import { useState } from 'react';

import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { StyledCloseIcon } from 'components/shared/Icons/ActionIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { Loading, ScaleLoader } from 'components/shared/Loading';
import Modal from 'components/shared/Modal/Modal';
import TableSearchHeader from 'components/Table/TableNav/TableSearchHeader';
import TablePicker from 'components/Table/TablePicker/TablePicker';
import useBouncer from 'hooks/useBouncer';
import { useInfoAndData } from 'hooks/useInfoAndData';
import { getDisplayValue, getFieldValue } from 'machines/FormMachine/FormMachine.utils';

import { Error, SearchIcon } from './Search.style';

type Props = {
  item: any;
  send: any;
};

function Search({ item, send }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [query, setQuery] = useState('');
  const [liveQuery, setLiveQuery] = useState('');
  const setDebouncedLiveQuery = useBouncer(setLiveQuery, 500);

  const { status, data, error, isFetching } = useInfoAndData({
    query: liveQuery,
    list: item.FIELDLIST,
    object: item.FIELDEDITOR,
    filters: item.FIELDLISTFILTERS || '',
  }) as any;

  function closeModal() {
    setIsModalOpen(false);
    setQuery('');
    setDebouncedLiveQuery('');
  }

  const clearSelection = () => {
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      FIELDVALUE: null,
      DISPLAYVALUE: null,
      FIELDFORCECALC: true,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
  };

  const handleTableClick = (row: any) => {
    const FIELDVALUE = getFieldValue(row.ID);
    const DISPLAYVALUE = getDisplayValue(item, row);
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      FIELDVALUE,
      DISPLAYVALUE,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
    closeModal();
  };

  return (
    <>
      <Modal isOpen={isModalOpen} handleClose={closeModal} fullScreen>
        <TableSearchHeader
          value={query}
          label={item.FIELDCAPTION}
          isUpdating={isFetching && status !== 'loading'}
          handleChange={(e: any) => {
            setQuery(e.target.value);
            setDebouncedLiveQuery(e.target.value);
          }}
        />
        {(() => {
          if (status === 'loading') return <Loading />;
          if (error) return <Error>{error.message}</Error>;
          return (
            <TablePicker
              type='Search'
              tableOffset={10}
              valueData={data?.data}
              keyData={data?.infoData}
              handleClickRow={handleTableClick}
            />
          );
        })()}
      </Modal>
      <ConfigBaseItem
        item={item}
        leftIcon={<SearchIcon />}
        isDisabled={!!item.SUBMITAFTERUPDATE}
        rightIcon={(() => {
          if (status === 'loading') {
            return <ScaleLoader height={10} width={3} />;
          }
          if (error) {
            return <ErrorInfo id={item.FIELDID} message={error.message} />;
          }
          if (!item.DISPLAYVALUE) return null;
          return <StyledCloseIcon onClick={clearSelection} />;
        })()}
      >
        {({ baseStatus }) => (
          <ConfigInput
            data-cy={item.FIELDID}
            readOnly
            value={item.DISPLAYVALUE || ''}
            onClick={() => setIsModalOpen(true)}
            onChange={() => {}}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modifiers={baseStatus.status}
          />
        )}
      </ConfigBaseItem>
    </>
  );
}
export default Search;
