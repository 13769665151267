import styled from 'styled-components';

import { ReactComponent as hideIcon } from 'assets/icons/view-hide.svg';
import { ReactComponent as showIcon } from 'assets/icons/view-show.svg';

export const HideIcon = styled(hideIcon)`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 15px;
  right: 20px;
  fill: ${({ theme }) => theme.tertiary};
  cursor: pointer;
`;
export const ShowIcon = styled(showIcon)`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  width: 15px;
  right: 20px;
  fill: ${({ theme }) => theme.tertiary};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h4 {
    margin-bottom: 5px;
  }
  div {
    position: relative;
    width: 100%;
  }
  .icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 15px;
  }
`;

type CustomInputProps = {
  error?: boolean;
  $hasIcon?: boolean;
};

export const CustomInput = styled.input<CustomInputProps>`
  width: 100%;
  color: ${(props) => props.theme.tertiary};
  letter-spacing: 0.3px;
  font-family: 'Roboto';
  border: 1px solid transparent;
  outline: none;
  font-size: ${(props) => props.theme.small};
  padding: ${(props) => (props.theme.hasRightIcon ? '1.2rem 0' : '1.2rem 4rem')};
  padding-left: ${({ $hasIcon }) => ($hasIcon ? '4rem' : '1.2rem')};
  font-weight: 500;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px
    ${(props) => (props.error ? props.theme.error : props.theme.gray2)};
  :focus {
    background-color: #fff;
    box-shadow: 0px 0px 1px 1px
      ${(props) => (props.error ? props.theme.error : props.theme.special)};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow:
      0 0 0 300px #fff inset,
      0px 0px 2px 0px ${(props) => props.theme.gray2};
  }

  ::placeholder {
    color: ${(props) => (props.error ? props.theme.error : props.theme.color)};
  }
  border-bottom: 1px solid #ddd;
  transition:
    background-color,
    box-shadow 0.2s ease-in-out;
  color: ${(props) => (props.error ? props.theme.error : 'inherit')};
`;
