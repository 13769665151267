import { assign, createMachine } from 'xstate';

import { changePassword } from 'helpers/services/services.utils';

export const passwordMachine = createMachine(
  {
    id: `passwordChangeMachine`,
    initial: 'idle',
    context: {
      responseError: '',
    },
    states: {
      idle: {
        on: {
          SUBMIT: {
            target: 'chooseState',
            actions: 'assignData',
          },
        },
      },
      chooseState: {
        always: [
          {
            cond: (ctx: any) => ctx.confirmedPassword === ctx.newPassword,
            target: 'changingPassword',
          },
          {
            target: 'failure',
            actions: assign({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hasMatchError: true,
              responseError: "Password confirmation doesn't match",
            }),
          },
        ],
      },
      changingPassword: {
        tags: 'loading',
        invoke: {
          src: async (ctx: any) => {
            return await changePassword({
              oldPassword: ctx.oldPassword,
              newPassword: ctx.newPassword,
            });
          },
          onDone: {
            target: 'success',
          },
          onError: {
            target: 'failure',
            actions: assign({
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              hasMatchError: false,
              responseError: (_, e) => e.data.message,
            }),
          },
        },
      },
      failure: {
        tags: 'failure',
        on: {
          SUBMIT: {
            target: 'chooseState',
            actions: 'assignData',
          },
        },
      },
      success: {
        after: {
          1000: {
            actions: 'goToHome',
          },
        },
      },
    },
  },
  {
    actions: {
      assignData: assign((ctx, e: any) => ({
        ...ctx,
        hasMatchError: false,
        oldPassword: e.oldPassword,
        newPassword: e.newPassword,
        confirmedPassword: e.confirmedPassword,
      })),
    },
  },
);
