import {
  useMemo,
  useEffect,
  createContext,
  useContext,
  PropsWithChildren,
  FC,
} from 'react';

import { isLastSavedVersionLatest } from 'config/Version';
import useInterceptor from 'hooks/useInterceptor';
import usePerstistedMachine from 'hooks/usePerstistedMachine';
import { AuthMachine } from 'machines/AuthMachine/AuthMachine';
const AuthContext = createContext<any>({});

const AuthDispatchContext = createContext<any>({});

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, send] = usePerstistedMachine('portal-authMachine', AuthMachine);

  // memoized user object
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const memoUser = useMemo(() => state.context.user, [state.context.user]);

  useInterceptor(memoUser, send);
  //refresh token on every mount or logout when new is Version is updated
  useEffect(() => {
    const isLatestVersion = isLastSavedVersionLatest();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    if (!isLatestVersion) {
      // @ts-ignore
      send({ type: 'LOGOUT' });
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    else {
      // @ts-ignore
      send({ type: 'REFRESH_TOKEN' });
    }
  }, [send]);

  return (
    <AuthContext.Provider value={state}>
      <AuthDispatchContext.Provider value={send}>{children}</AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
};
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth, useAuthDispatch, AuthContext };
