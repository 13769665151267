import styled from 'styled-components';

export const Switch = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  height: ${(props) => props.height || '3rem'};
  width: ${(props) => props.width || '5.8rem'};
  background: ${(props) => props.theme.grayLight2};
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  border-radius: ${(props) => props.height || '3rem'};
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  margin-top: 0.5rem;
  :focus {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  &:checked {
    background: ${(props) => props.theme.special};
    transition: all 0.3s;
    &:before {
      left: ${(props) => props.height || '3rem'};
    }
  }
  &:before {
    content: '';
    width: ${(props) => props.height || '3rem'};
    height: ${(props) => props.height || '3rem'};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${(props) => props.height || '3rem'};
    background: #fff;
    transform: scale(1.05);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
  }
`;

type BooleanContainerProps = {
  locked: number | boolean;
};

export const BooleanContainer = styled.div<BooleanContainerProps>`
  display: flex;
  pointer-events: ${({ locked }) => (locked ? 'none' : 'auto')};
`;

type BooleanOptionProps = {
  size?: string;
  isDisable?: boolean;
  active: boolean;
};

export const BooleanOption = styled.div<BooleanOptionProps>`
  ${(props) => {
    if (props.size === 'sm') {
      return {
        padding: '1rem 1.25rem',
        'font-size': props.theme.x_small,
      };
    }
    return {
      padding: '1.2rem 2.5rem',
      'font-size': props.theme.small,
    };
  }}
  ${(props) => {
    if (props.isDisable) {
      return {
        opacity: 0.5,
        pointerEvents: 'none',
      };
    }
  }}
  cursor: pointer;
  margin-top: 0.5rem;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-family: 'Roboto';
  color: ${({ theme, active }) => (active ? theme.white : theme.gray2)};
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 0px;
  box-shadow: ${({ theme }) => theme.shadow[3]};
  background-color: ${({ theme, active }) => (active ? theme.special : theme.grayLight2)};
  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.6)};
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.special};
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
