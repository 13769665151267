import { handleFieldType, isObjEmpty } from 'helpers/helpers';

function getConfigData(config: any, type: any) {
  const cleanConfig = config.filter((item: any) => {
    if (!item.FIELDNAME) return false;
    if (item.FIELDLEVEL === 'Detail') return false;
    if (type !== 'calc') return true;
    if (item.CALCULATESKIP) return false;
    if (item.FIELDFORCECALC) return true;
    if (item.FIELDISTOUCHED) return true;
    return item.FIELDVALUE;
  });
  return cleanConfig.reduce((acc: any, item: any) => {
    const [table, field] = item.FIELDNAME.split('.');
    if (!(table in acc)) acc[table] = [{}];
    const [rest] = acc[table];
    const value = handleFieldType(item);
    return { ...acc, [table]: [{ ...rest, [field]: value }] };
  }, {});
}
function getDetailsData(details: any, type: any) {
  const data = {};
  details.forEach((detail: any) => {
    // check details
    if (detail.CALCULATESKIP) return;
    if (!detail.store || !detail.store.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (data[detail.table] = []);
    }

    detail.store.forEach((storeItem: any, i: any) => {
      storeItem.item.forEach((item: any) => {
        // clean details data to sent
        if (type === 'calc' && item.CALCULATESKIP) return;
        if (!item.FIELDNAME) return;

        const [table, field] = item.FIELDNAME.split('.');
        if (!item.FIELDVALUE && field !== 'CCCCLISCALCULATEDRAFT') {
          return;
        }
        /* ---------------------- */
        // If the fieldTable dont exist, init it
        if (!(table in data)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[table] = [{}];
        }
        // init index for multiple store items
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!data[table][i]) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[table][i] = {};
        }
        // pass LINENUM only one time
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!('LINENUM' in data[table][i])) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          data[table][i]['LINENUM'] = i + 1;
        }
        if (field === 'CCCCLISCALCULATEDRAFT') {
          item.FIELDVALUE = 0;
        }
        // pass the value
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data[table][i][field] = handleFieldType(item);
      });
    });
  });
  return data;
}
export function getFieldsData(config: any, details: any, type: any) {
  const configData = getConfigData(config, type);
  const detailsData = getDetailsData(details, type);
  return { ...configData, ...detailsData };
}

export function getDraftData(currentDetail: any) {
  const { draft, table, CALCULATESKIP } = currentDetail;
  if (CALCULATESKIP) return {};
  return draft.reduce(
    (acc: any, item: any) => {
      if (item.CALCULATESKIP) return acc;
      if (!item.FIELDNAME) return acc;

      const [table, field] = item.FIELDNAME.split('.');
      if (!item.FIELDVALUE && field !== 'CCCCLISCALCULATEDRAFT') {
        return acc;
      }
      if (field === 'CCCCLISCALCULATEDRAFT') {
        item.FIELDVALUE = 1;
      }

      const fieldValue = handleFieldType(item);
      return { ...acc, [table]: { ...acc[table], [field]: fieldValue } };
    },
    { [table]: {} },
  );
}
export function addDraftAndFieldsData(draftData: any, fieldsData: any) {
  return Object.entries(draftData).reduce(
    (acc, [table, tableValues]) => {
      if (isObjEmpty(tableValues)) {
        return { ...acc, [table]: [] };
      }
      if (!(table in acc) || !acc[table].length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { ...acc, [table]: [{ LINENUM: 1, ...tableValues }] };
      }
      const LINENUM = acc[table].length + 1;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newDetailData = [...acc[table], { LINENUM, ...tableValues }];
      return { ...acc, [table]: newDetailData };
    },
    { ...fieldsData },
  );
}
