import TablePicker from 'components/Table/TablePicker/TablePicker';
import { useInfoAndData } from 'hooks/useInfoAndData';

import { ChartBody } from './ChartBody';

type Props = {
  data: any;
  description: string;
};

function ListCharts({ data, description }: Props) {
  const infoData = useInfoAndData({
    query: '',
    list: data.LIST,
    object: data.OBJECT,
    filters: data.FILTERS,
  });

  return (
    <ChartBody type='list' description={description} queryData={infoData}>
      <TablePicker
        type='Edit'
        tableOffset={11}
        enableA11y={false}
        valueData={infoData?.data?.data}
        keyData={infoData?.data?.infoData}
      />
    </ChartBody>
  );
}

export default ListCharts;
