import { toast } from 'react-hot-toast';
import { spawn, assign } from 'xstate';

import { API } from 'config/API';
import { getAllFilesTypes } from 'helpers/helpers';
import { createFormMachine } from 'machines/FormMachine/FormMachine';
import {
  updateConfigWithEditData,
  updateStoreWithEditData,
} from 'machines/shared/utils/edit';
import {
  createDetails,
  getFilesData,
  getSignatureData,
} from 'machines/shared/utils/utils';

function createExcludedPages(object: any) {
  return object.PAGES.reduce(
    (acc: any, pg: any) => {
      const excludedGroups = pg.GROUPS.filter((grp: any) => grp.GROUPBUFEXCLUDE);
      const excludedSpecialGroups = excludedGroups.filter((grp: any) =>
        ['File', 'Signature'].includes(grp.GROUPLEVEL),
      );
      const groupNames = excludedGroups.map((grp: any) => grp.GROUPDESC);
      const grouSpecialNames = excludedSpecialGroups.map((grp: any) => grp.GROUPLEVEL);
      return {
        ...acc,
        [pg.PAGEDESC]: groupNames,
        special: [...acc.special, ...grouSpecialNames],
      };
    },
    { special: [] },
  );
}
export const spawnCopyObject = assign({
  childObjectRef: (_: any, event: any) => {
    const excludedPages = createExcludedPages(event.object);

    const updatedConfig = updateConfigWithEditData(event.config, event.data, 'copy');
    const newConfig = updatedConfig.map((item: any) => {
      const canbeCopied = !excludedPages[item.PAGEDESC].includes(item.PAGEGROUP);
      if (canbeCopied) return item;
      return { ...item, FIELDVALUE: '', DISPLAYVALUE: undefined };
    });

    const stores = updateStoreWithEditData(event.config, event.data);
    const details = createDetails(event.config, stores, event.object);
    const newDetails = details.map((detail) => {
      const canbeCopied = !excludedPages[detail.page].includes(detail.name);
      if (canbeCopied) return detail;
      return { ...detail, store: [] };
    });

    const allFilesTypes = getAllFilesTypes(event.object, event.data?.editData);
    const filesConfig = getFilesData(allFilesTypes);
    const signature = getSignatureData(allFilesTypes, event);

    const canFilesBeCopied = excludedPages.special.includes('File');
    const canSignatureBeCopied = excludedPages.special.includes('Signature');

    return spawn(
      createFormMachine({
        parent: 'edit',
        parentFieldId: 'List-Copy',
        files: canFilesBeCopied ? [] : filesConfig,
        signature: canSignatureBeCopied ? null : signature,
        config: newConfig,
        details: newDetails,
        object: event.object,
        selectedObjectId: event.id,
        globalConfig: event.globalConfig,
        globalObjects: event.globalObjects,
      }),
    );
  },
});

export const spawnEditObject = assign({
  childObjectRef: (_: any, event: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newConfig = updateConfigWithEditData(event.config, event.data);
    const stores = updateStoreWithEditData(event.config, event.data);
    const details = createDetails(event.config, stores, event.object);

    const allFilesTypes = getAllFilesTypes(event.object, event.data?.editData);
    const filesConfig = getFilesData(allFilesTypes);
    const signature = getSignatureData(allFilesTypes, event);

    return spawn(
      createFormMachine({
        parent: 'edit',
        parentFieldId: 'List-Edit',
        signature,
        key: event.key,
        files: filesConfig,
        details: details,
        config: newConfig,
        object: event.object,
        selectedObjectId: event.id,
        globalConfig: event.globalConfig,
        globalObjects: event.globalObjects,
      }),
    );
  },
});

export const spawnNewObject = assign({
  childObjectRef: (_: any, e: any) => {
    const details = createDetails(e.config, undefined, e.object);
    return spawn(
      createFormMachine({
        parent: 'new',
        parentFieldId: 'List-New',
        globalObjects: e.globalObjects,
        globalConfig: e.globalConfig,
        id: e.id,
        object: e.object,
        config: e.config,
        details: details,
      }),
    );
  },
});

export const killChild = assign({
  childObjectRef: () => undefined,
});

export const assignErrorMessage = assign({
  errorMessage: (_: any, e: any) => `Server Error: ${e.data.message}`,
});
export const renderErrorMessage = (_: any, e: any) =>
  toast.error(`Server Error: ${e.data.message}`);

export const assignCallback = assign((ctx: any, e: any) => {
  return { ...ctx, refetch: e.refetch };
});

export const showPdfResult = (_: any, e: any) => {
  window.open(`${API}?filename=${e.data.fileName}`);
};
