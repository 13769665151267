const DEV_API = 'http://localhost:3001/api';
const PROD_API = '/api';

const DEV_EOPYY_API = 'http://localhost:3001/eopyy';
const PROD_EOPYY_API = '/eopyy';

const DEV_PORTAL_API = 'http://localhost:3001/portalServices';
const PROD_PORTAL_API = '/portalServices';

const DEV_FILES_API = 'http://localhost:3001/fileupload';
//const DEV_FILES_API = 'http://192.168.55.8:8090/api/Storage';
const PROD_FILES_API = '/fileupload';

const DEV_VIEW_FILES_API = 'http://localhost:3001/PortalFiles';
const PROD_VIEW_FIELS_API = '/PortalFiles';

export const PDF_API = 'http://localhost:3001/pdf';

export const API = process.env.NODE_ENV === 'development' ? DEV_API : PROD_API;

export const EOPYY_API =
  process.env.NODE_ENV === 'development' ? DEV_EOPYY_API : PROD_EOPYY_API;

export const PORTAL_API =
  process.env.NODE_ENV === 'development' ? DEV_PORTAL_API : PROD_PORTAL_API;

export const FILES_API =
  process.env.NODE_ENV === 'development' ? DEV_FILES_API : PROD_FILES_API;

export const VIEW_FILES_API =
  process.env.NODE_ENV === 'development' ? DEV_VIEW_FILES_API : PROD_VIEW_FIELS_API;
