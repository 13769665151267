import { FaFilter } from 'react-icons/fa';
import styled from 'styled-components';

import { inputIconStyle } from 'components/Search/Search.style';

export const FilterIcon = styled(FaFilter)`
  ${inputIconStyle}
`;
export const FilterContainer = styled.div`
  width: 20rem;
  margin-left: 1px;
  margin-bottom: 1rem;
  position: relative;
`;
