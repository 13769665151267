import axios from 'axios';
import { toast } from 'react-hot-toast';
import { assign, createMachine } from 'xstate';

import { PORTAL_API } from 'config/API';

import { initializeMachine } from './actors/intializeMachine';

const initialContext = {
  user: undefined,
  error: '',
  config: [],
  objects: [],
  branchConfig: {},
  expDate: undefined,
};

function getSelectedObj(objs: any, selectedItems: any) {
  return objs.filter((obj: any) =>
    Object.keys(selectedItems).every((key) => selectedItems[key] === obj[key]),
  );
}

const AuthMachine = createMachine(
  {
    id: 'authMachine',
    initial: 'unothorized',
    context: { ...initialContext },
    states: {
      unothorized: {
        on: {
          AUTHORIZE: {
            target: 'authorized',
            actions: ['assignUser', 'assignBranch', 'refreshExpDate'],
          },
        },
      },
      authorized: {
        exit: 'resetContext',
        initial: 'waitingInterceptor',
        states: {
          waitingInterceptor: {
            on: {
              APPROVAL: 'initializing',
            },
          },
          initializing: {
            invoke: {
              id: 'initialize',
              src: initializeMachine,
              data: (ctx) => ({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                ...initializeMachine.context,
                user: ctx.user,
              }),
              onDone: [
                {
                  cond: 'isChildSuccessFul',
                  target: 'checkExpDate',
                  actions: 'assignInitializeData',
                },
                {
                  target: 'initializingFailure',
                  actions: 'assignError',
                },
              ],
            },
          },
          initializingFailure: {
            on: {
              RETRY: 'initializing',
            },
          },
          checkExpDate: {
            invoke: {
              src: 'getCustomerExpDate',
              onDone: [
                {
                  cond: (_, e: any) => e.data.days >= 0 && e.data.days <= 7,
                  target: 'initialized',
                  actions: ['changePassword', 'renderWarning'],
                },
                { target: 'initialized' },
              ],
              onError: {
                target: 'initialized',
                actions: (_, e) => toast.error(e.data.message),
              },
            },
          },
          initialized: {},
        },
        on: {
          CHANGE_BRANCH: {
            target: '.waitingInterceptor',
            actions: ['assignClientId', 'assignBranch', 'refreshExpDate'],
          },
          LOGOUT: {
            target: '#authMachine.unothorized',
            actions: 'resetContext',
          },
          REFRESH_TOKEN: [
            {
              cond: 'tokenExpired',
              target: '#authMachine.unothorized',
            },
            { actions: 'refreshExpDate' },
          ],
        },
      },
    },
  },
  {
    actions: {
      assignClientId: assign((ctx: any, e: any) => {
        return { ...ctx, user: { ...ctx.user, clientID: e.clientID } };
      }),
      assignError: assign((ctx, e: any) => {
        const { error } = e.data;
        return { ...ctx, error: error.message };
      }),
      assignUser: assign((ctx, e: any) => {
        return { ...ctx, user: e.user };
      }),
      assignBranch: assign((ctx, e: any) => {
        const { objs, selected } = e.branch;
        const [selectedObj] = getSelectedObj(objs, selected);
        return { ...ctx, branch: { objs, selected: selectedObj } };
      }),
      assignInitializeData: assign((ctx, e: any) => {
        const { config, objects, branchConfig, s1gadgets } = e.data;
        return { ...ctx, config, objects, branchConfig, s1gadgets };
      }),
      refreshExpDate: assign((ctx: any) => {
        const expDate = new Date();
        expDate.setDate(new Date().getDate() + 1);
        return { ...ctx, expDate };
      }),
      resetContext: assign(() => {
        return { ...initialContext };
      }),
      renderWarning: (_, e) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toast.success(e.data.message, { subType: 'warning' });
      },
    },
    guards: {
      tokenExpired: (ctx) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return new Date(ctx.expDate) - new Date() < 0;
      },
      isChildSuccessFul: (_, e) => {
        return !e.data.error;
      },
    },
    services: {
      getCustomerExpDate: async (ctx: any) => {
        const result = await axios.post(PORTAL_API, {
          service: 'getCustomerExpDate',
          user: ctx.user.username,
        });
        return result.data;
      },
    },
  },
);

export { AuthMachine };
