import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Footer from 'components/shared/Layouts/Footer';
import { BranchCompany, BranchUser } from 'components/shared/ui/UserInfo';
import { publicWindow } from 'config/public';
import { useAuthDispatch } from 'context/AuthProvider';

import NavDropList from './NavDropList';
import { AccountIcon, KeyIcon, LinkIcon, LogoutIcon } from '../NavBar.style';

const { ssoSn } = publicWindow;
const { ssoRedirectUrl } = publicWindow;

type Props = {
  isOpen: boolean;
  user: any;
  branch: any;
};

function AccountMenu({ isOpen, user, branch }: Props) {
  const [issuer, setIssuer] = useState('');
  const navigate = useNavigate();
  const authSend = useAuthDispatch();

  useEffect(() => {
    const storedValue = localStorage.getItem('issuer');
    if (storedValue) {
      setIssuer(storedValue);
    }
  }, [isOpen]);

  function handleLogout() {
    if (issuer !== undefined && issuer !== null && issuer !== '') {
      window.location.href =
        'https://sso.softonecloud.com/SAML/InitiateSingleLogout/' +
        `${ssoSn}` +
        '?issuer=' +
        `${issuer}` +
        '&redirectUrl=' +
        `${ssoRedirectUrl}`;
    }
    localStorage.clear();
    authSend({ type: 'LOGOUT' });
    navigate('/', { replace: true });
  }

  if (!isOpen) return null;
  return (
    <NavDropList
      icon={<AccountIcon />}
      title='Account'
      menuOptions={[
        { TITLE: <BranchUser {...{ user, branch }} /> },
        { TITLE: <BranchCompany {...{ branch }} /> },
        {
          HIDDEN: branch.objs.length <= 1,
          TITLE: 'Change Company',
          PATH: 'branch-selection',
          ICON: <LinkIcon />,
        },
        {
          TITLE: 'Change Password',
          PATH: 'change-password',
          ICON: <KeyIcon />,
        },
        {
          TITLE: 'Logout',
          ICON: <LogoutIcon />,
          onClick: handleLogout,
        },
        { TITLE: <Footer /> },
      ]}
    />
  );
}

export default AccountMenu;
