import React, { useRef } from 'react';

import { asEffect, useMachine } from '@xstate/react';
import SignatureCanvas from 'react-signature-canvas';
import { v4 as uuid } from 'uuid';

import { SignatureMachine } from './signatureGroup.machine';
import {
  Wrapper,
  SaveButton,
  ClearButton,
  ButtonsWrapper,
  SignatureWrapper,
} from './signatureGroup.style';
import { FILES_API } from '../../config/API';
import {
  SIGNATURE_PREFIX,
  SOFTONE_DATABASE,
  SOFTONE_FILES,
} from '../../config/constants';
import { publicWindow } from '../../config/public';
import { useStepsContext, useStepsDispatch } from '../../context/StepsProvider';

export const DATA_URL_PREFIX = 'data:image/png;base64,';

const { artieWebId, artieCustomer } = publicWindow;

const CheckSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={{ width: 20, height: 20, marginLeft: 2 }}
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M5 13l4 4L19 7'
    />
  </svg>
);

type SignatureGroupProps = {
  softoneSubmitKey: string;
  resignature: boolean;
  readOnly: boolean;
};

const SignatureGroup = ({
  softoneSubmitKey,
  resignature,
  readOnly,
}: SignatureGroupProps) => {
  const ref = useRef<any>();

  const formSend = useStepsDispatch();
  const formState = useStepsContext();
  const { signature } = formState.context;
  const [, setSignatureFiles] = React.useState();

  const [state, send] = useMachine(
    SignatureMachine.withContext({ sendToForm: formSend }),
    {
      actions: {
        clear: asEffect((ctx: any) => {
          ref.current && ref.current.clear();
          ctx.sendToForm({ type: 'signature', signature: null });
        }),
        save: asEffect((ctx: any) => {
          if (ref.current) {
            const imageData = ref.current.toDataURL();
            const fileName = `${SIGNATURE_PREFIX}-${uuid()}.png`;
            ctx.sendToForm({
              type: 'signature',
              signature: {
                resignature,
                softoneSubmitKey,
                data: imageData,
                name: fileName,
              },
            });
            sessionStorage.setItem('signFileName', fileName);
          }
        }),
      },
    },
  );
  const signFileName = sessionStorage.getItem('signFileName');
  const loadedSignatureFileName = sessionStorage.getItem('loadedSignatureFileName');
  if (formState.context.object.REGSIGNATURE === SOFTONE_DATABASE) {
    React.useEffect(() => {
      if (!ref.current || !signature) {
        return;
      }
      async function fetchData() {
        try {
          ref.current.fromDataURL(signature.data);
          sessionStorage.setItem('signatureData', signature.data);
          return;
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      }

      fetchData();
    }, [signature]);
  } else if (formState.context.object.REGSIGNATURE === SOFTONE_FILES) {
    React.useEffect(() => {
      if (!ref.current || !signature) {
        return;
      }
      async function fetchData() {
        try {
          fetch(`${FILES_API}/DownloadFileBase64/${artieWebId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customer: artieCustomer,
              filePath: `${signature.basePath}${signature.name}`,
              fileBase64: '',
            }),
          })
            .then((response) => {
              response.json().then((data) => {
                setSignatureFiles(data.data);
                ref.current.fromDataURL(DATA_URL_PREFIX + data.data);
              });
            })
            .catch((err) => {
              console.log(`Error while trying to retreive base64 data: ${err}`);
            });
        } catch (error) {
          console.error('Error downloading file:', error);
        }
      }
      fetchData();
    }, []);
  }

  return (
    <Wrapper>
      <SignatureWrapper>
        <SignatureCanvas
          ref={ref}
          penColor='black'
          clearOnResize={false}
          canvasProps={{
            style: {
              color: 'white',
              borderRadius: 10,
              height: 300,
              width: '100%',
              border: '1px solid rgb(34,34,34,0.2)',
            },
          }}
          onBegin={() => send({ type: 'EDIT' })}
        />
      </SignatureWrapper>
      {(() => {
        if (readOnly) return null;
        return (
          <ButtonsWrapper>
            <ClearButton
              style={{ maxWidth: 300 }}
              onClick={() => send({ type: 'CLEAR' })}
            >
              Clear
            </ClearButton>
            <SaveButton
              style={{ maxWidth: 300 }}
              disabled={state.matches('saving')}
              onClick={() => send({ type: 'SAVE' })}
            >
              <span>{state.matches('idle') ? 'Save' : 'Saved'}</span>
              {state.matches('saving') && <CheckSvg />}
            </SaveButton>
          </ButtonsWrapper>
        );
      })()}
    </Wrapper>
  );
};

export default SignatureGroup;
