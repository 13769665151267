import styled from 'styled-components';

import { device } from 'config/device';

const PageContainer = styled.div`
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${(props) => props.theme.grayLight};
  @media ${device.tablet} {
    margin: 0.5rem auto;
  }
`;

const PageNavigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
  grid-gap: 1rem;
  .flex {
    display: flex;
    grid-gap: 1rem;
  }
  @media ${device.mobile} {
    .flex,
    button {
      width: 100%;
    }
  }
`;

const H2 = styled.h2`
  border-radius: 2px;
  background: ${(props) => props.theme.special};
  text-align: center;
  opacity: 1;
  color: #fff;
  position: relative;
  padding: 2rem 0;
  font-weight: 500;
  font-size: ${(props) => props.theme.large};
`;

export { H2, PageContainer, PageNavigation };
