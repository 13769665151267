import { useActor } from '@xstate/react';

import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { CheckIcon, ExamIcon } from 'components/shared/Icons/InfoIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { ScaleLoader } from 'components/shared/Loading';

type Props = {
  item: any;
  eoppyItemCode: string;
  eopyyPatientCode: string;
  serviceRef: any;
};

export default function Eopyy({
  item,
  eoppyItemCode,
  eopyyPatientCode,
  serviceRef,
}: Props) {
  const actor = useActor(serviceRef);
  const [state, send] = actor as any;
  const { context } = state as any;
  return (
    <ConfigBaseItem
      item={item}
      leftIcon={<ExamIcon />}
      rightIcon={(() => {
        if (state.matches('success')) return <CheckIcon />;
        if (state.matches('loading')) return <ScaleLoader height={10} width={3} />;
        if (state.matches('error'))
          return <ErrorInfo id={item.FIELDID} message={context.errorMessage} />;
      })()}
    >
      <ConfigInput
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        modifiers={state.value}
        placeholder=''
        value={context.eopyyCode}
        onBlur={() => send({ type: 'CHECK_CODE', eoppyItemCode, eopyyPatientCode })}
        onFocus={() => send({ type: 'RETRY' })}
        onChange={(e) => send({ type: 'UPDATE_CODE', code: e.target.value })}
      />
    </ConfigBaseItem>
  );
}
