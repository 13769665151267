import { createContext, useContext, useRef } from 'react';

import { useOnClickOutsideModal } from 'hooks/useOnClickOutsideModal';

export const ModalContext = createContext<any>({});

const ModalProvider = ({ children }: any) => {
  const openModals = useRef([]);
  return (
    <ModalContext.Provider value={openModals.current}>{children}</ModalContext.Provider>
  );
};
const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return context;
};

export const useModalTrack = () => {
  const openModals = useModalContext();
  useOnClickOutsideModal();

  function subscribe(props: any) {
    const found = openModals.find((modal: any) => modal.id === props.id);
    if (found) return;
    openModals.push(props);
  }
  function unSubscribe() {
    openModals.pop();
  }

  return { subscribe, unSubscribe };
};

export { ModalProvider, useModalContext };
