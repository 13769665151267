import {
  useTable,
  useSortBy,
  useGroupBy,
  useExpanded,
  usePagination,
  useGlobalFilter,
} from 'react-table';

import { ReactComponent as CheveronDown } from 'assets/icons/cheveron-down.svg';
import { ReactComponent as CheveronRight } from 'assets/icons/cheveron-right.svg';
import { StyledFlex } from 'components/shared/shared.style.';
import { useApprovals } from 'hooks/useApprovals';

import { checkIfapproval, getOriginalRow } from './Approval/apprvoval.utils';
import GlobalFilter from './Filter/GlobalFilter';
import Pagination from './Pagination/Pagination';
import { EmptyContainer } from './Table.style';
import TableNav from './TableNav/TableNav';
import RenderCell from './TableViews/RenderCell';
import { RenderHeader } from './TableViews/RenderHeader';
import { useA11yNavigation } from './useA11yNavigation';
import { OparationBtn } from '../Detail/Detail.style';

type Props = {
  data: Array<any>;
  columns: Array<any>;
  usage: {
    pagination?: boolean;
    filter?: boolean;
    columnsFilter?: boolean;
    enableA11y?: boolean;
  };
  tableProps?: any;
  provideCell?: (row: any) => void;
  actions?: (id?: string) => React.ReactNode;
  handleOneItem?: any;
};

function Table({
  data,
  columns,
  usage,
  tableProps,
  provideCell,
  actions,
  handleOneItem,
}: Props) {
  const tableInstance = useTable(
    {
      data,
      columns,
      autoResetPage: false,
      autoResetSortBy: false,
      ...tableProps,
    },
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const {
    rows,
    allColumns,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    state: { globalFilter, pageIndex, pageSize },
  } = tableInstance as any;

  const { tableRef, rowsRefs, activeRowIndex, changeHoverModeIndex } = useA11yNavigation({
    page,
    enabled: usage.enableA11y,
    provideCell: ({ row }: any) => {
      const originalRow = getOriginalRow(row);
      provideCell?.(originalRow);
    },
  });

  const approvalState = useApprovals(allColumns);

  const hasActions = actions && Boolean(actions());
  const tableRows = usage.pagination ? page : rows;

  return (
    <>
      <GlobalFilter
        enable={Boolean(usage.filter)}
        {...{ globalFilter, setGlobalFilter }}
      />
      <TableNav
        enable={Boolean(usage.columnsFilter)}
        length={rows.length || 0}
        columns={allColumns}
        excelData={data}
        excelColumns={columns}
        approvalState={approvalState}
      />
      {(() => {
        if (!tableRows.length) {
          return <EmptyContainer>No data found</EmptyContainer>;
        }
        return (
          <div ref={tableRef} className='container'>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup: any, headerIndex: number) => (
                  <tr key={headerIndex} {...headerGroup.getHeaderGroupProps()}>
                    {(() => {
                      if (!hasActions) return null;
                      return <th data-testid='column-actions'>Actions</th>;
                    })()}
                    {headerGroup.headers.map((column: any, columnIndex: number) => (
                      <th
                        data-cy={`column-${column.Header}`}
                        data-testid='column'
                        key={columnIndex}
                      >
                        <RenderHeader {...{ column, tableRows, approvalState }} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {tableRows.map((row: any, rowIndex: number) => {
                  prepareRow(row);
                  const originalRow = getOriginalRow(row);
                  const rowID = originalRow.ID;
                  return (
                    <tr
                      data-cy={`row-${rowID}`}
                      key={rowIndex}
                      {...row.getRowProps()}
                      ref={rowsRefs[rowIndex]}
                      /* eslint-disable react/no-unknown-property */
                      active-row={activeRowIndex === rowIndex ? 'true' : 'false'}
                      onMouseEnter={() => changeHoverModeIndex(rowIndex)}
                    >
                      {(() => {
                        if (!hasActions) return null;
                        return <td data-testid='cell-actions'>{actions(rowID)}</td>;
                      })()}
                      {row.cells.map((cell: any, cellIndex: number) => {
                        const { id, type } = cell.column;
                        return (
                          <td
                            data-cy={`cell-${id}-${cellIndex}`}
                            key={cellIndex}
                            data-testid='cell'
                            is-float={type === 'float' ? 'true' : 'false'}
                            is-grouped={cell.isGrouped ? 'true' : 'false'}
                            is-placeholder={cell.isPlaceholder ? 'true' : 'false'}
                            onClick={() => {
                              if (checkIfapproval(id)) {
                                if (cell.row.isGrouped) {
                                  const leafRows = cell.row.leafRows;
                                  return approvalState.onMultiChange(leafRows);
                                }
                                return approvalState.onChange(originalRow);
                              }
                              if (provideCell) provideCell(originalRow);
                            }}
                            {...(() => {
                              if (!cell.isGrouped) return cell.getCellProps();
                              return row.getToggleRowExpandedProps();
                            })()}
                          >
                            <div
                              style={{
                                display: 'flex',
                                marginRight: '8px',
                                lineHeight: '25px',
                              }}
                            >
                              <div style={{ display: 'flex' }}>
                                {Boolean(
                                  row.original?.fields?.counter.filter((each: any) => {
                                    return each.FIELDCAPTION === cell.column.Header;
                                  }).length,
                                ) && (
                                  <>
                                    <OparationBtn
                                      operation={'add'}
                                      onClick={() => {
                                        handleOneItem(cell.column.Header, rowID, 'add');
                                      }}
                                    >
                                      +
                                    </OparationBtn>
                                  </>
                                )}
                              </div>
                              {(() => {
                                if (cell.isGrouped) {
                                  return (
                                    <StyledFlex
                                      $gridGap='1rem'
                                      $alignItems='center'
                                      justifyContent='left'
                                    >
                                      {(() => {
                                        if (row.isExpanded) return <CheveronDown />;
                                        return <CheveronRight />;
                                      })()}
                                      {cell.render('Cell')}
                                      <p>({row.subRows.length})</p>
                                    </StyledFlex>
                                  );
                                }
                                return (
                                  <RenderCell
                                    {...{ cell, rowID, approvalState }}
                                    isSmall={Boolean(
                                      row.original?.fields?.counter.filter(
                                        (each: any) => {
                                          return each.FIELDCAPTION === cell.column.Header;
                                        },
                                      ).length,
                                    )}
                                  />
                                );
                              })()}
                              <div style={{ display: 'flex' }}>
                                {Boolean(
                                  row.original?.fields?.counter.filter((each: any) => {
                                    return each.FIELDCAPTION === cell.column.Header;
                                  }).length,
                                ) && (
                                  <>
                                    <OparationBtn
                                      operation={'subtract'}
                                      onClick={() => {
                                        handleOneItem(
                                          cell.column.Header,
                                          rowID,
                                          'subtract',
                                        );
                                      }}
                                    >
                                      -
                                    </OparationBtn>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })()}
      <Pagination
        enable={Boolean(usage.pagination)}
        total={rows.length || 0}
        {...{
          nextPage,
          pageSize,
          pageIndex,
          previousPage,
          setPageSize,
          canNextPage,
          canPreviousPage,
        }}
      />
    </>
  );
}

export default Table;
