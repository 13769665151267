import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { device } from 'config/device';

export const CloseIcon = styled(Close)`
  cursor: pointer;
  fill: #ecebf2;
  height: 2.5rem;
  width: 2.5rem;
`;

type NavLinkWrapperProps = {
  $isOverFlown: boolean;
};

export const NavMenuWrapper = styled.div<NavLinkWrapperProps>`
  display: ${(props) => (props.$isOverFlown ? 'block' : 'none')};
  hr {
    border-color: ${({ theme }) => `${theme.gray}90`};
  }
  .menu-hamburger-icon {
    svg {
      position: absolute;
      right: 3rem;
      bottom: 50%;
      transform: translateY(50%);
      width: 3rem;
      height: 3rem;
      fill: #ecebf2;
      cursor: pointer;
    }
  }
  @media ${device.tablet} {
    display: block;
  }
`;

export const NavStaticLinkWrapper = styled.li`
  cursor: pointer;
  display: flex;
  grid-gap: 1rem;
  align-items: center;
  font-size: ${({ theme }) => theme.large};
  :hover {
    opacity: 0.6;
  }
`;

export const NavlinksWrapper = styled.ul`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.large};
  list-style: none;
  li {
    margin-bottom: 2rem;
  }
  .menu-icon {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
  }
  .nav-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  grid-gap: 0.5rem;
  .nav-link {
    cursor: pointer;
    padding: 1rem 0.5rem;
    grid-gap: 1rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: ${({ theme }) => theme.medium};
    color: ${({ theme }) => theme.gray};
    :hover {
      color: ${({ theme }) => theme.gray2};
    }
  }
`;
