import { FaPrint } from 'react-icons/fa';
import styled, { css } from 'styled-components';

import { ReactComponent as AddListIcon } from 'assets/icons/add-solid.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/cheveron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Trash } from 'assets/icons/trash.svg';

export const iconStyle = css`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  fill: ${(props) => props.theme.gray2};
  :hover {
    fill: ${(props) => props.theme.gray};
  }
`;
export const svgStyle = css`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-40%);
`;

export const InputRightIcon = styled.div`
  ${svgStyle};
`;

export const EditIcon = styled(Edit)`
  ${iconStyle};
`;
export const PrintIcon = styled(FaPrint)`
  margin-left: 1.5rem;
  ${iconStyle};
`;
export const DeleteIcon = styled(Trash)`
  ${iconStyle};
  margin-left: 1.5rem;
  fill: ${({ theme }) => `${theme.error}CC`};
  :hover {
    fill: ${({ theme }) => theme.error};
  }
`;

export const StyledArrowDown = styled(ArrowDown)`
  ${iconStyle};
`;

export const CopyIcon = styled(Copy)`
  ${iconStyle};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  ${iconStyle};
  fill: ${({ theme }) => `${theme.error}CC`};
  :hover {
    fill: ${({ theme }) => theme.error};
  }
`;

export const AddIcon = styled(AddListIcon)`
  ${iconStyle};
  margin-left: 0.5rem;
  cursor: pointer;
  fill: ${(props) => `${props.theme.special}CC`};
  :hover {
    fill: ${(props) => props.theme.special};
  }
`;
