export function filterData(data: any, dFields = '', columnCaptions = '') {
  if (!data) return;
  const parsedDisplayFields = dFields.split(',');
  const parsedCaptions = columnCaptions.split(',');
  const matchTruthTable = data.columns?.reduce((prev: any, cur: any) => {
    const shouldBeKept = parsedDisplayFields.find((dField) => dField === cur.name);

    return [...prev, shouldBeKept ? true : false];
  }, []);
  const validRows = data.data?.map((row: any) => {
    return filterRowCells(row, matchTruthTable);
  });
  const validColumns = data.columns?.filter((column: any, index: number) => {
    if (matchTruthTable[index]) return column;
    else return false;
  });

  const validColumnsWithUpdatedCaptions = validColumns?.map(
    (column: any, index: number) => {
      if (parsedCaptions[index]) return { ...column, caption: parsedCaptions[index] };
      else return column;
    },
  );

  const validColumnsWithId = [{ name: 'ID' }, ...validColumnsWithUpdatedCaptions];

  return { columns: validColumnsWithId, data: validRows };
}

function filterRowCells(row: any, matchTruthTable: any) {
  const idCOlumn = row ?? row[0];
  const filteredRowCells = row?.filter((rowCell: any, index: number) => {
    if (matchTruthTable[index]) {
      return true;
    }
    return false;
  });
  return [idCOlumn, ...filteredRowCells];
}

export function getListValue(data: any, id: any) {
  if (!data || !id) return '';
  const match = data.find(([itemId]: any) => itemId === id || Number(itemId) === id);
  if (!match) return '';
  const description = match[1];
  return description;
}
