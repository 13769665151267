import { useState, useRef } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { useNavigation } from 'hooks/useNavigation';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import {
  DropList,
  StyledArrowUp,
  StyledArrowDown,
  LinkInfoWrapper,
} from './NavDropList.style';
import { NavListHeader } from '../NavBar.style';
import NavLinkLabel from '../NavLinkLabel';

type Props = {
  title: string;
  menuOptions: any[];
  objectPath?: string;
  icon?: any;
  object?: any;
};

const NavDropList = (props: Props) => {
  const location = useLocation();

  const { menuOptions, title, objectPath, icon, object } = props;
  const linkActive = location.pathname
    .split('/')
    .includes(objectPath ? objectPath.split('/')[0] : '');

  const ref = useRef();
  const [active, setActive] = useState(false);
  useOnClickOutside(ref, () => setActive(false));

  const navigate = useNavigation(objectPath);

  function handleClickHeader(e: any) {
    e.preventDefault();
    setActive(!active);
  }

  function handleClickItem(option: any) {
    if (option.onClick) {
      return option.onClick();
    }
    if (option.ICON) navigate(option);
  }

  if (menuOptions?.length === 0) return null;
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NavLink ref={ref} to={`${props}`} onClick={handleClickHeader}>
      {linkActive && title !== 'Account' && (
        <div
          style={{
            position: 'absolute',
            top: '0',
            width: '100%',
            background: '#079dd6',
            height: '80%',
            zIndex: '-1',
            borderRadius: '0px 0px 15px 15px',
          }}
        ></div>
      )}
      <NavListHeader>
        {icon && icon}
        {title}
        {(() => {
          if (active) {
            return <StyledArrowUp className='arrow' />;
          }
          return <StyledArrowDown className='arrow' />;
        })()}
      </NavListHeader>
      <DropList $isActive={active}>
        {menuOptions.map((option, index) => {
          if (option.HIDDEN) return null;
          return (
            <LinkInfoWrapper
              key={index}
              $hasIcon={Boolean(option.ICON)}
              onClick={() => handleClickItem(option)}
            >
              <NavLinkLabel {...{ option, object }} />
              {option.ICON}
            </LinkInfoWrapper>
          );
        })}
      </DropList>
    </NavLink>
  );
};

export default NavDropList;
