import React, { useMemo } from 'react';

import Particles from '@tsparticles/react';
import { useMachine } from '@xstate/react';
import { useNavigate } from 'react-router';
import useResizeObserver from 'use-resize-observer';

import { Button } from 'components/shared/Button/Button';
import { NotificationLoading } from 'components/shared/ui/AnimatedText';
import { API } from 'config/API';
import { publicWindow } from 'config/public';
import { useUserConfiguration } from 'config/themes';
import { useAuth, useAuthDispatch } from 'context/AuthProvider';
import { LoginMachine } from 'machines/LoginMachine/LoginMachine';

import BranchSelection from './BranchSelection';
import {
  Form,
  FormMessage,
  LoginContainer,
  LoginError,
  ParticlesContainer,
} from '../LoginPage/Login.style';

export default function BranchPage() {
  const { appId = '2006' } = publicWindow;
  const { title } = useUserConfiguration();

  const navigate = useNavigate();
  const { ref, width } = useResizeObserver();

  const authState = useAuth();
  const authSend = useAuthDispatch();
  const { user, branch } = authState.context;

  const [state, send] = useMachine(LoginMachine, {
    context: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      API,
      appId,
      objs: branch.objs,
      selected: branch.selected,
      loginClientID: user.loginClientID,
    },
    actions: {
      goToHome: () => {
        navigate('/', { replace: true });
      },
    },
  });
  const { value, context } = state as any;

  function onBranchSubmit(e: any) {
    e.preventDefault();
    send({ type: 'SUBMIT_BRANCH' });
  }

  React.useEffect(() => {
    if (value === 'success') {
      authSend({
        type: 'CHANGE_BRANCH',
        clientID: context.clientID,
        branch: {
          objs: context.objs,
          selected: context.authParams,
        },
      });
    }
  }, [value, context, authSend]);

  const particlesLoaded = (container: any) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 100,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#ffffff',
        },
        links: {
          color: '#ffffff',
          distance: 100,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 700,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
      fullScreen: { enable: false },
    }),
    [],
  );

  return (
    <LoginContainer ref={ref}>
      {width
        ? width
        : 0 > 650 && (
            <ParticlesContainer>
              <div
                className={'foo'}
                style={{ position: 'absolute', top: 0, bottom: 0, height: '100%' }}
              >
                <Particles
                  id='tsparticles'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  particlesLoaded={particlesLoaded}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options={options}
                />
              </div>
            </ParticlesContainer>
          )}
      <Form data-cy='branch-selection-form' onSubmit={onBranchSubmit}>
        <FormMessage color='tertiary'>{title}</FormMessage>
        {(() => {
          if (value === 'success' || value === 'authenticating') {
            return (
              <NotificationLoading
                showText={state.matches('success')}
                textTitle={'SUCCESS'}
              />
            );
          }
          return (
            <>
              <BranchSelection actor={context.branchActor} />
              <Button style={{ width: '100%' }} type='submit'>
                CONTINUE
              </Button>
              {context.error && <LoginError>{context.error}</LoginError>}
            </>
          );
        })()}
      </Form>
    </LoginContainer>
  );
}
