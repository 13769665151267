import { toast } from 'react-hot-toast';
import { assign, spawn, sendParent } from 'xstate';
import { pure } from 'xstate/lib/actions';

import { getAllFilesTypes } from 'helpers/helpers';
import { createDetailMachine } from 'machines/DetailMachine/DetailMachine';
import {
  updateConfigWithCalcData,
  updateConfigWithEditData,
  updateDraftWithCalcData,
  updateStoreWithCalcData,
  updateStoreWithEditData,
} from 'machines/shared/utils/edit';
import {
  createDetails,
  getFilesData,
  getSignatureData,
} from 'machines/shared/utils/utils';

import { createFormMachine } from './FormMachine';
import { getListDisplayValue } from './FormMachine.utils';
import { publicWindow } from '../../config/public';

export const assignError = assign((ctx, e: any) => ({ ...ctx, error: e.data.message }));

export const assignMessage = assign((ctx, e: any) => ({
  ...ctx,
  message: e.data.message,
}));
export const assignCallback = assign((ctx, e: any) => ({ ...ctx, callback: e.callback }));

export const assignCreatedObjectId = assign((ctx, e: any) => {
  return { ...ctx, createdObjectId: e.data.id, key: e.data.id };
});

export const assignConfig = assign({
  config: (_, e: any) => e.data.config,
});
export const assignObject = assign({
  object: (_, e: any) => e.object,
});
export const selectObject = assign({
  selectedObjectId: (_, e: any) => e.id,
});

export const assignBasePath = assign((ctx, e: any) => {
  return { ...ctx, filesBasePath: e.data };
});
export const assignSignaturePath = assign((ctx, e: any) => {
  return { ...ctx, signaturePath: e.data };
});

export const spawnObject: any = assign({
  childObjectRef: (context: any, event: any) => {
    const details = createDetails(event.config, undefined, event.object);
    return spawn(
      createFormMachine({
        globalObjects: event.globalObjects,
        globalConfig: event.globalConfig,
        parent: context.selectedObjectId,
        id: event.id,
        object: event.object,
        config: event.config,
        details: details,
        parentFieldId: event.fieldId,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        closeModal: event.closeModal,
      }),
    );
  },
});
export const spawnEditObject: any = assign({
  childObjectRef: (context: any, event: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newConfig = updateConfigWithEditData(event.config, event.data);
    const stores = updateStoreWithEditData(event.config, event.data);
    const details = createDetails(event.config, stores, event.object);

    const allFilesTypes = getAllFilesTypes(event.object, event.data?.editData);
    const filesConfig = getFilesData(allFilesTypes);
    const signature = getSignatureData(allFilesTypes, event);

    return spawn(
      createFormMachine({
        globalObjects: event.globalObjects,
        globalConfig: event.globalConfig,
        parent: context.selectedObjectId,
        id: event.id,
        key: event.key,
        files: filesConfig,
        signature,
        object: event.object,
        config: newConfig,
        details: details,
        parentFieldId: event.fieldId,
        callback: event.callback,
      }),
    );
  },
});

export const removeChildObject = assign((ctx) => {
  return { ...ctx, childObjectRef: undefined };
});

export const spawnDetailsMachines = assign({
  details: (context: any) => {
    return context.details.map((detail: any) => {
      const { config, store, name } = detail;
      const machineName = `detailMachine ${name}`;
      const ref = spawn(createDetailMachine(config, store, name), machineName);
      return { ...detail, machineRef: ref };
    });
  },
});
export const updateParent = pure((ctx: any, e: any) => {
  if (!ctx.parentFieldId) return;
  return sendParent({
    type: 'CHILD_UPDATE',
    id: e.data.id,
    config: ctx.config,
    fieldId: ctx.parentFieldId,
    childObject: ctx.object,
  });
});

export const assignPdfKey = assign((ctx: any, e: any) => {
  return { ...ctx, pdfOptions: { ...ctx.pdfOptions, key: e.data.id } };
});
export const assignFileNames = assign((ctx: any, e: any) => {
  return { ...ctx, pdfOptions: { ...ctx.pdfOptions, fileNames: e.data } };
});

export const showSuccessNotify = assign((ctx: any, e: any) => {
  toast.success('Form saved successfully.');

  return { ...ctx };
});

export const showErrorNotify = assign((ctx: any, e: any) => {
  toast.error("Unable to save. Please check the form's data.");

  return { ...ctx };
});

export const addFileToDelete = assign((ctx: any, e: any) => ({
  ...ctx,
  filesToDelete: [...ctx.filesToDelete, e.file],
}));

export const addSignature = assign((ctx: any, e: any) => ({
  ...ctx,
  signature: e.signature,
}));

export const removePreviousSignature = assign((context: any) => {
  const { signature } = context;
  return context;
});

export const removeFile = assign({
  files: (ctx: any, e: any) => ctx.files.filter((file: any) => file.name !== e.file.name),
});

export const renameFile = assign((ctx: any, e: any) => {
  const newTempFiles = ctx.tempFiles.map((file: any) => {
    if (file.key !== e.file.key) return file;
    return { ...file, name: e.file.name };
  });
  return { ...ctx, tempFiles: newTempFiles };
});

export const removeFileFromTemp = assign((ctx: any, e: any) => ({
  ...ctx,
  tempFiles: ctx.tempFiles.filter((file: any) => file.key !== e.file.key),
}));
export const addFileToTempFiles = assign((ctx: any, e: any) => ({
  ...ctx,
  tempFiles: [...ctx.tempFiles, e.file],
}));

/* PHOTO */
export const addTempPhoto = assign((ctx: any, e: any) => {
  return { ...ctx, tempPhotos: [...ctx.tempPhotos, e.photo] };
});
export const removeTempPhoto = assign((ctx: any, e: any) => {
  const newTempPhotos = ctx.tempPhotos.filter((photo: any) => photo.key !== e.photo.key);
  return { ...ctx, tempPhotos: newTempPhotos };
});
export const changePhotoName = assign({
  tempPhotos: (ctx: any, e: any) => {
    return ctx.tempPhotos.map((photo: any) => {
      if (photo.key !== e.photo.key) return photo;
      return { ...photo, name: e.name, path: e.name };
    });
  },
});
/* */

export const updateDetailConfig = (context: any) => {
  const affectedItems = context.config.filter(
    (item: any) => item.ACTIVE && item.FIELDLEVEL === 'Detail',
  );
  if (!affectedItems.length) return;
  const pageGroups = affectedItems.map((item: any) => item.PAGEGROUP);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const uniqueGroups = [...new Set(pageGroups)]; // remove dublicates
  context.details.forEach((detail: any) =>
    uniqueGroups.forEach((name) => {
      if (name === detail.name) {
        detail.machineRef.send({ type: 'UPDATE_CONFIG', config: affectedItems });
      }
    }),
  );
};
export const createNewEntryConfigUpdate = assign((ctx: any, e: any) => {
  const configMatchIndex = ctx.config.findIndex(
    (configItem: any) => e.data.fieldId === configItem.FIELDID,
  );
  const newConfig = [...ctx.config];
  const displayValue = getListDisplayValue(
    newConfig[configMatchIndex].FIELDDISPLAY,
    e.data.data,
  );
  newConfig[configMatchIndex] = {
    ...newConfig[configMatchIndex],
    FIELDVALUE: e.data.fieldValue,
    DISPLAYVALUE: displayValue,
  };
  return { ...ctx, config: newConfig };
});
export const executeCallback = (ctx: any) => {
  if (ctx.callback) ctx.callback();
};

/* ----------------------------------------------------- */
export const clearCalcError = assign((ctx) => ({ ...ctx, calcError: undefined }));
export const setCalculating = assign((ctx) => ({ ...ctx, isCalculating: true }));
export const assignCalcError = assign((ctx: any, e: any) => ({
  ...ctx,
  calcError: e.data.message,
}));
export const removeTouchedFields = assign((ctx: any) => {
  const newConfig = ctx.config.map((item: any) => ({ ...item, FIELDISTOUCHED: false }));
  return { ...ctx, config: newConfig };
});

export const replaceValues = assign((context: any, event: any) => {
  const newConfig = updateConfigWithCalcData(context.config, event.data);
  return { ...context, config: newConfig };
});
export const replaceDetailStore = (context: any, event: any) => {
  const newDetails = updateStoreWithCalcData(context, event.data);
  context.details.forEach((detail: any) => {
    if (!newDetails[detail.name].length) return;
    detail.machineRef.send({ type: 'UPDATESTORE', store: newDetails[detail.name] });
  });
};
export const replaceDetailDraft = (context: any, event: any) => {
  if (event.data.draft) {
    const draft = updateDraftWithCalcData(event.data);
    context.details.forEach((detail: any) => {
      if (detail.name !== event.data.name) return;
      detail.machineRef.send({ type: 'UPDATEDRAFT', draft });
    });
  }
};

export const finishCalculating = (context: any, event: any) => {
  context.isCalculating = false;
};

export const requestDetails = (context: any) => {
  context.details.forEach((detail: any) =>
    detail.machineRef.send({ type: 'REQUESTDETAILS' }),
  );
};
export const initDetailReady = assign({
  details: (context: any) =>
    context.details.map((detail: any) => {
      return {
        ...detail,
        ready: false,
      };
    }),
});
export const updateDetailsWithStore = assign({
  details: (context: any, event: any) =>
    context.details.map((detail: any) => {
      if (event.name !== detail.name) return detail;
      return { ...detail, store: event.store, draft: event.draft, ready: true };
    }),
});

/* ----------------------------------------------------- */
const { showMessages } = publicWindow;
// export const renderCalcMessage = (_, e) => {
// const { message } = e.data;
// if (!message || message === '') return;
// const messages = message.split('\r\n');
// messages.forEach((item) => toast.success(item, { subType: 'warning' }));
// };
export const renderCalcMessage = (_: any, e: any) => {
  if (showMessages === 1) {
    const { message } = e.data;
    if (!message || message === '') return;
    const messages = message.split('\r\n');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    messages.forEach((item: any) => toast.success(item, { subType: 'warning' }));
  }
  if (showMessages === 2) {
    // Retrieve the uniqueMessages string from sessionStorage
    const uniqueMessagesString = sessionStorage.getItem('uniqueMessages');
    const uniqueMessages = uniqueMessagesString ? JSON.parse(uniqueMessagesString) : [];

    // Retrieve the previous state of the uniqueMessages array from sessionStorage
    const prevUniqueMessagesString = sessionStorage.getItem('prevUniqueMessages');
    const prevUniqueMessages = prevUniqueMessagesString
      ? JSON.parse(prevUniqueMessagesString)
      : null;

    // Check if the messages have already been rendered
    const messagesRendered = sessionStorage.getItem('messagesRendered');

    // If messages have already been rendered, and there are differences between the current and previous state
    if (messagesRendered && prevUniqueMessages) {
      // Find added or changed messages
      const addedOrChangedMessages = uniqueMessages.filter(
        (message: any) => !prevUniqueMessages.includes(message),
      );

      // Render added or changed messages
      addedOrChangedMessages.forEach((item: any) => {
        // Show the toast for each message
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toast.success(item, { subType: 'warning' });
      });
    } else {
      // Render all messages for the first time
      uniqueMessages.forEach((item: any) => {
        // Show the toast for each message
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        toast.success(item, { subType: 'warning' });
      });
    }
    // Update the previous state of the uniqueMessages array in sessionStorage
    sessionStorage.setItem('prevUniqueMessages', JSON.stringify(uniqueMessages));
    // Set the flag to indicate that messages have been rendered
    sessionStorage.setItem('messagesRendered', 'true');
  }
};
/* ----------------------------------------------------- */
export const renderErrorMessage = (title: any) => (_: any, e: any) => {
  const { message } = e.data;
  if (!message || message === '') return;
  const messages = message.split('\r\n');
  messages.forEach((item: any) => toast.error(`${title}\n${item}`));
};
export const renderFilesError = (title: any) => (_: any, e: any) => {
  const deleteResults = e.data;
  if (!Array.isArray(deleteResults)) return;
  deleteResults
    .filter((p) => p.status === 'rejected')
    .forEach((p) => toast.error(`${title}\n${String(p.reason)}`));
};

export const removeSignaturePath = assign({ signaturePath: () => undefined });
export const addSignatureToFiles = assign({
  files: (ctx: any, e: any) => [...ctx.files, e.data],
});

// reset files if delete service failed
export const resetFiles = assign({
  files: (ctx: any, e: any) => {
    if (!Array.isArray(e.data)) return ctx.files;
    const successResults = e.data.filter((p: any) => p.status === 'fulfilled');
    const resetFiles = ctx.filesToDelete.filter((file: any) => {
      return successResults.every((p: any) => p.value !== file.name);
    });
    return [...ctx.files, ...resetFiles];
  },
});
export const addUploadedFiles = assign((ctx: any, e: any) => {
  if (!Array.isArray(e.data)) return { ...ctx };
  const successFiles = e.data
    .filter((p: any) => p.status === 'fulfilled')
    .map((p: any) => p.value);
  return { ...ctx, files: [...ctx.files, ...successFiles] };
});
