import {
  EditIcon,
  DeleteIcon,
  PrintIcon,
  CopyIcon,
} from 'components/shared/Icons/ActionIcons';
import { StyledFlex } from 'components/shared/shared.style.';

type Props = {
  id: string;
  send: any;
  print?: boolean;
  edit?: boolean;
  trash?: boolean;
  copy?: any;
};

export const Actions = ({ id, send, print, edit, trash, copy }: Props) => {
  return (
    <StyledFlex $gridGap={4} justifyContent='start'>
      {copy && <CopyIcon data-cy='copyIcon' onClick={() => copy(id, true)} />}
      {print && <PrintIcon onClick={() => send({ type: 'getColumnPdf', id })} />}
      {edit && (
        <EditIcon
          data-testid='editIcon'
          onClick={() => send({ type: 'updateStore', id })}
        />
      )}
      {trash && (
        <DeleteIcon
          data-cy='deleteIcon'
          data-testid='deleteIcon'
          onClick={() => send({ type: 'removeFromStore', id })}
        />
      )}
    </StyledFlex>
  );
};
