import { createMachine } from 'xstate';

import { updateItemValues } from 'machines/shared/actions/actions';

import {
  addItemToStore,
  updateStore,
  updateEopyyForEdit,
  resetState,
  updateStoreItem,
  removeFromStore,
  sendStoreToParent,
  updateConfig,
  updateInitialConfig,
  updateStoreWithCalcData,
  spawnEopyyMachine,
  resetEopyy,
  updateStoreTableUpdate,
  autoCompleteUpdate,
  abortCalculate,
  triggerCalculate,
  updateDraftWithCalcData,
  updateEopyyVariants,
  handleOneItem,
} from './DetailMachine.actions';

export const createDetailMachine = (config = [], detailStore = [], name: any) => {
  return createMachine({
    id: `detailMachine ${name}`,
    initial: 'adding',
    context: {
      name: name,
      config: config,
      initialConfig: config,
      store: detailStore,
      activeId: undefined,
      eopyyMachineRef: undefined,
    },
    entry: spawnEopyyMachine,
    states: {
      adding: {
        on: {
          ADD_TO_STORE: {
            actions: [addItemToStore, resetState, resetEopyy, triggerCalculate],
          },
          updateStore: {
            target: 'editing',
            actions: [abortCalculate, updateStore, updateEopyyForEdit],
          },
          removeFromStore: {
            actions: [removeFromStore, triggerCalculate],
          },
          handleOneItem: {
            actions: [handleOneItem],
          },
        },
      },
      editing: {
        on: {
          SAVE: {
            target: 'adding',
            actions: [updateStoreItem, resetState, resetEopyy, triggerCalculate],
          },
          CANCEL: {
            target: 'adding',
            actions: [abortCalculate, resetState, resetEopyy],
          },
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    on: {
      DETAILS_TABLE_UPDATE: {
        actions: [updateStoreTableUpdate, triggerCalculate],
      },
      AUTO_COMPLETE: {
        actions: [autoCompleteUpdate],
      },
      REQUESTDETAILS: {
        actions: [sendStoreToParent],
      },
      UPDATESTORE: {
        // triggerCalculate infinite loop an to valoyme san action
        actions: [updateStoreWithCalcData],
      },
      UPDATE_EOPYY_VARIANTS: {
        actions: [updateEopyyVariants, triggerCalculate],
      },
      UPDATEDRAFT: {
        actions: [updateDraftWithCalcData],
      },
      UPDATE_VALUE: {
        actions: [updateItemValues, triggerCalculate],
      },
      UPDATE_BOOLEAN: {
        actions: [updateItemValues],
      },
      UPDATE_CONFIG: {
        actions: [updateConfig, updateInitialConfig],
      },
    },
  });
};
