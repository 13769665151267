import styled from 'styled-components';

import { device } from 'config/device';

type BubbleWrapperProps = {
  $lineWidth: number;
};

const StepsInfoContainer = styled.ul<BubbleWrapperProps>`
  width: 100%;
  height: 150px;
  display: flex;
  padding: 1rem 1.5rem;
  grid-gap: 0.5rem;
  overflow-y: hidden;
  overflow-x: auto;
  justify-content: ${({ $lineWidth }) =>
    $lineWidth > 0 ? 'space-between' : 'flex-start'};
  @media ${device.mobile} {
    padding: 1rem;
    padding-bottom: 0;
  }
`;

export { StepsInfoContainer };
