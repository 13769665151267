import { toast } from 'react-hot-toast';
import { VscGoToFile } from 'react-icons/vsc';
import { useMutation } from 'react-query';

import { OverlayLoading } from 'components/shared/Loading';
import { getArtieFile } from 'helpers/services/services.artie';

import { ViewFIleButton } from './File.style';

type Props = {
  name: string;
  basePath: string;
};

export const ArtieFile = ({ name, basePath }: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { mutate, status } = useMutation(getArtieFile, {
    onError: (error: any) => toast.error(error.message),
    onSuccess: (blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
  });

  return (
    <>
      <OverlayLoading isLoading={status === 'loading'} delay={400} />
      <ViewFIleButton
        onClick={() => {
          const serverPath = `${basePath}${name}`;
          mutate({ serverPath });
        }}
      >
        File
        <VscGoToFile />
      </ViewFIleButton>
    </>
  );
};
