import * as React from 'react';

import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { utils, write } from 'xlsx';

const ExcelButton = styled.button`
  overflow: visible;
  font-size: 100%;
  line-height: 1.15;
  font-family: inherit;
  border: 0;
  margin: 0 !important;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

import { ReactComponent as ExcelSvg } from 'assets/icons/excel.svg';

type ExcelExportProps = {
  columns: any[];
  data: any[];
  buttonStyles?: React.CSSProperties;
};

export const ExcelExport = ({ columns, data, buttonStyles = {} }: ExcelExportProps) => {
  const excelColumns = React.useMemo(() => {
    return columns.filter((col) => col.Header !== 'Actions');
  }, [columns]);

  const columnTitles = excelColumns.map((col) => col.accessor);

  const updatedData = data?.map((row) => {
    const result = {};

    for (const title of columnTitles) {
      if (Object.prototype.hasOwnProperty.call(row, title)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[title] = row[title];
      }
    }

    return result;
  });

  const headerColumns = updatedData?.length
    ? Object.keys(updatedData[0]).reduce((acc, key, index) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[key] = excelColumns[index].Header;

        return acc;
      }, {})
    : undefined;

  const handleDownload = () => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([headerColumns, ...updatedData], {
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, 'Data');
    const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `Portal ${format(new Date(), 'yyy/MM/dd HH:mm:ss')}.xlsx`,
    );
  };

  return (
    <ExcelButton style={buttonStyles} onClick={handleDownload}>
      <ExcelSvg style={{ height: 30, width: 30 }} />
    </ExcelButton>
  );
};
