import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ReactComponent as Left } from 'assets/icons/arrow-thick-left.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { device } from 'config/device';

export const CloseIcon = styled(Close)`
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 100;
  cursor: pointer;
  fill: ${(props) => props.theme.gray};
  :hover {
    fill: ${(props) => props.theme.text};
  }
`;

export const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  padding-top: 10rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  @media ${device.mobile} {
    padding-top: 6rem;
  }
`;

type ModalMidScreenProps = {
  askUser?: boolean;
};

export const ModalMidScreen = styled.div<ModalMidScreenProps>`
  z-index: 99;
  position: relative;
  margin: 1rem;
  padding: 2rem 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.45);
  background-color: ${(props) => props.theme.grayLight};
  height: auto;
  width: auto
    ${(props) => {
      if (props.askUser) {
        return { height: 'auto', width: 'auto' };
      }
      return {
        height: '85%',
        width: '45%',
        [`@media ${device.desktop}`]: {
          width: '60%',
        },
        [`@media ${device.laptop}`]: {
          width: '80%',
        },
        [`@media ${device.tablet}`]: {
          width: '90%',
          height: '75%',
        },
      };
    }};
`;

export const ModalLayout = styled(motion.div)`
  position: fixed;
  margin-top: 10rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  overflow: auto;
  background-color: ${(props) => props.theme.grayLight};
  @media ${device.mobile} {
    margin-top: 6rem;
  }
`;

export const ModalContent = styled.div`
  height: calc(100% - 10rem);
  padding: 1.5rem 1rem;
  @media ${device.tablet} {
    padding: 1.5rem 0.5rem;
  }
`;

export const ArrowLeftIcon = styled(Left)`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  fill: ${(props) => props.theme.gray};
`;
export const BackContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  padding-right: 2.5rem;
  color: ${(props) => props.theme.gray};
  background: ${({ theme }) => theme.disable};
  box-shadow: ${({ theme }) => theme.shadow[2]};
  span {
    cursor: pointer;
    text-transform: uppercase;
  }
`;
export const BackWrapper = styled.div`
  grid-gap: 0.5rem;
  display: flex;
  align-items: center;
  :hover {
    color: ${(props) => props.theme.text};
    svg {
      fill: ${(props) => props.theme.text};
    }
  }
`;
