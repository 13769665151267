import styled, { css } from 'styled-components';

import { ReactComponent as AreaChart } from 'assets/icons/area.svg';
import { ReactComponent as BarChart } from 'assets/icons/bar.svg';
import { device } from 'config/device';

export const iconStyles = css`
  height: 2rem;
  width: 2rem;
  fill: white;
`;

export const AreaChartIcon = styled(AreaChart)`
  ${iconStyles}
`;
export const BarChartIcon = styled(BarChart)`
  ${iconStyles}
`;

export const Container = styled.div`
  margin: 2rem;
  overflow: hidden;
  grid-gap: 2rem;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    margin: 2rem 0;
  }
  .chart-wrapper {
    grid-gap: 2rem;
    display: flex;
    flex-wrap: wrap;
  }
  .chart-item {
    flex: 1;
    min-width: 70rem;
    @media ${device.laptop} {
      min-width: 50rem;
    }
    @media ${device.tablet} {
      min-width: 40rem;
    }
    @media ${device.mobile} {
      min-width: 30rem;
    }
  }
`;

type ChartContainerProps = {
  $isList: boolean;
};

export const ChartContainer = styled.div<ChartContainerProps>`
  .children {
    height: ${(props) => (props.$isList ? '55rem' : '45rem')};
    padding: 0 2rem;
    @media ${device.mobile} {
      padding: 0 1rem;
    }
  }
  .info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    h4 {
      color: ${({ theme }) => theme.error};
    }
  }
  .error {
    height: 10rem;
  }
  .loading {
    height: 45rem;
  }
`;

export const ChartHeader = styled.div`
  width: 100%;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    padding: 0 1rem;
  }
`;

type ChartButtonProps = {
  type?: string;
};

export const ChartButton = styled.div<ChartButtonProps>`
  min-width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  border: none;
  outline: none;
  padding: 0.5em;
  cursor: pointer;
  font-size: ${(props) => props.theme.small};
  font-weight: bold;
  color: white;
  background-color: ${(props) => props.theme.special};
  box-shadow: ${(props) => props.theme.shadow[2]};
  :hover {
    opacity: 0.7;
  }
  min-width: ${(props) => props.type === 'secondary' && '7rem'};
  color: ${(props) => props.type === 'secondary' && props.theme.gray2};
  background: ${(props) => props.type === 'secondary' && 'transparent'};
  border: ${(props) => props.type === 'secondary' && `1px solid ${props.theme.gray2}`};
`;
