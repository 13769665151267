import styled, { keyframes } from 'styled-components';

import { device } from 'config/device';

const SlideUp = keyframes`
  0% { opacity: 0; transform: translateY(0.5rem);}
  100% { opacity: 1; transform: translateY(0); }
`;
export const CalendarContainer = styled.div`
  position: relative;
  height: 100%;
  margin: 1.5rem 1rem;
  padding: 3rem 2rem;
  background: ${(props) => props.theme.white};
  .rbc-header {
    padding: 0.5rem;
  }
  .rbc-today {
    border: 2px solid ${(props) => props.theme.special}!important;
  }
  .rbc-event {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.special};
    animation-name: ${SlideUp};
    animation-duration: 0.5s;
  }
  @media ${device.mobile} {
    margin: 0;
    padding: 0.5rem;
    .rbc-toolbar {
      gap: 0.5rem;
      flex-direction: column;
    }
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 26rem;
`;
