import { useMemo } from 'react';

import { useActor } from '@xstate/react';
import { v4 as uuidv4 } from 'uuid';

import Bool from 'components/Bool/Bool';
import { DateContainer, DATE_TYPES } from 'components/DateInput';
import Eoppy from 'components/Eopyy/Eopyy';
import List from 'components/List/List';
import StringList from 'components/List/StringList';
import Search from 'components/Search/Search';
import { Actions } from 'components/Table/TablePicker/TableActions';
import TablePicker from 'components/Table/TablePicker/TablePicker';
import Text from 'components/Text/Text';
import {
  getEoppyItemCode,
  getEoppyItemsCode,
  getEopyyPatientCode,
} from 'helpers/helpers';
import { updateRelatedFields } from 'helpers/updateRelatedFields';

import {
  AddIcon,
  DetailButton,
  DetailContainer,
  DetailInputs,
  InformationContainer,
  StoreContainer,
  ButtonsContainer,
  CancelButton,
  SaveIcon,
} from './Detail.style';

type Props = {
  machineRef: any;
  parentConfig: any;
  readOnly: boolean;
  error?: any;
};

export default function Detail({ machineRef, parentConfig, readOnly, error }: Props) {
  const actor: any = useActor(machineRef);
  const [state, send] = actor;
  const { store, config, eopyyMachineRef } = state.context;
  const eoppyItemCode = getEoppyItemCode(config);
  const eopyyPatientCode = getEopyyPatientCode(parentConfig);
  const eoppyDetailItemCodes = getEoppyItemsCode(store);
  /*--- Conditional and parametric rendering of trash icon ---*/
  const serializedVariable = localStorage.getItem('portal-authMachine');
  const variable = JSON.parse(serializedVariable ? serializedVariable : '');
  //console.log('variable', variable);
  const contextObject = state.context.config[0];
  //console.log('pages', contextObject);
  let deleteVisible = '';
  //Find the object from localStorage that matches the target OBJECTID
  let matchedObject = null;
  let matchedObject2 = null;

  const isDisabled = config
    .filter((item: any) => {
      return Boolean(item.FIELDREQUIRED);
    })
    .some((item: any) => {
      return !item.FIELDVALUE;
    });

  for (const obj of variable.context.objects) {
    if (obj.OBJECTID === contextObject.OBJECTID) {
      matchedObject = obj;
      break;
    }
  }
  if (matchedObject) {
    for (const obj2 of matchedObject.PAGES) {
      if (obj2.PAGENUM === contextObject.PAGENUM) {
        matchedObject2 = obj2;
        break;
      }
    }
    //console.log('matchedObject2', matchedObject2);
    deleteVisible = matchedObject2.GROUPS[0].DELETEVISIBLE;
    //console.log('deleteVisible', deleteVisible);
  } else {
    console.log('No matching object found in localStorage.');
  }

  const isReadOnly = useMemo(() => {
    if (readOnly) return true;
    return config.every((item: any) => item.FIELDREADONLY);
  }, [readOnly, config]);

  const updatedDetails = useMemo(() => {
    const updatedConfig = parentConfig.map((item: any) => {
      const found = config.find((dItem: any) => dItem.FIELDID === item.FIELDID);
      return found ? found : item;
    });
    return config.map((item: any) => ({
      ...item,
      ...updateRelatedFields(updatedConfig, item),
    }));
  }, [parentConfig, config]);

  const updatedStore = useMemo(() => {
    return store.map((storeLine: any) => {
      const newItem = storeLine.item.reduce((acc: any, storeItem: any) => {
        if (storeItem.FIELDTYPE === 'Hidden') return acc;
        return [...acc, { ...storeItem, FIELDREADONLY: isReadOnly }];
      }, []);
      return { ...storeLine, item: newItem };
    });
  }, [store, isReadOnly]);

  function handleUpdate({ storeId, fieldCaption, value }: any) {
    send({ type: 'DETAILS_TABLE_UPDATE', storeId, fieldCaption, value });
  }
  function handleEopyySuccess({ storeId, variants }: any) {
    send({ type: 'UPDATE_EOPYY_VARIANTS', storeId, variants });
  }

  function handleOneItem(fieldId: number, rowId: string, operation: 'add' | 'subtract') {
    send({ type: 'handleOneItem', fieldId, rowId, operation });
  }

  const renderStore = () => {
    if (!store.length) return null;
    const actions = (id: any) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (deleteVisible === 1) {
        return <Actions edit trash {...{ id, send }} />;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      } else {
        // @ts-ignore
        if (deleteVisible === 0) {
          return <Actions edit {...{ id, send }} />;
        }
      }
    };
    return (
      <StoreContainer data-testid='store'>
        <TablePicker
          handleOneItem={handleOneItem}
          type='Detail'
          readOnly={isReadOnly}
          eopyyInfo={{
            eopyyPatientCode,
            eoppyDetailItemCodes,
            ref: eopyyMachineRef,
          }}
          handleUpdate={handleUpdate}
          handleEopyySuccess={handleEopyySuccess}
          keyData={updatedStore[0].item}
          valueData={updatedStore}
          //actions={(id) => <Actions edit trash {...{ id, send }} />}
          actions={actions}
        />
      </StoreContainer>
    );
  };
  const renderButton = () => {
    if (state.matches('editing')) {
      return (
        <ButtonsContainer>
          <CancelButton
            onClick={() => {
              send({ type: 'CANCEL' });
            }}
          >
            Cancel
          </CancelButton>
          <DetailButton onClick={() => send({ type: 'SAVE', item: config })}>
            SAVE
            <SaveIcon width='1.5rem' color='#fff' />
          </DetailButton>
        </ButtonsContainer>
      );
    }
    return (
      <DetailButton
        disabled={error || isDisabled}
        onClick={() => {
          send({ type: 'ADD_TO_STORE', id: uuidv4(), item: config });
        }}
      >
        ADD
        <AddIcon width='1.5rem' color='#fff' />
      </DetailButton>
    );
  };

  if (isReadOnly) {
    return (
      <DetailContainer>
        <InformationContainer readOnly={true}>{renderStore()}</InformationContainer>
      </DetailContainer>
    );
  }
  return (
    <DetailContainer>
      <DetailInputs>
        {updatedDetails.map((item: any) => {
          const key = item.FIELDID;
          if (item.REFFIELDID && !item.ACTIVE) return null;
          if (item.FIELDTYPE === 'Search') {
            return <Search key={key} {...{ item, send }} />;
          }
          if (item.FIELDTYPE === 'Eopyy') {
            return (
              <Eoppy
                key={key}
                {...{ item, send }}
                serviceRef={eopyyMachineRef}
                eoppyItemCode={eoppyItemCode}
                eopyyPatientCode={eopyyPatientCode}
              />
            );
          }
          if (DATE_TYPES.includes(item.FIELDTYPE)) {
            return (
              <DateContainer key={key} config={updatedDetails} {...{ item, send }} />
            );
          }
          if (item.FIELDTYPE === 'Text') {
            return <Text key={key} {...{ item, send }} isDetail />;
          }
          if (item.FIELDTYPE === 'Bool') {
            return <Bool key={key} {...{ item, send }} />;
          }
          if (item.FIELDTYPE === 'List') {
            return <List key={key} {...{ item, send }} />;
          }
          if (item.FIELDTYPE === 'String') {
            return <StringList key={key} {...{ item, send }} />;
          }
          return null;
        })}
      </DetailInputs>
      <InformationContainer>
        {renderButton()}
        {renderStore()}
      </InformationContainer>
    </DetailContainer>
  );
}
