import { useState } from 'react';

import styled from 'styled-components';

import { ClipLoader } from 'components/shared/Loading';

type WrapperProps = {
  isLoading: boolean;
  error: boolean;
  height?: string;
  width?: string;
};

const Wrapper = styled.div<WrapperProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.height ? props.height : '50px')};
  width: ${(props) => (props.width ? props.width : '70px')};
  img {
    width: 100%;
    height: 100%;
    display: ${(props) => (props.error || props.isLoading ? 'none' : 'block')};
  }
`;

type ImageProps = {
  src: string;
  height?: string;
  width?: string;
};

export function Image(props: ImageProps) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  return (
    <Wrapper isLoading={loading} {...{ props, error }}>
      <ClipLoader size={20} />
      <img
        src={props.src}
        onLoad={() => setLoading(false)}
        onError={() => {
          setError(true);
          setLoading(false);
        }}
      />
    </Wrapper>
  );
}
