import { useState, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import { ClipLoader } from 'components/shared/Loading';
import { StyledFlex } from 'components/shared/shared.style.';
import { publicWindow } from 'config/public';
import { useStepsContext, useStepsDispatch } from 'context/StepsProvider';
import { convertToBytes } from 'helpers/helpers';

import File from './File';
import {
  Span,
  Title,
  EditIcon,
  DeleteIcon,
  BrowseButton,
  Container,
  FileWrapper,
  FilesWrapper,
  FolderIconWrapper,
  DragAndDropContainer,
} from './FilesUploader.style';

const { artieSizeLimit } = publicWindow;
const sizeLimitInBytes = convertToBytes(artieSizeLimit);

type Props = {
  readOnly?: boolean;
  softoneSubmitKey: string;
};

const FilesUpload = ({ readOnly, softoneSubmitKey }: Props) => {
  const [loading, setLoading] = useState(false);
  const [fileToEditName, setFileToEditName] = useState(null);
  const state = useStepsContext();
  const send = useStepsDispatch();
  const allFiles = state.context.files || [];
  const allTempFiles = state.context.tempFiles || [];

  function removeFile(file: any) {
    send({ type: 'removeFile', file });
  }
  function removeFileFromTemp(file: any) {
    send({ type: 'removeFileFromTemp', file });
  }

  const groupFiles = allFiles.filter(
    (file: any) => file.softoneSubmitKey === softoneSubmitKey,
  );
  const groupTempFiles = allTempFiles.filter(
    (file: any) => file.softoneSubmitKey === softoneSubmitKey,
  );

  const onDrop = useCallback(
    (uploadedFiles: any) => {
      // Read the files that have been dropped
      uploadedFiles.forEach((file: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadstart = () => setLoading(true);
        reader.onloadend = () => setLoading(false);
        reader.onabort = () => console.log('file reader aborted');
        reader.onerror = () => console.log('file reader failed');
        reader.onload = () => {
          if (artieSizeLimit && file.size > sizeLimitInBytes) {
            return toast.error(
              `File is too large. Size must be less than ${artieSizeLimit}`,
            );
          }
          // Check for duplicate filenames
          let fileName = file.name;
          let fileCounter = 1;
          while (
            allTempFiles.some((tempFile: any) => tempFile.name === fileName) ||
            allFiles.some((tempFile: any) => tempFile.name === fileName)
          ) {
            const [name, extension] = file.name.split('.');
            fileName = `${name}-(${fileCounter}).${extension}`;
            fileCounter++;
          }
          send({
            type: 'fileUpload',
            file: {
              data: reader.result,
              name: fileName, // Use the adjusted filename
              path: file.path,
              size: file.size,
              key: file.name + uuidv4(),
              softoneSubmitKey,
            },
          });
        };
      });
    },
    [softoneSubmitKey, send, allTempFiles, sizeLimitInBytes, artieSizeLimit],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  function onBlur() {
    const { newName, ...orignalFile } = fileToEditName as any;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const oldFileExtension = fileToEditName.name.split('.').pop();
    const newFileExtension = newName.split('.').pop();
    const isNewNameValid =
      newName.trim().length > 0 && oldFileExtension === newFileExtension;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const newFileName = isNewNameValid ? fileToEditName.newName : fileToEditName.name;
    const newFileWithUpdatedName = { ...orignalFile, name: newFileName };
    send({ type: 'renameFile', file: newFileWithUpdatedName });
    setFileToEditName(null);
  }
  function onChange(e: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFileToEditName({ ...fileToEditName, newName: e.target.value });
  }
  function renameFile(file: any) {
    setFileToEditName({ ...file, newName: file.name });
  }

  return (
    <Container>
      {!readOnly && (
        <>
          <Title>Choose or drag and drop files (max {artieSizeLimit})</Title>
          <DragAndDropContainer {...getRootProps()}>
            <FolderIconWrapper>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z'
                />
              </svg>
            </FolderIconWrapper>
            <Span>or</Span>
            <input {...getInputProps()} />
            <BrowseButton onClick={open}>Local Files</BrowseButton>
          </DragAndDropContainer>
        </>
      )}
      {(() => {
        if (!groupTempFiles.length) return null;
        return (
          <>
            <StyledFlex $gridGap={10} $marginTop={'10px'} $alignItems='center'>
              <h3 style={{ marginTop: '15px' }}>Files to upload</h3>
              <ClipLoader size={20} isLoading={loading} delay={400} />
            </StyledFlex>
            <FilesWrapper>
              {groupTempFiles.map((file: any) => (
                <FileWrapper key={file.key}>
                  {(() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (file.key === fileToEditName?.key) {
                      return (
                        <ConfigInput
                          style={{
                            fontSize: '1.6rem',
                            marginTop: 0,
                            padding: '0.5rem 1rem',
                            width: 'auto',
                            minWidth: 'auto',
                            border: 'none',
                            background: 'inherit',
                            boxShadow: 'none',
                            outline: '1px solid rgba(0,0,0,0.2)',
                          }}
                          autoFocus
                          key={file.key}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          value={fileToEditName.newName}
                          onBlur={onBlur}
                          onChange={onChange}
                        />
                      );
                    }
                    return (
                      <div className='wrapper'>
                        <div className='name'>{file.name}</div>
                        {(() => {
                          if (readOnly) return null;
                          return (
                            <div className='actions'>
                              <DeleteIcon onClick={() => removeFileFromTemp(file)} />
                              <EditIcon onClick={() => renameFile(file)} />
                            </div>
                          );
                        })()}
                      </div>
                    );
                  })()}
                </FileWrapper>
              ))}
            </FilesWrapper>
          </>
        );
      })()}
      {(() => {
        if (!groupFiles.length) return null;
        return (
          <>
            <h3 style={{ marginTop: 10 }}>Uploaded Files</h3>
            <FilesWrapper>
              {groupFiles.map((file: any, idx: number) => (
                <File
                  readOnly={readOnly}
                  basePath={file.basePath}
                  key={file + idx}
                  name={file.name}
                  remove={() => removeFile(file)}
                />
              ))}
            </FilesWrapper>
          </>
        );
      })()}
    </Container>
  );
};

export default FilesUpload;
