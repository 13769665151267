import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import Footer from 'components/shared/Layouts/Footer';
import Drawer from 'components/shared/Modal/Drawer';
import { BranchCompany, BranchUser } from 'components/shared/ui/UserInfo';
import { publicWindow } from 'config/public';
import { useUserConfiguration } from 'config/themes';
import { useAuth, useAuthDispatch } from 'context/AuthProvider';

import AccountMenu from './AccountMenu';
import {
  CloseIcon,
  NavlinksWrapper,
  NavMenuWrapper,
  NavStaticLinkWrapper,
} from './NavMenu.style';
import NavMenuLink from './NavMenuLink';
import { NavLogo } from '../NavBar.style';

const { ssoSn } = publicWindow;
const { ssoRedirectUrl } = publicWindow;

type NavMenuProps = {
  isOverFlown: boolean;
};

function NavMenu({ isOverFlown }: NavMenuProps) {
  const [issuer, setIssuer] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { logoPath, logoHeight } = useUserConfiguration();

  const authState = useAuth();
  const authSend = useAuthDispatch();
  const isAuthorized = authState.matches('authorized');
  const isInitialized = authState.matches('authorized.initialized');
  const { objects = [], user, branch } = authState.context;

  useEffect(() => {
    const storedValue = localStorage.getItem('issuer');
    if (storedValue) {
      setIssuer(storedValue);
    }
  }, [isOpen]);

  function goHome() {
    setIsOpen(false);
    navigate('/');
  }

  function logout() {
    if (issuer !== undefined && issuer !== null && issuer !== '') {
      window.location.href =
        'https://sso.softonecloud.com/SAML/InitiateSingleLogout/' +
        `${ssoSn}` +
        '?issuer=' +
        `${issuer}` +
        '&redirectUrl=' +
        `${ssoRedirectUrl}`;
    }
    localStorage.clear();
    setIsOpen(false);
    authSend({ type: 'LOGOUT' });
    navigate('/', { replace: true });
  }

  if (!isAuthorized) return null;
  return (
    <NavMenuWrapper $isOverFlown={isOverFlown}>
      <div className='menu-hamburger-icon' onClick={() => setIsOpen(true)}>
        <svg data-cy='burger-menu-icon' viewBox='0 0 100 80' width='40' height='40'>
          <rect width='100' height='12'></rect>
          <rect y='30' width='100' height='12'></rect>
          <rect y='60' width='100' height='12'></rect>
        </svg>
      </div>
      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        renderHeader={() => (
          <>
            <NavLogo
              alt='app-logo'
              src={logoPath}
              $logoHeight={logoHeight}
              onClick={() => navigate('/')}
              style={{ maxWidth: '70%' }}
            />
            <CloseIcon onClick={() => setIsOpen(false)} />
          </>
        )}
        renderInfo={() => (
          <>
            <BranchUser {...{ user, branch }} />
            <hr />
            <BranchCompany {...{ branch }} />
            <hr />
          </>
        )}
        renderFooter={() => <Footer screen='drawer' />}
      >
        <NavlinksWrapper style={{ margin: '0' }}>
          <NavStaticLinkWrapper onClick={goHome}>
            Home
            <HomeIcon className='menu-icon' />
          </NavStaticLinkWrapper>
          {isInitialized &&
            objects.map((object: any) => (
              <NavMenuLink
                key={object.OBJECTPATH}
                object={object}
                closeMenu={() => setIsOpen(false)}
              />
            ))}
          <AccountMenu branch={branch} closeMenu={() => setIsOpen(false)} />
          <NavStaticLinkWrapper onClick={logout}>
            Logout
            <Logout className='menu-icon' />
          </NavStaticLinkWrapper>
        </NavlinksWrapper>
      </Drawer>
    </NavMenuWrapper>
  );
}

export default NavMenu;
