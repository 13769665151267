import styled from 'styled-components';

import { device } from 'config/device';

type NavLinkWrapperProps = {
  $isOverFlown: boolean;
};

export const NavLinkWrapper = styled.div<NavLinkWrapperProps>`
  height: 100%;
  width: 100%;
  margin-left: 5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props) => props.theme.primary};
  visibility: ${(props) => (props.$isOverFlown ? 'hidden' : 'visible')};
  .arrow {
    margin-left: auto;
  }
  a {
    height: 100%;
    width: max-content;
    padding: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: ${(props) => props.theme.white};
    font-weight: 500;
    font-size: ${(props) => props.theme.small};
  }
    @keyframes onActive {
      from {
        transform: scaleY(0);
      }
      to {
        transform: scaleY(1);
      }
    }
  }
  @media ${device.tablet} {
    display: none;
  }
`;
