import { useMemo } from 'react';

import { useAuth } from 'context/AuthProvider';
import { isObjEmpty } from 'helpers/helpers';

import { publicWindow } from './public';

const defaultTHeme = (theme: any) => {
  return {
    primary: 'rgb(240,240,240)',
    secondary: 'rgb(111,112,116)',
    tertiary: theme.navbarColor,
    special: theme.specialColor,
    green: theme.successColor,
    gray: '#7F7F7F',
    gray2: '#575C66',
    text: 'rgb(34,34,34)',
    white: '#fff',
    error: '#ED2B2A',
    disable: '#DADEE3',
    grayLight: '#ECEBF2',
    grayLight2: '#F9F9F9',
    greenLight: 'rgb(111,173,69)',
    xx_small: '1rem',
    x_small: '1.25rem',
    small: '1.5rem',
    medium: '1.75rem',
    large: '2rem',
    x_large: '2.25rem',
    xx_large: '2.5rem',

    shadow: [
      '0 0 0 1px rgba(0, 0, 0, 0.05)',
      '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    ],
  };
};

export function useUserConfiguration() {
  const authState = useAuth();
  const { branchConfig } = authState.context;
  return useMemo(() => {
    if (isObjEmpty(branchConfig)) {
      return { ...publicWindow, theme: defaultTHeme(publicWindow) };
    }
    return { ...branchConfig, theme: defaultTHeme(branchConfig) };
  }, [defaultTHeme, branchConfig, publicWindow]);
}

export default defaultTHeme;
