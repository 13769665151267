import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/icons/cheveron-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/cheveron-up.svg';

const arrowStyle = css`
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  min-height: 2em;
  min-width: 2rem;
  margin-left: 1rem;
  fill: #fff;
`;
export const StyledArrowUp = styled(ArrowUp)`
  ${arrowStyle};
`;
export const StyledArrowDown = styled(ArrowDown)`
  ${arrowStyle};
`;

type DropListProps = {
  $isActive: boolean;
};

export const DropList = styled.div<DropListProps>`
  min-width: 100%;
  z-index: 2;
  position: absolute;
  top: 75%;
  right: 0;
  display: ${(props) => (props.$isActive ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: ${(props) => props.theme.shadow[3]};
  border-radius: 3px;
  background-color: ${(props) => props.theme.grayLight};
  :before {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    z-index: 3;
    right: 2.4rem;
    top: -0.5rem;
    transform: rotate(45deg);
    background-color: ${(props) => props.theme.grayLight};
    box-shadow: -1px -1px 2px -1px ${(props) => props.theme.gray2};
  }
`;

type LinkInfoWrapperProps = {
  $hasIcon: boolean;
};

export const LinkInfoWrapper = styled.div<LinkInfoWrapperProps>`
  width: 100%;
  padding: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => props.theme.gray2};
  cursor: pointer;
  border-bottom: 1px solid ${(props) => `${props.theme.gray2}66`};
  :last-child {
    border-bottom: none;
  }
  &:hover {
    ${(props) => {
      if (props.$hasIcon) return { opacity: 0.7 };
    }}
  }
`;
