import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIconSvg } from 'assets/icons/edit.svg';
import { Button } from 'components/shared/Button/Button';
import { device } from 'config/device';

export const DeleteIcon = styled(CloseIcon)`
  width: 1.8rem !important;
  fill: ${({ theme }) => theme.error};
  flex-shrink: 0;
  margin-left: 0.5rem;
  cursor: pointer;
`;

export const EditIcon = styled(EditIconSvg)`
  width: 1.8rem !important;
  margin-left: 1rem;
  flex-shrink: 0;
  cursor: pointer;
`;

export const FilesWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
`;

export const FileWrapper = styled.div`
  padding: 0 0.5rem;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }
  .name {
    max-width: 15rem;
    word-break: break-word;
  }
  .actions {
    display: flex;
    align-items: center;
  }
  @media ${device.mobile} {
    width: 100%;
    .name {
      max-width: 100%;
    }
  }
`;

type LoadWrapperProps = {
  width: number;
  height: number;
};

export const LoadWrapper = styled.div<LoadWrapperProps>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 15px;
  @media ${device.mobile} {
    align-items: center;
  }
`;

export const BrowseButton = styled(Button)`
  padding: 5px 10px;
  border-radius: 3px;
  margin: 0;
  position: relative;
  background: ${({ theme }) => theme.secondary};
  margin-top: 5px;
  font-size: ${({ theme }) => theme.x_small};
`;

export const DragAndDropContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px dotted;
  border-color: ${({ theme }) => theme.secondary};
`;

export const Span = styled.span`
  font-weight: bold;
  opacity: 0.3;
  color: ${({ theme }) => theme.lightGray};
`;

export const FolderIconWrapper = styled.div`
  svg {
    color: ${({ theme }) => theme.secondary};
    height: 40px;
    width: 40px;
  }
`;

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 10px;
`;
