import Axios from 'axios';

import { FILES_API } from 'config/API';
import { publicWindow } from 'config/public';

const { artieWebId, artieCustomer } = publicWindow;

export const uploadArtieFile = async (file: any, basePath: any) => {
  const [, data64] = file.data.split(',');
  const response = await Axios.post(`${FILES_API}/UploadFileBase64/${artieWebId}`, {
    customer: artieCustomer,
    filePath: `${basePath}${file.name}`,
    fileBase64: data64,
    OverwriteIfExists: true,
  }).catch((error) => {
    if (error.response) return error.response;
    throw new Error(error.message);
  });
  const resData = response.data;
  if (!resData.success) throw new Error(resData.message);
  return { ...file, serverPath: resData.data };
};

export const getArtieFile = async ({ serverPath }: any) => {
  const response = await Axios.post(
    `${FILES_API}/DownloadFileStream/${artieWebId}`,
    { customer: artieCustomer, filePath: `${serverPath}` },
    { responseType: 'blob' },
  ).catch((error) => {
    // temporary solution, responsetype blob dont send error
    if (error.response) throw new Error('File Error');
    throw new Error(error.message);
  });
  return response.data;
};

export const deleteArtieFile = async (
  file: any,
  basePath: any,
  resignatureStatus: any,
) => {
  const filePath =
    resignatureStatus === 1 ? `${basePath}${file.name}` : `${file.basePath}${file.name}`;
  const response = await Axios.delete(`${FILES_API}/DeleteFile/${artieWebId}`, {
    data: {
      customer: artieCustomer,
      filePath: filePath,
    },
  }).catch((error) => {
    if (error.response) return error.response;
    throw new Error(error.message);
  });
  const resData = response.data;
  if (resData.success) return file.name;
  throw new Error(resData.message);
};
