import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/icons/cheveron-down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/cheveron-up.svg';

const svgStyle = css`
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
`;
export const PlaceHolderSvg = styled.div`
  ${svgStyle};
`;
export const SortDown = styled(ArrowDown)`
  ${svgStyle};
`;
export const SortUp = styled(ArrowUp)`
  ${svgStyle};
`;
