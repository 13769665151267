import axios from 'axios';

import { PORTAL_API } from 'config/API';
import { fillConfigWithData } from 'machines/shared/utils/fillConfigWithData';

function checkServerFailure({ data }: any) {
  if (data.success) return;
  throw new Error(data.error);
}
function replaceConfig(config: any) {
  return config.map(({ FIELDFORCECALC, ...item }: any) => {
    if (!FIELDFORCECALC) return item;
    return { ...item, CALCULATESKIP: FIELDFORCECALC };
  });
}

export const services = {
  gettingConfig: async () => {
    const configResult = await axios.post(PORTAL_API, {
      service: 'getConfig',
    });
    checkServerFailure(configResult);
    return replaceConfig(configResult.data.data);
  },
  gettingObjects: async () => {
    const objectsResult = await axios.post(PORTAL_API, {
      service: 'getObjects',
    });
    checkServerFailure(objectsResult);
    return objectsResult.data.data.OBJECT;
  },
  gettingLoginData: async () => {
    const loginDataResult = await axios.post(PORTAL_API, {
      service: 'getLoginData',
    });
    checkServerFailure(loginDataResult);
    return loginDataResult.data.data;
  },
  gettingSpecialFields: async (ctx: any) => {
    const { config, loginData } = ctx;
    return await fillConfigWithData(config, loginData);
  },
  gettingBranchConfig: async () => {
    const response = await axios.post(PORTAL_API, {
      service: 'getCompanyConfig',
    });
    const responseData = response.data;
    if (!responseData.success) {
      // we dont want to show any error if this service fail
      console.log(responseData.error);
      return {};
    }
    return responseData.data[0];
  },
  gettingCLgadgets: async () => {
    const response = await axios.post(PORTAL_API, {
      service: 'clloadgadgetsparams',
    });
    const responseData = response.data;
    if (!responseData.success) {
      // we dont want to show any error if this service fail
      console.log(responseData.error);
      return [];
    }
    return responseData.data;
  },
};
