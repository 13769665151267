import { isObjEmpty } from 'helpers/helpers';
import { getData, getSelectorStringData } from 'helpers/services/services.utils';

/* ----------------- Search Data ------------------- */
const emptySearchRequest = (fieldName: any) => ({
  fieldName,
  success: true,
  data: { [fieldName]: [] },
});
function extractSearchData(responses: any) {
  return responses.reduce((acc: any, response: any) => {
    const { data, success, fieldName } = response;
    if (!success || !data || isObjEmpty(data)) return acc;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [[values]] = Object.values(data);
    const [table, field] = fieldName.split('.');

    if (table in acc) {
      const groupTable = acc[table];
      if (!(field in groupTable)) {
        return { ...acc, [table]: { ...groupTable, [field]: [values] } };
      }
      const groupField = acc[table][field];
      return {
        ...acc,
        [table]: { ...groupTable, [field]: [...groupField, values] },
      };
    }
    return { ...acc, [table]: { [field]: [values] } };
  }, {});
}
function fillKeysWithValues(data: any, searchInfo: any) {
  return searchInfo.reduce((acc: any, searchData: any) => {
    const [table, field] = searchData.fieldName.split('.');
    const softOneObject = data[table];
    if (!softOneObject) return acc;
    const searchInfoWithKeys = softOneObject.map((item: any) => {
      return { ...searchData, key: item[field] };
    });
    return [...acc, ...searchInfoWithKeys];
  }, []);
}
export async function getSearchData(editData: any, searchInfo: any) {
  if (!searchInfo.length) return {};
  const newSearchInfo = fillKeysWithValues(editData, searchInfo);

  const allSearchPromises = newSearchInfo.map((searchData: any) => {
    // dont send requests without a key
    if (!searchData.key) {
      return emptySearchRequest(searchData.fieldName);
    }
    /* --------------------- */
    return getData({
      key: searchData.key,
      form: searchData.form || '',
      object: searchData.object || '',
      fieldName: searchData.fieldName,
      locateInfo: `${searchData.object}:${searchData.locateInfo}`,
    });
  });
  const searchResults = await Promise.all(allSearchPromises);
  return extractSearchData(searchResults);
}

/* ----------------- String List ------------------- */
function extractStringListData(responses: any) {
  return responses.reduce((acc: any, response: any) => {
    const { columns, data, editor } = response;
    if (!columns || !data || !editor) return acc;
    return { ...acc, [editor]: { columns, data } };
  }, {});
}
function cleanStringList(editData: any, stringListInfo: any) {
  return stringListInfo.reduce((acc: any, stringListValue: any) => {
    const { editor, fieldName } = stringListValue;
    const [object, name] = fieldName.split('.');

    const isDouble = acc.some((item: any) => item.editor === editor);
    if (isDouble) return acc;

    if (!(object in editData)) return acc;
    if (!editData[object][0]) return acc;

    const objectItems = editData[object][0];
    const calcListHasEditor = Object.keys(objectItems).some((key) => key === name);
    if (!calcListHasEditor) return acc;

    return [...acc, stringListValue];
  }, []);
}
export async function getStringData(editData: any, stringListInfo: any) {
  if (!stringListInfo.length) return {};
  const validStringList = cleanStringList(editData, stringListInfo);
  const allStringListPromises = validStringList.map((stringListValue: any) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getSelectorStringData('', { editor: stringListValue.editor }),
  );
  const stringListResults = await Promise.all(allStringListPromises);
  return extractStringListData(stringListResults);
}
