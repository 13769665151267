import { Suspense, useEffect, useMemo, useState } from 'react';

import Particles from '@tsparticles/react';
import { useMachine } from '@xstate/react';
//import axios from 'axios';
import * as jwtDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import useResizeObserver from 'use-resize-observer';

import {
  LoginContainer,
  Form,
  UserIcon,
  KeyIcon,
  FormMessage,
  LoginError,
  ParticlesContainer,
} from './Login.style';
import { Button } from '../../components/shared/Button/Button';
import Input from '../../components/shared/Input/Input';
import Footer from '../../components/shared/Layouts/Footer';
import { Loading } from '../../components/shared/Loading';
import { NotificationLoading } from '../../components/shared/ui/AnimatedText';
import { API } from '../../config/API';
import { publicWindow } from '../../config/public';
import { useUserConfiguration } from '../../config/themes';
import { saveVersion } from '../../config/Version';
import { useAuthDispatch } from '../../context/AuthProvider';
import { LoginMachine } from '../../machines/LoginMachine/LoginMachine';
import BranchSelection from '../BranchPage/BranchSelection';

let { normalLoginEnabled } = publicWindow;
const { loginLabelButton } = publicWindow;
const { ssoLabelButton } = publicWindow;
const { ssoEnabled } = publicWindow;
const { ssoSn } = publicWindow;
const { ssoRedirectUrl } = publicWindow;
const redirectUrl =
  'https://sso.softonecloud.com/' + `${ssoSn}` + '?redirectUrl=' + `${ssoRedirectUrl}`;

if (ssoEnabled === false && normalLoginEnabled === false) {
  normalLoginEnabled = true;
}

const LoginPage = () => {
  const [, setIssuer] = useState('');
  //console.log(issuer);
  const redirectToAnotherPage = () => {
    window.location.href = redirectUrl;
  };

  const getQueryStringToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokenFromQuery = urlParams.get('token');

    return tokenFromQuery || ''; // Return an empty string if token is undefined
  };

  useEffect(() => {
    const token = getQueryStringToken();
    if (token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const decoded = jwtDecode(token);
      setIssuer(decoded.Issuer);
      localStorage.setItem('issuer', decoded.Issuer);
      send({ type: 'LOGIN', username: decoded.UserId, password: `SSO:${token}` });
    } else {
      // Handle the case when the token is not present
    }
  }, []);

  const { appId = '2006' } = publicWindow;
  const { title } = useUserConfiguration();
  const { ref, width } = useResizeObserver();

  const authSend = useAuthDispatch();
  const [state, send] = useMachine(LoginMachine, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    context: { API, appId },
    actions: {
      goToHome: (ctx) => {
        authSend({
          type: 'AUTHORIZE',
          user: {
            appId: ctx.appId,
            username: ctx.username,
            clientID: ctx.clientID,
            loginClientID: ctx.loginClientID,
          },
          branch: {
            objs: ctx.objs,
            selected: ctx.authParams,
          },
        });
        saveVersion();
      },
    },
  });
  const { value, context } = state as any;

  const { register, handleSubmit, formState } = useForm({
    shouldUnregister: false,
  });

  const onSubmit = (data: any) => {
    send({ type: 'LOGIN', username: data.user, password: data.pass });
  };
  const onBranchSubmit = (e: any) => {
    e.preventDefault();
    send({ type: 'SUBMIT_BRANCH' });
  };

  const particlesLoaded = (container: any) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 100,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#ffffff',
        },
        links: {
          color: '#ffffff',
          distance: 100,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 700,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
      fullScreen: { enable: false },
    }),
    [],
  );

  return (
    <Suspense fallback={<Loading />}>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <LoginContainer ref={ref}>
          {(width ?? 0) > 650 && (
            <ParticlesContainer>
              <div
                className={'foo'}
                style={{ position: 'absolute', top: 0, bottom: 0, height: '100%' }}
              >
                <Particles
                  id='tsparticles'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  particlesLoaded={particlesLoaded}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options={options}
                />
              </div>
            </ParticlesContainer>
          )}
          {(() => {
            if (
              value === 'success' ||
              value === 'logginIn' ||
              value === 'authenticating'
            ) {
              return (
                <Form resize={(width ?? 0) < 650}>
                  <FormMessage color='tertiary'>{title}</FormMessage>
                  <NotificationLoading
                    showText={value === 'success'}
                    textTitle={'WELCOME'}
                  />
                </Form>
              );
            }
            if (value === 'selectingBranch' || value === 'branchFailure') {
              return (
                <Form
                  resize={(width ?? 0) < 650}
                  onSubmit={onBranchSubmit}
                  data-cy='branch-form'
                >
                  <FormMessage color='tertiary'>{title}</FormMessage>
                  <BranchSelection actor={context.branchActor} />
                  <Button style={{ width: '100%' }} type='submit'>
                    CONTINUE
                  </Button>
                  {context.error && <LoginError>{context.error}</LoginError>}
                </Form>
              );
            }
            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Form
                resize={(width ?? 0) < 650}
                onSubmit={handleSubmit(onSubmit)}
                data-cy='login-form'
              >
                {' '}
                <FormMessage color='tertiary'>{title}</FormMessage>
                <Input
                  data-cy='login-username'
                  $errorMessage='Username is required'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={formState.errors.user}
                  type='text'
                  icon={UserIcon}
                  label='Username'
                  placeholder='Your username...'
                  {...register('user', { required: true })}
                />
                <Input
                  data-cy='login-password'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={formState.errors.pass}
                  label={'Password'}
                  icon={KeyIcon}
                  type='password'
                  placeholder='Password...'
                  {...register('pass', { required: true })}
                  $errorMessage='Password is required'
                />
                {normalLoginEnabled && (
                  <Button style={{ width: '100%' }} type='submit'>
                    {loginLabelButton}
                  </Button>
                )}
                {ssoEnabled && (
                  <Button style={{ width: '100%' }} onClick={redirectToAnotherPage}>
                    {ssoLabelButton}
                  </Button>
                )}
                {context.error && (
                  <LoginError data-cy='login-error'>{context.error}</LoginError>
                )}
              </Form>
            );
          })()}
        </LoginContainer>
        <Footer screen='login' />
      </div>
    </Suspense>
  );
};

export default LoginPage;
