import {
  balanceFloat,
  formatCurrency,
  formatFloat,
  removeDemicals,
} from 'helpers/convertNumber';

export const createTableColumns = (keyData: any, accessor: any, header: any) => {
  return keyData.map((column: any) => ({
    type: column.type,
    Header: column[header] || column[accessor],
    accessor: column[accessor].replace(/\./g, '_'),
  }));
};

function getValidAccessor(prop: any) {
  // Data prop cant have property including dot notation
  // so we replace it with '-'
  // it must be the same with accessor of columns
  return prop.replace(/\./g, '_');
}

/* ------------------ Memory Table Functions ------------------- */
export const createListData = (valueData: any, keyData: any) => {
  return valueData.map((row: any) =>
    keyData.reduce((obj: any, cell: any, index: number) => {
      const accessor = getValidAccessor(cell.name);
      if (cell.type === 'DateTime') {
        return { ...obj, [accessor]: row[index]?.split(' ')[0] };
      }
      return { ...obj, [accessor]: row[index] };
    }, {}),
  );
};

/* ---------------------- Detail Table functions ------------------ */
function convertDetailValue({
  FIELDTYPE,
  FIELDPROPERTY,
  DISPLAYVALUE,
  NUMOFDECIMAL,
}: any) {
  if (FIELDTYPE === 'Bool') return DISPLAYVALUE === '1' ? 'Yes' : 'No';
  if (FIELDTYPE === 'Text') {
    if (FIELDPROPERTY === 'Integer') {
      const formatedNumber = formatCurrency(DISPLAYVALUE, NUMOFDECIMAL);
      return DISPLAYVALUE ? removeDemicals(formatedNumber) : '';
    }
    if (FIELDPROPERTY === 'Number') {
      const formatedNumber = formatCurrency(DISPLAYVALUE, NUMOFDECIMAL);
      return DISPLAYVALUE ? balanceFloat(formatedNumber) : '';
    }
  }
  return DISPLAYVALUE || '';
}
export const createDetailData = (valueData: any, property: any, eopyyInfo: any) => {
  // create this values only once as they dont depent on store-row
  const fields = {
    eopyy: valueData[0].item
      .filter((curItem: any) => curItem.FIELDTYPE === 'Eopyy')
      .map((curItem: any) => ({ ...curItem, eopyyInfo })),
    date: valueData[0].item.filter((cur: any) => cur.FIELDTYPE === 'Date'),
    readOnly: valueData[0].item.filter((cur: any) => cur.FIELDREADONLY),
    editable: valueData[0].item.filter((cur: any) => cur.EDITABLE),
    counter: valueData[0].item.filter(
      (cur: any) => cur.FIELDPROPERTY === 'Number' && cur.EDITABLE,
    ),
  };

  return valueData.map((store: any) => {
    return store.item.reduce(
      (acc: any, item: any) => {
        const accessor = getValidAccessor(item[property]);
        const detailValue = convertDetailValue(item);
        return { ...acc, [accessor]: detailValue };
      },
      { ID: store.id, fields },
    );
  });
};

/* ------------------- List and Search Table share the same functions ------------------------ */

function combineData(data: any, keyData: any) {
  return keyData.reduce((acc: any, column: any, index: number) => {
    const accessor = getValidAccessor(column.dataIndex);
    if (column.type === 'date') {
      const dateWithoutTime = data[index + 1].split(' ')[0];
      return { ...acc, [accessor]: dateWithoutTime };
    }
    if (column.type === 'float') {
      const formatedNumber = formatFloat(data[index + 1]);
      return { ...acc, [accessor]: formatedNumber };
    }
    return { ...acc, [accessor]: data[index + 1] };
  }, {});
}
export const createTableData = (valueData: any, keyData: any) => {
  return valueData.map((data: any) => {
    const id = data[0].split(';').pop();
    const newObj = combineData(data, keyData);
    return { ID: id, ...newObj };
  });
};
