import { useLocation, useNavigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DnaImage from 'assets/images/dna.jpg';
import Pdf from 'components/Pdf/Pdf';
import { Button } from 'components/shared/Button/Button';
import { normalizeGreek } from 'helpers/helpers';
import { WithAnimation } from 'helpers/routeTransitions/MountTransition';
import { useNavigation } from 'hooks/useNavigation';

import {
  FinalScreenContainer,
  ImageWrapper,
  CheckIcon,
  InfoWrapper,
  ButtonContainer,
} from './FinalScreen.style';

type Props = {
  pdfOptions?: any;
  homeButton?: boolean;
  menu?: any[];
  objectPath?: string;
  onClose?: () => void;
  setFinal?: any;
};

const SuccessScreen = ({
  pdfOptions = [],
  homeButton,
  menu = [],
  objectPath,
  onClose,
  setFinal,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const navigateTo = useNavigate();
  const { pathname } = useLocation();

  const section = pathname.split('/')[1];

  function handleClick(option: any) {
    if (option.TYPE === 'CREATE') {
      setFinal ? setFinal(true) : null;
      return navigateTo(`/${section}/new/new`);
    }

    if (option.TYPE === 'LIST') {
      return onClose ? onClose() : navigateTo(`/${section}/search/list`);
    }

    if (option === 'home') {
      return navigateTo('/');
    }
    if (option.TYPE === 'CREATE') {
      location.reload();
    }
    return navigateTo('');
  }

  const hasPdfOptions = Boolean(pdfOptions.fileNames.length);
  const validMenus = menu.filter((item) => item.TYPE === 'CREATE' || item.SHOWONSUBMIT);

  return (
    <FinalScreenContainer data-cy={'final-success-screen'}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      <ImageWrapper img={DnaImage} />
      <InfoWrapper>
        <div className='header'>
          <CheckIcon />
          <span>Success</span>
        </div>
        <ButtonContainer>
          {hasPdfOptions && (
            <div>
              <h3>PDF:</h3>
              <div className='buttons'>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*// @ts-ignore*/}
                {pdfOptions.fileNames.map((option, index) => (
                  <Pdf key={index} caption={option.caption} filename={option.fileName} />
                ))}
              </div>
            </div>
          )}
          <div>
            {(() => {
              if (!homeButton && !validMenus.length) return null;
              return <h3>Next Actions:</h3>;
            })()}
            <div className='buttons'>
              {homeButton && <Button onClick={() => handleClick('home')}>Home</Button>}
              {validMenus.map((item, index) => (
                <Button key={index} onClick={() => handleClick(item)}>
                  {normalizeGreek(item.TITLE)}
                </Button>
              ))}
            </div>
          </div>
        </ButtonContainer>
      </InfoWrapper>
    </FinalScreenContainer>
  );
};

export default WithAnimation(SuccessScreen);
