import { assign, sendParent, spawn } from 'xstate';

import { EOPYY_API } from 'config/API';
import { EopyyMachine } from 'machines/EopyyMachine/EopyyMachine';

export const addItemToStore = assign((context: any, event: any) => {
  const serializedEventItem = event.item.map((item: any) => {
    if (item.FIELDPROPERTY === 'Number' && item.FIELDVALUE === '') {
      return { ...item, FIELDVALUE: '0', DISPLAYVALUE: '0' };
    }
    return item;
  });

  return {
    ...context,
    activeId: event.id,
    store: [...context.store, { id: event.id, item: serializedEventItem }],
  };
});

export const updateStore = assign((context: any, event: any) => {
  const selectedStore = context.store.find((store: any) => store.id === event.id);
  return { config: selectedStore.item, activeId: event.id };
});
export const updateStoreItem = assign((context: any, event: any) => {
  return {
    store: context.store.map((store: any) => {
      if (store.id !== context.activeId) return store;
      return { ...store, item: event.item };
    }),
  };
});

export const resetState = assign((context: any) => {
  return { activeId: undefined, config: context.initialConfig };
});
export const removeFromStore = assign((context: any, event: any) => {
  return { store: context.store.filter((item: any) => item.id !== event.id) };
});

export const handleOneItem = assign((context: any, event: any) => {
  return {
    store: context.store.map((storeItem: any) => {
      if (storeItem.id === event.rowId) {
        const item = storeItem.item;

        const ex = item.find((i: any) => i.FIELDCAPTION === event.fieldId);

        let convertToFloat = parseFloat(ex.FIELDVALUE.replace(',', '.'));

        if (event.operation === 'subtract') {
          convertToFloat--;
        } else {
          convertToFloat++;
        }

        const rounded = Math.round(convertToFloat * 100) / 100;

        const result = rounded.toString().replace('.', ',');

        ex.FIELDVALUE = result;
        ex.DISPLAYVALUE = result;

        return {
          ...storeItem,
          item,
        };
      }

      return {
        ...storeItem,
      };
    }),
  };
});

export const updateConfig = assign({
  config: (context: any, event: any) =>
    context.config.map((item: any) => {
      const foundItem = event.config.find(
        (updatedItem: any) => updatedItem.FIELDID === item.FIELDID,
      );
      if (!foundItem) return item;
      return {
        ...foundItem,
        ACTIVE: item.ACTIVE,
        FIELDVALUE: item.FIELDVALUE,
        DISPLAYVALUE: item.DISPLAYVALUE,
      };
    }),
});
export const updateInitialConfig = assign({
  initialConfig: (context: any, event: any) =>
    context.initialConfig.map((item: any) => {
      const foundItem = event.config.find(
        (updatedItem: any) => updatedItem.FIELDID === item.FIELDID,
      );
      return foundItem ? foundItem : item;
    }),
});

export const updateStoreTableUpdate = assign((ctx: any, e: any) => {
  const storeConfig = ctx.store[0].item;
  const fieldId = storeConfig.find(
    (configItem: any) => configItem.FIELDCAPTION === e.fieldCaption,
  )?.FIELDID;
  const updatedStore = ctx.store.map((storeItem: any) => {
    if (storeItem.id !== e.storeId) {
      return storeItem;
    }
    const updatedStoreItemValues = storeItem.item.map((item: any) => {
      if (item.FIELDID === fieldId) {
        const { FIELDVALUE, DISPLAYVALUE } = e.value;
        return { ...item, FIELDVALUE, DISPLAYVALUE };
      }
      return item;
    });
    return { ...storeItem, item: updatedStoreItemValues };
  });
  return { ...ctx, store: updatedStore };
});

const findByIdAndUpdate = (id: any, data: any, item: any) => {
  if (item.FIELDID !== id) return item;
  return { ...item, ...data };
};
export const autoCompleteUpdate = assign((ctx: any, e: any) => {
  const newInitialConfig = ctx.initialConfig.map((item: any) => {
    return findByIdAndUpdate(e.id, e.data, item);
  });
  const newConfig = ctx.config.map((item: any) => {
    return findByIdAndUpdate(e.id, e.data, item);
  });
  const updatedStore = ctx.store.map((storeItem: any) => {
    const updatedStoreItem = storeItem.item.map((storeConfigItem: any) => {
      return findByIdAndUpdate(e.id, e.data, storeConfigItem);
    });
    return { ...storeItem, item: updatedStoreItem };
  });
  return {
    ...ctx,
    store: updatedStore,
    config: newConfig,
    initialConfig: newInitialConfig,
  };
});

/* --------------- EOPYY --------------- */
export const spawnEopyyMachine = assign({
  eopyyMachineRef: (context: any) => {
    const hasEopyy = context.config.find((config: any) => config.FIELDTYPE === 'Eopyy');
    if (!hasEopyy) return undefined;
    return spawn(
      EopyyMachine.withContext({
        ...EopyyMachine.context,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        EOPYY_API,
        fieldId: hasEopyy.FIELDID,
      }),
    );
  },
});

function updateVariants(array: any, variants: any) {
  return array.map((item: any) => {
    const [, field] = item.FIELDNAME.split('.');
    const itemKey = field.replace('CCCCL', '');
    const found = Object.keys(variants).find(
      (key) => item.FIELDCAPTION === key || itemKey === key.toUpperCase(),
    );
    if (!found) return item;
    const variantValue = variants[found];
    return { ...item, FIELDVALUE: variantValue, DISPLAYVALUE: variantValue };
  });
}
export const updateEopyyVariants = assign((ctx: any, e: any) => {
  if (e.storeId) {
    const newStore = ctx.store.map((storeItem: any) => {
      if (storeItem.id !== e.storeId) return storeItem;
      const newItem = updateVariants(storeItem.item, e.variants);
      return { ...storeItem, item: newItem };
    });
    return { ...ctx, store: newStore };
  }
  const newConfig = updateVariants(ctx.config, e.variants);
  return { ...ctx, config: newConfig };
});

export const saveEopyyCode = assign((ctx: any, event: any) => ({
  eopyyHistory: [
    ...ctx.eopyyHistory,
    { eopyyCode: event.eopyyCode, customerCode: event.customerCode },
  ],
}));
export const resetEopyy = (ctx: any) => {
  if (!ctx.eopyyMachineRef) return;
  ctx.eopyyMachineRef.send({ type: 'RESET' });
};
export const updateEopyyForEdit = (ctx: any, e: any) => {
  const selectedStore = ctx.store.find((store: any) => store.id === e.id);
  const eopyyItem = selectedStore.item.find((item: any) => item.FIELDTYPE === 'Eopyy');
  if (!eopyyItem?.FIELDVALUE || !ctx.eopyyMachineRef) return;
  ctx.eopyyMachineRef.send({
    type: 'DETAIL_EDIT_UPDATE',
    eopyyCode: eopyyItem.FIELDVALUE,
  });
};

/* ----------------     CALCULATE      --------------------- */
export const triggerCalculate = sendParent({ type: 'CALCULATE' });
export const abortCalculate = sendParent({ type: 'ABORT_CALCULATE' });

export const triggerCalculateDraft = sendParent(({ name, config }: any) => {
  return { type: 'CALCULATE_DRAFT', name, draft: config };
});

export const sendStoreToParent = sendParent((context: any) => {
  const { name, store, config } = context;
  return { type: 'detailsUpdate', store, name, draft: config };
});

export const updateStoreWithCalcData = assign((ctx, e: any) => {
  return { ...ctx, store: e.store };
});

export const updateDraftWithCalcData = assign((ctx, e: any) => {
  // enable if you want calculate to always update fields
  // const newDraft = e.draft.map((item) => ({ ...item, FIELDISTOUCHED: false }));
  return { ...ctx, config: e.draft };
});
