import { assign, actions, send, sendParent } from 'xstate';
const { pure } = actions;
export const isEmpty = (ctx: any) => {
  return ctx.eopyyCode.trim().length <= 0;
};
export const hasPatientCode = (ctx: any) => {
  return ctx.eopyyCode.trim().length <= 0;
};

export const cacheError = assign((ctx, event: any) => ({
  errorMessage: event.data.message,
}));

export const updateEopyyHistory = assign((ctx: any, e: any) => {
  return {
    ...ctx,
    eopyyHistory: [
      ...ctx.eopyyHistory,
      {
        patient: ctx.patient,
        eopyyCode: ctx.eopyyCode,
        Intagible: e.data.Intagible,
        Icd10: e.data.Icd10,
        Icd10Descr: e.data.Icd10Descr,
      },
    ],
  };
});

export const checkHistoryForCode = pure((ctx: any) => {
  if (!ctx.patient.code) return send({ type: 'NO_PATIENT' });
  const foundCode = ctx.eopyyHistory.find(
    (historyItem: any) =>
      ctx.eopyyCode === historyItem.eopyyCode &&
      ctx.patient.code === historyItem.patient.code,
  );
  if (foundCode)
    return send({
      type: 'VALID_CODE',
      Intagible: foundCode.Intagible,
      Icd10: foundCode.Icd10,
      Icd10Descr: foundCode.Icd10Descr,
    });
  return send({ type: 'UNKOWN_CODE' });
});

export const updateParent = sendParent((ctx: any) => ({
  type: 'UPDATE_VALUE',
  id: ctx.fieldId,
  FIELDVALUE: ctx.eopyyCode,
  DISPLAYVALUE: ctx.eopyyCode,
}));

export const resetContext = assign((ctx) => ({
  ...ctx,
  eopyyCode: '',
  patient: { phoneNumber1: null, phoneNumber2: null, code: null },
  errorMessage: undefined,
}));

export const updateParentVariants = pure((_, e: any) => {
  const Icd10 = e.data ? e.data.Icd10 : e.Icd10;
  const Icd10Descr = e.data ? e.data.Icd10Descr : e.Icd10Descr;
  const Intangible = e.data ? e.data.Intagible : e.Intagible;
  return sendParent({
    type: 'UPDATE_EOPYY_VARIANTS',
    variants: { Icd10, Icd10Descr, Intangible },
  });
});
