import { useQuery } from 'react-query';

import { getSelectorData, getSelectorStringData } from 'helpers/services/services.utils';

function useGetSelectorData(editor: any) {
  return useQuery(['getSelectorData', { editor }], getSelectorData, {
    refetchOnWindowFocus: false,
    retry: false,
  });
}

function useGetSelectorStringData(editor: any) {
  return useQuery(['getSelectorStringData', { editor }], getSelectorStringData, {
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export { useGetSelectorData, useGetSelectorStringData };
