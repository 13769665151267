// eslint-disable-next-line import/named
import styled, { css } from 'styled-components';

import { ReactComponent as Search } from 'assets/icons/search.svg';

export const inputIconStyle = css`
  height: 1.7rem;
  width: 1.7rem;
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: ${(props) => props.theme.gray};
  left: 0;
`;
export const SearchIcon = styled(Search)`
  ${inputIconStyle}
`;
export const inputStyle = css`
  width: 100%;
  font-family: 'Roboto';
  color: ${(props) => props.theme.text};
  letter-spacing: 0.3px;
  outline: none;
  margin-top: 0.5rem;
  padding: 1.4rem 1.5rem;
  font-size: ${(props) => props.theme.small};
  font-weight: 500;
  border-radius: 2px;
  border: 1px solid transparent;
  border-top: none;
  border-bottom: 1px solid #ddd;
  box-shadow:
    inset 0 1px 1px rgba(0, 0, 0, 0.39),
    0 -1px 1px #fff,
    0 1px 0 #fff;
`;
export const StyledInput = styled.input`
  ${inputStyle};
  padding-left: 3.8rem;
  background-color: ${(props) => props.theme.grayLight2};
  box-shadow: 0px 0px 2px 0px ${(props) => props.theme.gray2};
  :focus {
    background-color: #fff;
    box-shadow: 0px 0px 1px 1px ${(props) => props.theme.special};
  }
  :-webkit-autofill {
    box-shadow: 0 0 100px 0px #fff inset;
    -webkit-box-shadow: 0 0 100px 0px #fff inset;
  }
  transition:
    background-color,
    box-shadow 0.2s ease-in-out;
`;

export const FormContainer = styled.div`
  width: 100%;
  position: relative;
`;
export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 80%;
`;
export const TableLabel = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0.3rem 0rem 0 1rem;
    width: 80px;
    font-weight: normal;
    font-size: ${(props) => props.theme.medium};
    color: ${(props) => props.theme.gray};
  }
`;
export const Label = styled.div`
  color: ${(props) => props.theme.gray2};
  font-weight: 700;
  font-size: ${(props) => props.theme.small};
`;
export const Error = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  text-align: center;
  font-size: ${(props) => props.theme.large};
  color: ${(props) => props.theme.error};
  font-weight: 600;
`;
