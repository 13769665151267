import { assign } from 'xstate';

function removeDuplicates(options: any) {
  return options.reduce((acc: any, opt: any) => {
    const isDuplicate = acc.some((item: any) => item.label === opt.label);
    return isDuplicate ? acc : [...acc, opt];
  }, []);
}
function groupByDependency(options: any, prevKeys: any, selectedItems: any) {
  const filteredOptions = options.filter((opt: any) => {
    return prevKeys.every((key: any) => selectedItems[key]?.value === opt[key]);
  });
  return removeDuplicates(filteredOptions);
}
function isDisabled(options: any, prevSelectedItem: any) {
  if (options.length <= 1) return true;
  return !prevSelectedItem;
}
function hasOptionsChanged(options: any, selectedItem: any) {
  return options.every((opt: any) => opt.value !== selectedItem.value);
}
function getValue(options: any, selectedItem: any) {
  if (options.length === 1) {
    return options[0];
  }
  if (hasOptionsChanged(options, selectedItem)) {
    return '';
  }
  return selectedItem;
}

export const actions = {
  rerenderValues: assign((ctx: any) => {
    const { COMPANY, BRANCH, MODULE, REFID } = ctx.selectOptions;
    const selectedItems = {
      COMPANY: COMPANY.length === 1 ? COMPANY[0] : ctx.selectedItems.COMPANY,
      BRANCH: BRANCH.length === 1 ? BRANCH[0] : ctx.selectedItems.BRANCH,
      MODULE: MODULE.length === 1 ? MODULE[0] : ctx.selectedItems.MODULE,
      REFID: REFID.length === 1 ? REFID[0] : ctx.selectedItems.REFID,
    };
    return { ...ctx, selectedItems };
  }),
  initializeValue: assign((ctx: any) => {
    const { COMPANY, BRANCH, MODULE, REFID } = ctx.groupedObjects;
    const selectedItems = {
      COMPANY: removeDuplicates(COMPANY).length === 1 ? COMPANY[0] : '',
      BRANCH: removeDuplicates(BRANCH).length === 1 ? BRANCH[0] : '',
      MODULE: removeDuplicates(MODULE).length === 1 ? MODULE[0] : '',
      REFID: removeDuplicates(REFID).length === 1 ? REFID[0] : '',
    };
    return { ...ctx, selectedItems };
  }),
  assignSelection: assign((ctx: any, e: any) => {
    const selectedItems = { ...ctx.selectedItems, [e.label]: e.data };
    return { ...ctx, selectedItems };
  }),
  assignPrevKeys: assign((ctx) => {
    const prevKeys = {
      BRANCH: ['COMPANY'],
      MODULE: ['COMPANY', 'BRANCH'],
      REFID: ['COMPANY', 'BRANCH', 'MODULE'],
    };
    return { ...ctx, prevKeys };
  }),
  groupObjects: assign((ctx, e: any) => {
    const groupedObjects = e.objects.reduce(
      (acc: any, obj: any) => ({
        COMPANY: [...acc.COMPANY, { label: obj.COMPANYNAME, value: obj.COMPANY, ...obj }],
        BRANCH: [...acc.BRANCH, { label: obj.BRANCHNAME, value: obj.BRANCH, ...obj }],
        MODULE: [...acc.MODULE, { label: obj.MODULENAME, value: obj.MODULE, ...obj }],
        REFID: [...acc.REFID, { label: obj.REFIDNAME, value: obj.REFID, ...obj }],
      }),
      { COMPANY: [], BRANCH: [], MODULE: [], REFID: [] },
    );
    return { ...ctx, groupedObjects };
  }),
  createOptions: assign((ctx: any) => {
    const { prevKeys, selectedItems } = ctx;
    const { COMPANY, BRANCH, MODULE, REFID } = ctx.groupedObjects;

    const selectOptions = {
      COMPANY: removeDuplicates(COMPANY),
      BRANCH: groupByDependency(BRANCH, prevKeys.BRANCH, selectedItems),
      MODULE: groupByDependency(MODULE, prevKeys.MODULE, selectedItems),
      REFID: groupByDependency(REFID, prevKeys.REFID, selectedItems),
    };
    return { ...ctx, selectOptions };
  }),
  createStatus: assign((ctx: any) => {
    const { selectedItems } = ctx;
    const { COMPANY, BRANCH, MODULE, REFID } = ctx.selectOptions;
    return {
      ...ctx,
      disable: {
        COMPANY: COMPANY.length === 1,
        BRANCH: isDisabled(BRANCH, selectedItems.COMPANY),
        MODULE: isDisabled(MODULE, selectedItems.BRANCH),
        REFID: isDisabled(REFID, selectedItems.MODULE),
      },
    };
  }),
  createSelectValue: assign((ctx: any) => {
    const { selectedItems } = ctx;
    const { COMPANY, BRANCH, MODULE, REFID } = ctx.selectOptions;

    const selectValue = {
      COMPANY: COMPANY.length === 1 ? COMPANY[0] : selectedItems.COMPANY,
      BRANCH: getValue(BRANCH, selectedItems.BRANCH),
      MODULE: getValue(MODULE, selectedItems.MODULE),
      REFID: getValue(REFID, selectedItems.REFID),
    };
    return { ...ctx, selectValue };
  }),
  assignSelectedObjects: assign((ctx, e: any) => {
    const { selected } = e;
    const selectedObject = {
      COMPANY: { label: selected.COMPANYNAME, value: selected.COMPANY, ...selected },
      BRANCH: { label: selected.BRANCHNAME, value: selected.BRANCH, ...selected },
      MODULE: { label: selected.MODULENAME, value: selected.MODULE, ...selected },
      REFID: { label: selected.REFIDNAME, value: selected.REFID, ...selected },
    };
    return {
      ...ctx,
      selectValue: selectedObject,
      selectedItems: selectedObject,
    };
  }),
};
