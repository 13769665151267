import { createContext, useContext } from 'react';
export const StepsContext = createContext<any>({});
const StepsDispatchContext = createContext<any>({});
const StepsProvider = ({ children, appState, appSend }: any) => {
  return (
    <StepsContext.Provider value={appState}>
      <StepsDispatchContext.Provider value={appSend}>
        {children}
      </StepsDispatchContext.Provider>
    </StepsContext.Provider>
  );
};
const useStepsContext = () => {
  const context = useContext(StepsContext);
  if (context === undefined) {
    throw new Error('useStepsContext must be used within a StepsProvider');
  }
  return context;
};
export const useStepsDispatch = () => {
  const context = useContext(StepsDispatchContext);
  if (context === undefined) {
    throw new Error('useStepsDispatch must be used within a StepsProvider');
  }
  return context;
};

export { StepsProvider, useStepsContext };
