import { memo, useEffect } from 'react';

import { toast, Toaster, ToastBar } from 'react-hot-toast';
import { VscWarning, VscError, VscCheck } from 'react-icons/vsc';
import styled from 'styled-components';

import { device } from 'config/device';
import { publicWindow } from 'config/public';

import { StyledCloseIcon } from './Icons/ActionIcons';
import 'config/Animations.css';

const { popupDelay } = publicWindow;
const ToastStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    padding: 0 1.5rem;
    font-size: ${(props) => props.theme.small};
    text-align: center;
    word-break: break-word;
    color: ${(props) => props.theme.text};
  }
  @media ${device.mobile} {
    width: 90vw;
  }
`;

function getColor(type: any, subType: any) {
  if (type === 'success') {
    if (subType === 'warning') {
      return '#d1d100';
    }
    return '#5F8D4E';
  }
  return '#be6464';
}

const SuccessIcon = styled(VscCheck)`
  min-width: 2.75rem;
  min-height: 2.75rem;
  fill: #5f8d4e;
`;
const WarningIcon = styled(VscWarning)`
  min-width: 2.75rem;
  min-height: 2.75rem;
  fill: #d1d100;
`;
const ErrorIcon = styled(VscError)`
  min-width: 2.75rem;
  min-height: 2.75rem;
  fill: #be6464;
`;

function CustomToast() {
  //console.log('CustomToast is called!');
  useEffect(() => {
    sessionStorage.clear();
    //console.log('The session storage is cleared!');
  }, []);

  return (
    <Toaster
      gutter={15}
      toastOptions={{ duration: popupDelay, position: 'bottom-right' }}
    >
      {(t: any) => {
        const color = getColor(t.type, t.subType);
        return (
          <ToastBar
            toast={t}
            style={{
              overflow: 'hidden',
              minWidth: '37rem',
              padding: '1.5rem 2.5rem',
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: `2px solid ${color}`,
              animation: 'SlideRight 0.5s ease',
            }}
          >
            {({ message }) => {
              return (
                <ToastStyle>
                  {(() => {
                    if (t.type === 'error') return <ErrorIcon />;
                    if (t.type === 'success') {
                      if (t.subType === 'warning') {
                        return <WarningIcon />;
                      }
                      return <SuccessIcon />;
                    }
                    return null;
                  })()}
                  <span>{message}</span>
                  {t.type !== 'loading' && (
                    <StyledCloseIcon onClick={(t: any) => toast.dismiss(t.id)} />
                  )}
                </ToastStyle>
              );
            }}
          </ToastBar>
        );
      }}
    </Toaster>
  );
}

export default memo(CustomToast);
