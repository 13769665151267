import { useRef, useCallback } from 'react';

function useBouncer(fn: any, delay = 0) {
  const ref = useRef<any>({});
  ref.current.fn = fn;
  const debounced = useCallback(
    (args: any) => {
      if (ref.current.timeout) {
        clearTimeout(ref.current.timeout);
      }
      ref.current.timeout = setTimeout(() => {
        ref.current.fn(args);
      }, delay);
    },
    [delay],
  );
  return debounced;
}

export default useBouncer;
