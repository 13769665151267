import ErrorScreen from 'components/FinalScreen/ErrorScreen';

function ErrorPage(props: any) {
  return (
    <div style={{ width: '100rem', margin: '0 auto', maxWidth: '97%' }}>
      <ErrorScreen {...props} />
    </div>
  );
}

export default ErrorPage;
