import { SecondaryButton } from 'components/shared/Button/Button';
import { API } from 'config/API';

type Props = {
  filename: string;
  caption: string;
};

function Pdf({ filename, caption }: Props) {
  function handleClick() {
    window.open(`${API}?filename=${filename}`);
  }
  return <SecondaryButton onClick={handleClick}>{caption}</SecondaryButton>;
}

export default Pdf;
