import { Loading } from 'components/shared/Loading';
import { useStepsContext, useStepsDispatch } from 'context/StepsProvider';

import ErrorScreen from './ErrorScreen';
import SuccessScreen from './SuccessScreen';

type Props = {
  onClose?: () => void;
  setFinal?: any;
};

const FinalScreen = ({ onClose, setFinal }: Props) => {
  const send = useStepsDispatch();
  const { value, context } = useStepsContext();

  const homeButton = context.parentFieldId ? false : true;

  const { object, globalObjects } = context;
  const currentObj = globalObjects.find((item: any) => item.OBJECTID === object.OBJECTID);

  if (value === 'final')
    return (
      <SuccessScreen
        menu={currentObj?.MENUOPTIONS || []}
        message={context.message}
        pdfOptions={context.pdfOptions}
        homeButton={homeButton}
        objectPath={currentObj.OBJECTPATH}
        onClose={onClose}
        setFinal={setFinal}
      />
    );
  if (value === 'failure')
    return (
      <ErrorScreen
        title='Try Again'
        message={context.error}
        handler={() => send({ type: 'RETRY' })}
      />
    );
  else
    return (
      <div style={{ height: '50rem' }}>
        <Loading />
      </div>
    );
};

export default FinalScreen;
