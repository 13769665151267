import { useState, useEffect, useCallback } from 'react';

import { useQuery } from 'react-query';

import { AddIcon } from 'components/shared/Icons/ActionIcons';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { ClipLoader } from 'components/shared/Loading';
import { getConfigByID, getOjbectByID } from 'machines/FormMachine/FormMachine.utils';
import { fillConfigWithData } from 'machines/shared/utils/fillConfigWithData';
import { updateConfigWithExtraFields } from 'machines/shared/utils/utils';

import ChildSteps from './ChildSteps';
import { useStepsContext, useStepsDispatch } from '../../context/StepsProvider';

// const FASTENTRYPATHS = ['task', 'task_histology', 'task_sample'];

type Props = {
  objectId: number;
  fieldId: number;
};

function CreateButton({ objectId, fieldId }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const send = useStepsDispatch();
  const appState = useStepsContext();
  const { config, globalObjects, globalConfig, childObjectRef } = appState.context;

  const configById = getConfigByID(objectId, globalConfig);
  const objectById = getOjbectByID(objectId, globalObjects);

  const newConfig = updateConfigWithExtraFields(config, configById);
  const editConfig = newConfig.filter((item: any) => item.FIELDVALUE);

  useEffect(() => {
    if (!childObjectRef) setIsModalOpen(false);
  }, [childObjectRef]);

  const { status, data } = useQuery(
    ['create', fieldId, { editConfig }],
    () => fillConfigWithData(newConfig, editConfig),
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    send({ type: 'CALCULATE' });
    send({ type: 'KILL_CHILD' });
  }, [send]);

  function handleClick() {
    send({
      type: 'SPAWN_OBJECT',
      id: objectId,
      fieldId,
      config: data,
      object: objectById,
      globalObjects,
      globalConfig: config,
      closeModal: handleClose,
    });
    setIsModalOpen(true);
  }

  // const isFastEntry =
  //   FASTENTRYPATHS.find((path) => path === object.OBJECTPATH) || object.FASTENTRY === 1;

  return (
    <div style={{ marginLeft: '0.5rem' }}>
      {childObjectRef && (
        <ChildSteps
          isOpen={isModalOpen}
          onClose={handleClose}
          actorRef={childObjectRef}
        />
      )}
      {(() => {
        if (status === 'loading') {
          return <ClipLoader size={20} />;
        }
        if (status === 'error') {
          return (
            <ErrorInfo id={fieldId} message='Some values couldnt load from server' />
          );
        }
        return <AddIcon data-cy='add-icon' data-testid='addIcon' onClick={handleClick} />;
      })()}
    </div>
  );
}

export default CreateButton;
