import { useMemo } from 'react';

import { Button } from 'components/shared/Button/Button';
import { StyledFlex } from 'components/shared/shared.style.';

import { DateInput } from './DateInput';
import { TimeInput } from './TimeInput';

type Props = {
  item: any;
  handleChange: (date: Date) => void;
  config: any;
};

export function CheckInOut({ item, handleChange, config }: Props) {
  const isDisabled = useMemo(() => {
    if (item.FIELDTYPE === 'CheckOut') {
      const checkIn = config.find(
        (configItem: any) => configItem.FIELDTYPE === 'CheckIn',
      );
      if (!checkIn?.FIELDVALUE) return true;
    }
    return !!item.FIELDVALUE;
  }, [item, config]);
  return (
    <DateInput item={item} isDisabled={true} handleChange={() => {}}>
      {({ baseStatus }: any) => (
        <StyledFlex $minWidth='30rem' $gap='1rem' $alignItems='center'>
          <TimeInput
            position='right'
            isDisabled={true}
            handleChange={() => {}}
            {...{ item, baseStatus }}
          />
          <Button
            disabled={isDisabled}
            onClick={() => handleChange(new Date())}
            style={{ width: '13rem', marginTop: '0.5rem' }}
          >
            {item.FIELDTYPE}
          </Button>
        </StyledFlex>
      )}
    </DateInput>
  );
}
