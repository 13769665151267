import styled, { css } from 'styled-components';

import { ReactComponent as Key } from 'assets/icons/key.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { device } from 'config/device';

const svgStyle = css`
  height: 2rem;
  width: 2rem;
  fill: ${(props) => props.theme.gray2};
`;

const svgPosition = css`
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-40%);
`;
export const KeyIcon = styled(Key)`
  ${svgStyle}
  ${svgPosition}
`;
export const UserIcon = styled(User)`
  ${svgStyle}
  ${svgPosition}
`;
export const LoginContainer = styled.div`
  width: 100rem;
  height: 50rem;
  min-height: 50rem;
  max-width: 97%;
  margin: 6rem auto;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadow[3]};
  @media ${device.tablet} {
    width: 50rem;
  }
  @media ${device.mobile} {
    height: auto;
    margin: 2rem auto;
  }
`;

export const FormMessage = styled.p`
  font-weight: 700;
  font-size: ${(props) => props.theme.x_large};
  position: relative;
  margin-bottom: 3rem;
  :after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 0.5rem;
    border-radius: 20px;
    bottom: -1.5rem;
    right: 50%;
    transform: translateX(50%);
  }
  position: relative;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 0.5rem;
    background: rgb(15, 27, 43);
    border-radius: 20px;
    right: 50%;
    transform: translateX(50%);
    bottom: -1.5rem;
  }
`;

type FormProps = {
  resize?: boolean;
};

export const Form = styled.form<FormProps>`
  width: ${(props) => (props.resize ? '100%' : '50%')};
  height: 100%;
  padding: 4rem;
  position: relative;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  .option {
    gap: 1rem;
    display: flex;
    svg {
      fill: ${(props) => props.theme.special};
    }
  }
  h2 {
    width: 100%;
    text-align: center;
    color: ${(props) => props.theme.special};
  }
  @media ${device.mobile} {
    min-height: 50rem;
    padding: 2rem;
    gap: 4rem;
    justify-content: center;
  }
`;

export const ParticlesContainer = styled.div`
  width: 50%;
  height: 100%;
  color: #fff;
  position: relative;
  background: ${({ theme }) =>
    `linear-gradient(to top right, ${theme.special}FF, ${theme.special}FF, ${theme.special}CC, ${theme.special}B3)`};
`;

export const LoginError = styled.span`
  color: ${({ theme }) => theme.error};
  text-align: center;
`;
