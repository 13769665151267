import styled from 'styled-components';

type StyledTextProps = {
  noOfLines?: number;
  $isTruncated?: boolean;
};

export const StyledText = styled.p<StyledTextProps>`
  line-clamp: ${(props) => props.noOfLines || 'none'};
  overflow: ${(props) => (props.$isTruncated || props.noOfLines ? 'hidden' : 'auto')};
  white-space: ${(props) => (props.$isTruncated ? 'nowrap' : 'normal')};
  text-overflow: ${(props) => (props.$isTruncated ? 'ellipsis' : 'clip')};
`;

type StyledFlexProps = {
  justifyContent?: string;
  $gridGap?: number | string;
  $minWidth?: string;
  $gap?: string;
  $alignItems?: string;
  $marginTop?: string;
  $height?: string;
};

export const StyledFlex = styled.div<StyledFlexProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.$alignItems || 'center'};
  gap: ${(props) => props.$gridGap || '10px'};
  height: 100%;
  min-width: ${(props) => props.$minWidth || 'auto'};
  margin-top: ${(props) => props.$marginTop || '0'};
`;
