import { Component } from 'react';

import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.state.error) {
      return (
        <ErrorPage
          title='Go to Home'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          message={this.state.error}
          handler={() => {
            this.setState({ error: null, errorInfo: null });
            window.location.href = '/';
          }}
        />
      );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
