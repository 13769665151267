import Axios from 'axios';

import { PORTAL_API } from 'config/API';
import { getFilesBasePath } from 'helpers/files';
import { uploadFile, deleteFile } from 'helpers/services';
import {
  setData,
  calculate,
  getPdf,
  getData,
  setSignature,
} from 'helpers/services/services.utils';
import {
  addDraftAndFieldsData,
  getFieldsData,
  getDraftData,
} from 'machines/shared/utils/getFieldsData';
import { createLocateObject } from 'machines/shared/utils/locateInfo';
import { addDraftToConfig } from 'machines/shared/utils/utils';

import { submitAfterUpdateCreateData, submitFilesData } from './FormMachine.utils';

export const submitFilesService = async (context: any) => {
  const fileData = submitFilesData(context.files, context.filesToDelete, {
    basePath: context.filesBasePath,
    signaturePath: context.signaturePath,
  });
  const { data } = await Axios.post(PORTAL_API, {
    key: context.key,
    service: 'setFiles',
    OBJECT: context.object.OBJSOFTONENAME,
    FORM: '',
    data: fileData,
  });
  if (data.success) return data;
  throw new Error(data.error);
};

export const submitImgSignature = async (context: any) => {
  const { key, object } = context;
  const [, data64] = context.signature.data.split(',');
  return await setSignature({ key, object, data: data64 });
};

export const setDataService = async (context: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const data = getFieldsData(context.config, context.details);
  return await setData({ key: context.key, data, object: context.object });
};

export const uploadSignature = async (context: any) => {
  const { signature, signaturePath } = context;
  return await uploadFile(signature, signaturePath);
};

export const uploadAllFiles = async (context: any) => {
  const { tempFiles, filesBasePath } = context;
  return await Promise.allSettled(
    tempFiles.map((file: any) => uploadFile(file, filesBasePath)),
  );
};
export const deleteAllFiles = async (context: any) => {
  const resignatureStatus = context.signature.resignature;
  const { filesToDelete, signaturePath } = context;
  return await Promise.allSettled(
    filesToDelete.map((file: any) => deleteFile(file, signaturePath, resignatureStatus)),
  );
};

export const submitAfterUpdateService = async (context: any) => {
  const submitAfterUpdateFields = context.config
    .filter((configItem: any) => configItem.SUBMITAFTERUPDATE && configItem.FIELDVALUE)
    .map((item: any) => {
      const submitAfterUpdateMatch = context.globalConfig.find(
        (globalItem: any) => globalItem.FIELDID === Number(item.SUBMITAFTERUPDATEID),
      );
      const submitAfterUpdateMatchOjbect = context.globalObjects.find(
        (globalObject: any) => globalObject.OBJECTID === submitAfterUpdateMatch.OBJECTID,
      );
      return {
        configItem: { ...submitAfterUpdateMatch, FIELDVALUE: item.FIELDVALUE },
        object: submitAfterUpdateMatchOjbect,
      };
    });
  if (submitAfterUpdateFields.length === 0) return;
  for (let field = 0; field < submitAfterUpdateFields.length; field++) {
    const afterSubmitData = submitAfterUpdateCreateData(
      submitAfterUpdateFields[field],
      context.createdObjectId,
    );
    const result = await setData({
      object: submitAfterUpdateFields[field].object,
      data: afterSubmitData,
      key: submitAfterUpdateFields[field].configItem.FIELDVALUE,
    });
    if (!result.success) throw new Error(result.error);
  }
};

export const calculateService = (type: any) => async (context: any, event: any) => {
  const { key, config, details, object } = context;
  const currentDetail = details.find((detail: any) => detail.name === event.name);
  const locateObject = () => {
    if (type === 'draft') {
      const newConfig = addDraftToConfig(config, currentDetail.draft);
      return createLocateObject(newConfig, object);
    }
    return createLocateObject(config, object);
  };
  const getData = () => {
    if (type === 'draft') {
      const draftData = getDraftData(currentDetail);
      const fieldsData = getFieldsData(config, details, 'calc');
      return addDraftAndFieldsData(draftData, fieldsData);
    }
    return getFieldsData(config, details, 'calc');
  };
  const data = getData();

  const result = await calculate(object, data, key, locateObject());
  return { ...result, ...event, prevData: data };
};

export const getAllPdfs = async (ctx: any) => {
  const allPdfsPromises = ctx.object.TEMPLATES.map((template: any) =>
    getPdf({
      key: ctx.pdfOptions.key,
      object: ctx.object.OBJSOFTONENAME,
      template,
    }),
  );
  return await Promise.all(allPdfsPromises);
};

export const getDataUpdate = async (ctx: any, e: any) => {
  const configMatch = ctx.config.find(
    (configItem: any) => configItem.FIELDID === e.fieldId,
  );
  const response = await getData({
    form: e.childObject.OBJSOFTONEFORM,
    object: e.childObject.OBJSOFTONENAME,
    key: e.id,
    locateInfo: `${configMatch.FIELDEDITOR}:${configMatch.FIELDDISPLAY}`,
  });
  if (!response.success) throw new Error(response.error);
  return {
    data: response.data[configMatch.FIELDEDITOR][0],
    fieldValue: e.id,
    fieldId: e.fieldId,
  };
};

function hasDynamicParams(path: any) {
  return path.includes('[') && path.includes(']');
}
export async function filesBasePath(ctx: any) {
  const { object } = ctx;
  if (!hasDynamicParams(object.OBJECTFILEPATH)) {
    return object.OBJECTFILEPATH;
  }
  return await getFilesBasePath({
    path: object.OBJECTFILEPATH,
    form: object.OBJSOFTONEFORM,
    object: object.OBJSOFTONENAME,
    key: ctx.key,
  });
}

export async function signatureBasePath(ctx: any) {
  const { object } = ctx;
  if (!hasDynamicParams(object.OBJECTSIGNATUREPATH)) {
    return object.OBJECTSIGNATUREPATH;
  }
  return await getFilesBasePath({
    path: object.OBJECTSIGNATUREPATH,
    form: object.OBJSOFTONEFORM,
    object: object.OBJSOFTONENAME,
    key: ctx.key,
  });
}
