import { getFileGroups, getFileTableNamesFromGroupsArray } from 'helpers/helpers';

const FILE_FIELDS = ['NAME', 'SOFNAME', 'LNUM', 'LINENUM', 'CRTDATE', 'CODE'];

const createCategories = (config: any) => {
  return config.reduce((acc: any, item: any) => {
    if (!item.FIELDNAME) return acc;
    const [tableName, fieldName] = item.FIELDNAME.split('.');

    if (!(tableName in acc)) {
      return { ...acc, [tableName]: [fieldName] };
    }
    if (item.FIELDLEVEL === 'Detail') {
      if (!acc[tableName].includes('LINENUM')) {
        acc[tableName] = [...acc[tableName], 'LINENUM'];
      }
    }
    return { ...acc, [tableName]: [...acc[tableName], fieldName] };
  }, {});
};
const convertArrayToString = (categories: any) => {
  return Object.keys(categories).reduce((finalString: any, category) => {
    return `${finalString + category}:${categories[category].toString()};`;
  }, []);
};

const createSearchInfo = (searchFields: any) => {
  return searchFields.reduce((prev: any, cur: any) => {
    if (!cur.FIELDNAME) return prev;
    return [
      ...prev,
      {
        key: '',
        object: cur.FIELDEDITOR,
        locateInfo: cur.FIELDDISPLAY,
        fieldName: cur.FIELDNAME,
      },
    ];
  }, []);
};

function createFilesLocateInfo(object: any) {
  const fileGroups = getFileGroups(object.PAGES);
  const tablesNames = getFileTableNamesFromGroupsArray(fileGroups);
  const locateInfo = tablesNames.reduce((previousLocateInfo, currentTable) => {
    const currentTableLocateInfo = `${currentTable}:${FILE_FIELDS.join(',')};`;
    return previousLocateInfo + currentTableLocateInfo;
  }, '');
  return locateInfo;
}

// const createStringListInfo = (stringListFields) => {
//   return stringListFields.reduce((prev, cur) => {
//     return [...prev, { editor: cur.FIELDEDITOR, fieldName: cur.FIELDNAME }];
//   }, []);
// };

const findSearchFields = (configById: any) => {
  return configById.filter((item: any) => item.FIELDTYPE === 'Search');
};
// const findStringListFields = (configById) => {
//   return configById.filter((item) => item.FIELDTYPE === 'String');
// };

export function createLocateObject(config: any, object: any) {
  const categories = createCategories(config);
  const locateInfo = convertArrayToString(categories);

  const filesLocateInfo = createFilesLocateInfo(object);
  const specialFieldsInfo = createSpecialFieldsInfo(config);

  return { locateInfo: locateInfo + filesLocateInfo, ...specialFieldsInfo };
}

export function createSpecialFieldsInfo(config: any) {
  const searchFields = findSearchFields(config);
  const searchInfo = createSearchInfo(searchFields);

  // const stringListFields = findStringListFields(config);
  // const stringListInfo = createStringListInfo(stringListFields);

  return { searchInfo };
}
