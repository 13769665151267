import * as React from 'react';

import { ClipLoader as ClipSpinner } from 'react-spinners'; // it was ClipLoader as ClipSpinner

import { useUserConfiguration } from 'config/themes';

type ClipLoaderProps = {
  size: number;
  delay?: number;
  isLoading?: boolean;
};

export function ClipLoader({ size, delay = 0, isLoading = true }: ClipLoaderProps) {
  const { specialColor } = useUserConfiguration();
  const [hidden, setHidden] = React.useState(true);

  React.useEffect(() => {
    if (!isLoading) return;
    const timeout = setTimeout(() => setHidden(false), delay);
    return () => clearTimeout(timeout);
  }, [delay, isLoading]);

  if (hidden || !isLoading) return null;
  return <ClipSpinner size={size} color={specialColor} loading={isLoading} />;
}
