import { v4 as uuidv4 } from 'uuid';

import { isObjEmpty } from 'helpers/helpers';

function getSearchDisplayValue(displayField: any, searchValues: any) {
  if (displayField.split(',').length === 1) {
    const [name] = displayField.split(',');
    if (!(name in searchValues)) return '';
    return `${searchValues[name]}`;
  }
  const [code, name] = displayField.split(',');
  if (!(code in searchValues) || !(name in searchValues)) return '';

  return `${searchValues[code]} | ${searchValues[name]}`;
}
function getValuesAccordingToType(item: any, value: any) {
  if (
    item.FIELDTYPE === 'Date' ||
    item.FIELDTYPE === 'DateTime' ||
    item.FIELDTYPE === 'Time'
  ) {
    // safari dont support '-' format
    const displayValue =
      Boolean(value) === true ? new Date(value.replace(/-/g, '/')) : undefined;
    return { fieldValue: value, displayValue };
  }
  if (item.FIELDTYPE === 'List' || item.FIELDTYPE === 'Hidden') {
    if (typeof value === 'string') {
      const fieldValue = value.split('|').shift()?.trim();
      return { fieldValue, displayValue: value };
    }
  }
  if (item.FIELDTYPE === 'Text') {
    if (item.FIELDPROPERTY !== 'Text') {
      return {
        fieldValue: value.replace('.', ','),
        displayValue: value.replace('.', ','),
      };
    }
  }
  return { fieldValue: value, displayValue: value };
}

export function createNewItem({ item, index, eventData }: any) {
  const { editData, searchData } = eventData;

  const [objectName, tableName] = item.FIELDNAME.split('.');
  if (!(editData && editData[objectName])) return item;
  const editValues = editData[objectName][index];
  if (!(editValues && editValues[tableName])) return item;

  // ------ Serch fields ------
  if (item.FIELDTYPE === 'Search') {
    if (isObjEmpty(searchData)) return item;
    if (!(objectName in searchData)) return item;
    const objectData = searchData[objectName];
    if (!(tableName in objectData)) return item;
    const searchValues = objectData[tableName][index];
    if (!searchValues) return item;
    return {
      ...item,
      FIELDVALUE: editValues[tableName],
      DISPLAYVALUE: getSearchDisplayValue(item.FIELDDISPLAY, searchValues),
    };
  }
  // only search for detail level cause we handle stringList Fields on other levels
  // if (item.FIELDTYPE === 'String') {
  //   const { stringData } = specialFieldsData;
  //   if (isObjEmpty(stringData)) return item;
  //   if (!(item.FIELDEDITOR in stringData)) return item;
  //   const stringListValues = stringData[item.FIELDEDITOR];
  //   if (!stringListValues) return item;
  //   return {
  //     ...item,
  //     FIELDVALUE: editValues[tableName],
  //     DISPLAYVALUE: getListValue(stringListValues.data, editValues[tableName]),
  //   };
  // }

  // ----- else ------
  const { fieldValue, displayValue } = getValuesAccordingToType(
    item,
    editValues[tableName],
  );
  return { ...item, FIELDVALUE: fieldValue, DISPLAYVALUE: displayValue };
}

function updateLinesWithRest(newLines: any, rest: any) {
  return newLines.map((storeLine: any) => {
    for (let i = 0; i < rest.length; i++) {
      const { item, index } = rest[i];
      storeLine.item.splice(index, 0, item);
    }
    return { ...storeLine, item: storeLine.item };
  });
}
export function createNewLines(storeConfig: any, eventData: any) {
  const rest: any = [];
  const newLines: any = [];
  storeConfig.forEach((item: any, index: any) => {
    if (!item.FIELDNAME) {
      return rest.push({ item, index });
    }
    const [objectName] = item.FIELDNAME.split('.');
    const detailArray = eventData ? eventData.editData[objectName] : eventData;
    if (!detailArray) {
      return rest.push({ item, index });
    }
    if (newLines.length === 0) {
      for (let i = 0; i < detailArray.length; i++) {
        newLines[i] = { id: uuidv4(), item: [] };
      }
    }
    for (let i = 0; i < detailArray.length; i++) {
      const newItem = createNewItem({ item, index: i, eventData });
      newLines[i].item.push(newItem);
    }
  });
  return updateLinesWithRest(newLines, rest);
}
