import { ReactComponent as Link } from 'assets/icons/link.svg';
import { useNavigation } from 'hooks/useNavigation';

import { LinksWrapper } from './NavMenu.style';
import { NavListHeader } from '../NavBar.style';
import NavLinkLabel from '../NavLinkLabel';

type NavMenuLinkProps = {
  object: any;
  closeMenu: () => void;
};

function NavMenuLink({ object, closeMenu }: NavMenuLinkProps) {
  const { MENUOPTIONS, OBJECTNAME, ICON } = object;
  const navigate = useNavigation(object.OBJECTPATH);

  function handleClick(option: any) {
    if (!option.PATH) return;
    closeMenu();
    navigate(option);
  }

  if (MENUOPTIONS?.length === 0) return null;
  return (
    <li>
      <NavListHeader>
        {OBJECTNAME}
        {ICON}
      </NavListHeader>
      <LinksWrapper>
        {MENUOPTIONS.map((option: any, index: number) => {
          if (option.HIDDEN) return null;
          return (
            <div key={index}>
              <div
                data-cy={`${OBJECTNAME}-${option.TITLE}`}
                className='nav-link'
                onClick={() => handleClick(option)}
              >
                <NavLinkLabel {...{ option, object }} />
                <Link className='nav-icon' />
              </div>
              <hr />
            </div>
          );
        })}
      </LinksWrapper>
    </li>
  );
}

export default NavMenuLink;
