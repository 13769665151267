import { ScaleLoader as ScaleSpinner } from 'react-spinners';

import { useUserConfiguration } from 'config/themes';

type ScaleLoaderProps = {
  height: number;
  width: number;
  isLoading?: boolean;
};

export function ScaleLoader({ height, width, isLoading = true }: ScaleLoaderProps) {
  const { specialColor } = useUserConfiguration();
  return (
    <ScaleSpinner
      height={height}
      width={width}
      radius={2}
      loading={isLoading}
      color={specialColor}
    />
  );
}
