import ErrorPage from 'components/shared/Layouts/Error/ErrorPage';
import { Loading } from 'components/shared/Loading';
import { WithAnimation } from 'helpers/routeTransitions/MountTransition';
import { useLoadgadgets } from 'hooks/useLoadgadgets';

import { Container } from './Charts.style';
import CustomCharts from './CustomCharts';
import ListCharts from './ListCharts';

const AnimatedErrorPage = WithAnimation(ErrorPage);

type Props = {
  s1gadgets: { CODE: string }[];
};

function Charts({ s1gadgets }: Props) {
  const { data, error, status, refetch } = useLoadgadgets(s1gadgets) as any;

  if (status === 'loading') {
    return <Loading />;
  }
  if (status === 'error') {
    return (
      <AnimatedErrorPage
        title='Try Again'
        message={error.message}
        handler={() => refetch()}
      />
    );
  }

  return (
    <Container>
      <div className='chart-wrapper'>
        {data?.custom.map((item: any, index: number) => (
          <CustomCharts
            key={`custom-${index}`}
            cstId={item.key}
            axes={item.data.axes}
            description={item.description}
            structure={item.data.structure}
          />
        ))}
      </div>
      <div className='chart-wrapper'>
        {data?.list.map((item: any, index: number) => (
          <ListCharts
            key={`list-${index}`}
            data={item.data}
            description={item.description}
          />
        ))}
      </div>
    </Container>
  );
}

export default Charts;
