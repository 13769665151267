import { assign } from 'xstate';

export const displayDependentFields = assign({
  config: (context: any) => {
    const boolFIelds = context.config.filter((item: any) => item.FIELDTYPE === 'Bool');
    return context.config.map((item: any) => {
      const foundRefBool = boolFIelds.find(
        (boolItem: any) => boolItem.FIELDID === item.REFFIELDID,
      );
      if (!foundRefBool) return item;
      return {
        ...item,
        ACTIVE: foundRefBool.FIELDVALUE === item.REFVALUE,
      };
    });
  },
});

export const saveStarterFiles = assign({
  config: (context: any) => {
    sessionStorage.setItem('starterFiles', JSON.stringify(context.files));

    return context.config;
  },
});

export const updateItemValues = assign({
  config: (context: any, event: any) => {
    const { UPDATEDFIELDS = '', CLEAREDFIELDS = '', LOCKFIELDS = '' } = event;

    const fieldsToLock = LOCKFIELDS.split(',');
    const fieldsToClear = CLEAREDFIELDS.split(',');
    const extraFieldsToUpdate = UPDATEDFIELDS.split(',');

    const newContext = context.config.map((item: any) => {
      if (item.REFFIELDID === event.id) {
        return { ...item, ACTIVE: event.FIELDVALUE === item.REFVALUE };
      }
      if (item.FIELDID === event.id) {
        const res = {
          ...item,
          FIELDVALUE: event.FIELDVALUE,
          DISPLAYVALUE: event.DISPLAYVALUE,
          FIELDFORCECALC: event.FIELDFORCECALC,
          FIELDISTOUCHED: true,
        };

        return res;
      }

      let newItem = { ...item };
      // lock fields when boolean is active
      if (fieldsToLock.includes(String(item.FIELDID))) {
        const payload = {
          id: item.FIELDID,
          data: { FIELDREADONLY: Number(event.FIELDVALUE) },
        };
        if (item.FIELDLEVEL === 'Detail') {
          const found = context.details.find(
            (machine: any) => machine.name === item.PAGEGROUP,
          );
          found.machineRef.send({ type: 'AUTO_COMPLETE', ...payload });
        }
        newItem = { ...newItem, FIELDREADONLY: Number(event.FIELDVALUE) };
      }

      // update fields with item value
      if (extraFieldsToUpdate.includes(String(item.FIELDID))) {
        const payload = { id: item.FIELDID, data: event };
        if (item.FIELDLEVEL === 'Detail') {
          const found = context.details.find(
            (machine: any) => machine.name === item.PAGEGROUP,
          );
          found.machineRef.send({ type: 'AUTO_COMPLETE', ...payload });
        }
        newItem = {
          ...newItem,
          FIELDVALUE: event.FIELDVALUE,
          DISPLAYVALUE: event.DISPLAYVALUE,
        };
      }

      // clear fields from any value
      if (fieldsToClear.includes(String(item.FIELDID))) {
        const data = {
          FIELDVALUE: null,
          DISPLAYVALUE: null,
        };
        if (item.FIELDLEVEL === 'Detail') {
          const found = context.details?.find(
            (machine: any) => machine.name === item.PAGEGROUP,
          );
          found.machineRef.send({
            type: 'AUTO_COMPLETE',
            id: item.FIELDID,
            data,
          });
        }
        newItem = { ...newItem, ...data };
      }

      return newItem;
    });

    return newContext;
  },
});
