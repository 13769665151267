import { useState, useRef } from 'react';

import { ReactComponent as UpIcon } from 'assets/icons/arrow-thick-up.svg';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { ActiveWrapper, Dropdown, DropDownItemContainer, Label } from './DropDown.style';

type DropDownItemsProps = {
  data: string[] | number[];
  handleClick: (item: string | number) => void;
};

const DropDownItems = ({ data, handleClick }: DropDownItemsProps) => {
  return (
    <DropDownItemContainer>
      {data.map((item, index) => (
        <Label key={index} onClick={() => handleClick(item)}>
          {item}
        </Label>
      ))}
    </DropDownItemContainer>
  );
};

type DropDownProps = {
  data: string[] | number[];
  selectedItem: string | number;
  handleClick: (item: string | number) => void;
};

export default function DropDown({ data, selectedItem, handleClick }: DropDownProps) {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  useOnClickOutside(ref, close);

  function close() {
    setActive(false);
  }

  return (
    <Dropdown ref={ref} onClick={() => setActive(!active)}>
      <ActiveWrapper>
        {selectedItem}
        <UpIcon />
      </ActiveWrapper>
      {active && <DropDownItems handleClick={handleClick} data={data} />}
    </Dropdown>
  );
}
