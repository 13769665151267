import axios from 'axios';
import { assign } from 'xstate';

export const actions = {
  assignError: assign((ctx: any, e: any) => {
    return { ...ctx, error: e.data.message };
  }),
  assignObject: assign((ctx: any) => {
    return {
      authParams: {
        COMPANY: ctx.objs[0].COMPANY,
        BRANCH: ctx.objs[0].BRANCH,
        MODULE: ctx.objs[0].MODULE,
        REFID: ctx.objs[0].REFID,
      },
    };
  }),
  assignSelectedObject: assign(({ branchActor }: any) => {
    const { selectedItems } = branchActor.getSnapshot().context;
    return {
      authParams: {
        COMPANY: selectedItems.COMPANY.value,
        BRANCH: selectedItems.BRANCH.value,
        MODULE: selectedItems.MODULE.value,
        REFID: selectedItems.REFID.value,
      },
    };
  }),
  assignCreds: assign((ctx: any, e: any) => {
    return { ...ctx, username: e.username, password: e.password };
  }),
  assignClientID: assign({
    clientID: (_, e: any) => e.data.clientID,
  }),
};

export const services = {
  logInService: async (ctx: any, e: any) => {
    const { username, password } = e;
    try {
      if (username.trim() <= 0 || password.trim() <= 0) {
        throw Error('Username or Password empty');
      }
      const response = await axios.post(ctx.API, {
        service: 'login',
        username,
        password,
        appId: ctx.appId,
      });
      const { data } = response;
      if (!data.success) {
        throw Error(data.error);
      }
      return data;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw Error(error);
    }
  },
  authService: async (ctx: any) => {
    try {
      const response = await axios.post(ctx.API, {
        service: 'authenticate',
        clientID: ctx.loginClientID,
        ...ctx.authParams,
      });
      const { data } = response;
      if (!data.success) {
        throw Error(data.error);
      }
      return data;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw Error(error);
    }
  },
};
