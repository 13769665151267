import styled from 'styled-components';

export const ActiveWrapper = styled.div`
  position: relative;
  svg {
    width: 15px;
    height: 15px;
    position: absolute;
  }
  :hover {
    opacity: 0.7;
  }
`;

const Dropdown = styled.div`
  font-weight: 500;
  font-size: ${({ theme }) => theme.small};
  position: relative;
  display: flex;
  height: 80%;
  max-width: 8rem;
  cursor: pointer;
  margin-right: 15px;
  svg {
    position: absolute;
    top: -5px;
    fill: ${(props) => props.theme.secondary};
  }
`;
const DropDownItemContainer = styled.div`
  box-shadow: ${({ theme }) => theme.shadow[3]};
  width: 100%;
  position: absolute;
  left: -2rem;
  bottom: 0;
  border-radius: 3px;
  background: #f7f7f9;
  z-index: 2;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const Label = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  border-left: 0.5rem solid transparent;
  border-bottom: 1px solid ${(props) => props.theme.grayLight};
  :hover {
    border-left: 0.5rem solid ${(props) => props.theme.special};
  }
`;
export { Dropdown, DropDownItemContainer, Label };
