import { useActor } from '@xstate/react';
import styled from 'styled-components';

import { Select } from 'components/shared/ui/Select';

const Container = styled.div`
  width: 100%;
  gap: 2rem;
  display: flex;
  flex-direction: column;
`;
const SelectWrapper = styled.div`
  width: 100%;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function BranchSelection({ actor }: any) {
  const [state, send] = useActor(actor) as any;
  const { selectOptions, selectValue, disable, isSubmitted } = state.context;
  return (
    <Container>
      {[
        { label: 'Company', key: 'COMPANY' },
        { label: 'Branch', key: 'BRANCH' },
        { label: 'Entity', key: 'MODULE' },
        { label: 'Name', key: 'REFID' },
      ].map((opt) => (
        <SelectWrapper key={opt.key}>
          <h4 data-cy={`title-${opt.key}`}>{opt.label}:</h4>
          <Select
            isSubmitted={isSubmitted}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={selectValue[opt.key]}
            isDisabled={disable[opt.key]}
            options={selectOptions[opt.key]}
            onChange={(item: any) => {
              send({ type: 'SELECT', label: opt.key, data: item });
            }}
            placeholder={`Select ${opt.label}`}
          />
        </SelectWrapper>
      ))}
    </Container>
  );
}

export default BranchSelection;
