import { Fragment } from 'react';

import Page from 'components/Page/Page';
import { Container } from 'components/shared/Layouts/Layout';
import { EmptyContainer } from 'components/Table/Table.style';
import { StepsProvider } from 'context/StepsProvider';
import { groupBy } from 'helpers/helpers';

type Props = {
  context: any;
  object: any;
};

function Preview({ context, object }: Props) {
  const groupByPage = groupBy('PAGEDESC');
  const pageItems = groupByPage(context.config);

  const isPageEMpty = (page: any) => {
    const group = page.GROUPS[0];
    if (group.GROUPLEVEL === 'File') {
      return !context.files.length && !context.tempFiles.length;
    }
    if (group.GROUPLEVEL === 'Signature') {
      return !context.signature;
    }
    if (group.GROUPLEVEL === 'Detail') {
      const found = context.details.find(
        (detail: any) => detail.name === group.GROUPDESC,
      );
      return !found.store.length;
    }
    const pageName = page.PAGEDESC;
    if (!pageItems[pageName]) return true;
    return pageItems[pageName].every((item: any) => !item.FIELDVALUE);
  };

  const objectPages = object.PAGES.reduce((acc: any, page: any, index: number) => {
    const previewGroups = page.GROUPS.map((group: any) => ({
      ...group,
      GROUPREADONLY: 1,
    }));
    const previewPage = { ...page, GROUPS: previewGroups };

    if (isPageEMpty(page)) return acc;
    return [
      ...acc,
      <Page stepIndex={index + 1} key={page.PAGENUM} page={previewPage} preview={true} />,
    ];
  }, []);

  const appState = {
    context: {
      ...context,
      config: context.config.reduce((acc: any, item: any) => {
        if (!item.FIELDVALUE) return acc;
        return [...acc, { ...item, PREVIEWMODE: 1, CREATENEWENTRY: 0 }];
      }, []),
    },
  };

  if (!objectPages.length) return <EmptyContainer>No data to preview</EmptyContainer>;
  return (
    <StepsProvider appState={appState} appSend={() => {}}>
      <Container>
        {objectPages.map((comp: any, index: number) => (
          <Fragment key={index}>{comp}</Fragment>
        ))}
      </Container>
    </StepsProvider>
  );
}

export default Preview;
