import { memo, useState, useEffect, useRef } from 'react';

import set from 'date-fns/set';

import { Button } from 'components/shared/Button/Button';
import useBouncer from 'hooks/useBouncer';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { TimeContainer } from './DateInput.style';

export function addZero(i: number) {
  return i < 10 ? `0${i}` : i;
}

type Props = {
  handleChange: (date: Date) => void;
  date: Date | null;
  position?: string;
  onClose: () => void;
};

function TimePicker({ handleChange, date, position, onClose }: Props) {
  const divRef = useRef<any>(null);
  const hoursRef = useRef<any>([0]);
  const minutesRef = useRef<any>([0]);

  useOnClickOutside(divRef, onClose);

  const debouncedHoursChanged = useBouncer(onHoursChange, 100);
  const debouncedMinutesChanged = useBouncer(onMinutesChange, 750);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hoursArray = new Array(24).fill();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const minutesArray = new Array(60).fill();

  const [hours, setHours] = useState(setCurrentTime);
  const [minutes, setMinutes] = useState(setCurrentTime);

  useEffect(() => {
    if (date) {
      debouncedHoursChanged(new Date(date).getHours());
      debouncedMinutesChanged(new Date(date).getMinutes());
    }
  }, [date]);

  function onHoursChange(index: any) {
    setHours(index);
    scrollToCenter(hoursRef.current[index]);
  }
  function onMinutesChange(index: any) {
    setMinutes(index);
    scrollToCenter(minutesRef.current[index]);
  }
  function scrollToCenter(ref: any) {
    if (!ref) return;
    ref.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  function setCurrentTime() {
    debouncedHoursChanged(new Date().getHours());
    debouncedMinutesChanged(new Date().getMinutes());
  }
  function handleSubmit(e: any) {
    e.preventDefault();
    const selectedDate = date ? new Date(date) : new Date();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const payload = set(selectedDate, {
      hours,
      minutes,
      seconds: 0,
    });
    handleChange(payload);
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TimeContainer ref={divRef} style={{ [position]: 0 }}>
      <div className='time-wrapper'>
        <div className='carousel'>
          {hoursArray.map((_, index) => {
            if (hoursRef.current[index]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (index === hours) {
                hoursRef.current[index].className = 'selected';
              } else {
                hoursRef.current[index].className = 'none';
              }
            }
            return (
              <p
                key={index}
                ref={(el) => (hoursRef.current[index] = el)}
                onClick={() => onHoursChange(index)}
              >
                {addZero(index)}
              </p>
            );
          })}
        </div>
        <div className='carousel'>
          {minutesArray.map((_, index) => {
            if (minutesRef.current[index]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (index === minutes) {
                minutesRef.current[index].className = 'selected';
              } else {
                minutesRef.current[index].className = 'none';
              }
            }
            return (
              <p
                key={index}
                ref={(el) => (minutesRef.current[index] = el)}
                onClick={() => onMinutesChange(index)}
              >
                {addZero(index)}
              </p>
            );
          })}
        </div>
      </div>
      <div className='button-wrapper'>
        <h4 onClick={setCurrentTime}>Now</h4>
        <Button onClick={handleSubmit}>OK</Button>
      </div>
      <div className='spacer' />
    </TimeContainer>
  );
}

export default memo(TimePicker);
