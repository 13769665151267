import styled from 'styled-components';

import { device } from 'config/device';

export const THwrapper = styled.div`
  width: max-content;
  grid-gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  fill: ${(props) => props.theme.primary};
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.6;
  }
`;

export const GroupWrapper = styled.div`
  display: flex;
  transition: opacity 0.2s ease-in-out;
  :hover {
    opacity: 0.6;
  }
`;

type TablePageWrapperProps = {
  $isClickable?: boolean;
  $offset?: number;
  $height?: number;
};

export const TablePageWrapper = styled.div<TablePageWrapperProps>`
  height: 100%;
  font-size: ${(props) => props.theme.small};
  font-weight: 500;
  .container {
    width: 100%;
    max-height: ${(props) => {
      if (props.$height) return `${props.$height}rem`;
      return `calc(100% - ${props.$offset || 0}rem)`;
    }};
    position: relative;
    overflow: auto;
    box-shadow: ${({ theme }) => theme.shadow[3]};
    border-bottom: 3px solid ${(props) => props.theme.special};
    @media ${device.mobile} {
      max-height: ${(props) => {
        if (props.$height) return `${props.$height}rem`;
        return `calc(100% - ${(props.$offset || 0) + 2 || 0}rem)`;
      }};
    }
  }
  table {
    width: 100%;
    padding: 1rem;
    text-align: left;
    border-collapse: collapse;
    svg {
      height: 2rem;
      min-height: 2rem;
      width: 2rem;
      min-width: 2rem;
    }
  }
  [active-row='true'] {
    background-color: ${(props) => `${props.theme.special}1A`} !important;
  }
  [is-grouped='true'] {
    background-color: ${(props) => `${props.theme.special}1A`};
  }
  [is-placeholder='true'] {
    text-align: right;
    background-color: ${(props) => `${props.theme.error}1A`};
  }
  [is-float='true'] {
    text-align: right;
  }
  thead {
    position: sticky;
    top: 0;
    tr {
      color: ${(props) => props.theme.white};
      font-size: ${(props) => props.theme.medium};
      box-shadow: ${({ theme }) => theme.shadow[3]};
      background: ${(props) => props.theme.special};
    }
    th {
      padding: 1rem 1.5rem;
    }
  }
  tbody {
    cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'auto')};
    tr:nth-child(even) {
      background-color: #ededed;
    }
    tr:nth-child(odd) {
      background-color: #fff;
    }
    td {
      padding: 1.5rem 1.2rem;
    }
  }
`;

export const EmptyContainer = styled.div`
  padding: 5rem 0;
  text-align: center;
  font-size: ${(props) => props.theme.medium};
  color: ${(props) => props.theme.error};
`;

type MemoryTableWrapperProps = {
  $isClickable?: boolean;
  $offset?: number;
  $height?: number;
};

export const MemoryTableWrapper = styled.div<MemoryTableWrapperProps>`
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    max-height: ${(props) => {
      if (props.$height) return props.$height;
      return `calc(100% - ${props.$offset || 0}rem)`;
    }};
    position: relative;
    overflow: auto;
  }

  table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: ${(props) => props.theme.white};
    [active-row='true'] {
      td:nth-child(1) {
        color: ${(props) => props.theme.text};
        border-left: 1rem solid ${(props) => props.theme.special};
      }
    }
    thead {
      position: sticky;
      top: 0;
      tr {
        color: ${(props) => props.theme.white};
        box-shadow: ${({ theme }) => theme.shadow[3]};
        background: ${(props) => props.theme.special};
      }
      th {
        transition: opacity 0.2s ease-in-out;
        :hover {
          opacity: 0.5;
        }
      }
    }
    th,
    td {
      font-weight: 500;
      font-size: ${(props) => props.theme.small};
      padding: 1.5rem 0.7rem;
    }
    tbody {
      tr {
        cursor: ${({ $isClickable = true }) => ($isClickable ? 'pointer' : 'auto')};
        color: ${(props) => props.theme.gray};
        td {
          border-bottom: 1px solid ${(props) => props.theme.grayLight};
          :nth-child(1) {
            border-left: 1rem solid #fff;
          }
        }
        :hover {
          color: ${(props) => props.theme.text};
          td:nth-child(1) {
            border-left: 1rem solid ${(props) => props.theme.special};
          }
        }
      }
    }
  }
`;
