import { useState, useMemo, useEffect, createRef, useRef } from 'react';

import useBouncer from 'hooks/useBouncer';

function isInViewport(element: Element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function blockHorizontalScroll() {
  if (!document.activeElement) return false;
  const tagName = document.activeElement.tagName.toLowerCase();
  const isInput = ['input', 'textarea'].includes(tagName);
  const inputHasValue = Boolean(
    Object.prototype.hasOwnProperty.call(document.activeElement, 'value'),
  );
  if (isInput && inputHasValue) return true;
  return false;
}

const initialState = { mode: 'keyboard', activeRowIndex: 0 };

export function useA11yNavigation({ page, provideCell, enabled }: any) {
  const tableRef = useRef<any>({ current: { scrollLeft: 0 } });
  const [rowsRefs, setRowsRefs] = useState([]);
  const [state, setState] = useState(initialState);
  const [isScrolling, setIsScrolling] = useState(false);

  const setIsScrollingDebounced = useBouncer(setIsScrolling, 300);

  useEffect(() => {
    function handleKeyDown(e: any) {
      function goDown() {
        e.preventDefault();
        const isLastRow = state.activeRowIndex === page?.length - 1;
        if (isLastRow) {
          return setState(initialState);
        }
        setState((prev) => ({
          mode: 'keyboard',
          activeRowIndex: prev.activeRowIndex + 1,
        }));
      }
      function goUp() {
        e.preventDefault();
        const isFirstRow = state.activeRowIndex === 0;
        if (isFirstRow) {
          return setState({ mode: 'keyboard', activeRowIndex: page?.length - 1 });
        }
        setState((prev) => ({
          mode: 'keyboard',
          activeRowIndex: prev.activeRowIndex - 1,
        }));
      }
      function goLeft() {
        e.preventDefault();
        const left = tableRef.current?.scrollLeft - 50;
        tableRef.current?.scrollTo({ left, behavior: 'smooth' });
      }
      function goRight() {
        e.preventDefault();
        const left = tableRef.current?.scrollLeft + 50;
        tableRef.current?.scrollTo({ left, behavior: 'smooth' });
      }

      // TODO: need fix, dont use this behavior for charts
      if (!enabled) return;

      const isEnter = e.code === 'Enter' || e.code === 'NumpadEnter';
      if (isEnter) provideCell?.({ row: page ? page[state.activeRowIndex] : null });

      if (e.code === 'ArrowUp') goUp();
      if (e.code === 'ArrowDown') goDown();

      if (blockHorizontalScroll()) return;
      if (e.code === 'ArrowLeft') goLeft();
      if (e.code === 'ArrowRight') goRight();
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state, page, state.activeRowIndex, setState, provideCell]);

  useEffect(() => {
    const ref = rowsRefs[state.activeRowIndex];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!ref || !ref.current) return;
    if (state.mode === 'mouse') return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isInViewport(ref.current)) return;
    setIsScrolling(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref.current.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
      behavior: 'smooth',
    });
    setIsScrollingDebounced(false);
  }, [state.activeRowIndex, rowsRefs]);

  useEffect(() => {
    const rowRef = Array(page?.length).map(() => createRef());
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setRowsRefs(rowRef);
  }, [page.length]);

  return useMemo(() => {
    function changeHoverModeIndex(index: number) {
      if (isScrolling) return;
      setState({ mode: 'mouse', activeRowIndex: index });
    }
    return { ...state, tableRef, rowsRefs, changeHoverModeIndex };
  }, [state, isScrolling]);
}
