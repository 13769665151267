import styled, { css } from 'styled-components';

import { ReactComponent as AddListIcon } from 'assets/icons/add-solid.svg';
import { ReactComponent as SaveIconSvg } from 'assets/icons/save-disk.svg';
import { Button } from 'components/shared/Button/Button';
import { device } from 'config/device';

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddIcon = styled(AddListIcon)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  fill: ${(props) => props.color};
  margin-left: 1rem;
`;
export const SaveIcon = styled(SaveIconSvg)`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  fill: ${(props) => props.color};
  margin-left: 1rem;
`;

export const DetailButton = styled(Button)`
  margin-left: auto;
`;
export const CancelButton = styled(Button)`
  background: #fff;
  margin-right: 1rem;
  font-weight: 500;
  max-width: 15rem;
  border-bottom: 1px solid ${(props) => props.theme.tertiary};
  color: ${(props) => props.theme.tertiary};
  margin-top: 0;
  font-size: ${(props) => props.theme.x_small};
`;

export const DetailInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type InformationContainerProps = {
  readOnly?: boolean;
};

export const InformationContainer = styled.div<InformationContainerProps>`
  border-top: ${({ readOnly }) => `${readOnly ? 0 : 1}px solid rgba(0, 0, 0, 0.1)`};
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: ${({ readOnly }) => (readOnly ? '0rem' : `2rem 0rem`)};
  padding-top: ${({ readOnly }) => (readOnly ? '0rem' : '2rem')};
  @media ${device.mobile} {
    margin: 0rem;
  }
`;
export const StoreContainer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  flex: 3;
  width: 100%;
  margin-top: 2rem;
  margin-right: 1rem;
  background: #fff;
  overflow: auto;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

type OparationBtnProps = {
  operation: 'add' | 'subtract';
};

export const OparationBtn = styled.button<OparationBtnProps>`
  background-color: ${(props) => (props.operation === 'add' ? 'green' : 'red')};
  padding: 5px;
  width: 40px;
  color: white;
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  ${({ operation }) => {
    if (operation === 'subtract') {
      return css`
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      `;
    }

    return (
      operation === 'add' &&
      css`
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
      `
    );
  }})
`;
