import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useUserConfiguration } from 'config/themes';

type Props = {
  data: any;
  width: number | null;
  axes: { X: string; Y: string };
};

export function LineChart({ data, width, axes }: Props) {
  const { theme } = useUserConfiguration();
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <AreaChart data={data}>
        <defs>
          <linearGradient id='color' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor={theme.special} stopOpacity={0.8} />
            <stop offset='95%' stopColor={theme.special} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type='monotone'
          dataKey={axes.Y}
          stroke={theme.special}
          fill='url(#color)'
        />
        <XAxis
          dataKey={axes.X}
          tickLine={false}
          tickMargin={5}
          domain={['auto', 'auto']}
        />
        <YAxis
          dataKey={axes.Y}
          tickLine={false}
          tickCount={6}
          padding={{ bottom: 5, top: 5 }}
          domain={['dataMin', 'dataMax']}
          // tickFormatter={(number) => `€${number.toFixed(2)}`}
          width={width ? width : 0}
        />
        <Tooltip />
        <CartesianGrid opacity={0.1} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
}
