import { useState, forwardRef } from 'react';

import { Wrapper, CustomInput, HideIcon, ShowIcon } from './Input.style';

type Props = {
  errorMessage: string;
  type: string;
  label: string;
  placeholder: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  error?: boolean;
};

export default forwardRef(function Input(props: Props, ref) {
  const { errorMessage, type, label, placeholder, icon: Icon, error } = props;
  const [inputType, setInputType] = useState(type);

  function togglePassword() {
    setInputType((type) => (type === 'password' ? 'text' : 'password'));
  }

  function renderPasswordIcon() {
    if (inputType === 'password') return <ShowIcon onClick={togglePassword} />;
    else return <HideIcon onClick={togglePassword} />;
  }

  return (
    <Wrapper>
      <h4>{label}</h4>
      <div>
        {Icon && <Icon className='icon' />}
        <CustomInput
          {...props}
          $hasRightIcon={inputType === 'password'}
          $hasIcon={Boolean(Icon)}
          autoComplete='new-password'
          error={error}
          type={inputType}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          placeholder={error ? errorMessage : placeholder}
        />
        {type === 'password' && renderPasswordIcon()}
      </div>
    </Wrapper>
  );
});
