import { assign, createMachine } from 'xstate';
import { choose } from 'xstate/lib/actions';

import { isObjEmpty } from 'helpers/helpers';

import { actions } from './BranchMachine.config';

export const branchMachine = createMachine(
  {
    id: 'branchMachine',
    initial: 'idle',
    context: {
      selectedItems: {},
      selectOptions: {},
      selectValue: {},
      disable: {},
      isSubmitted: false,
    },
    entry: 'assignPrevKeys',
    states: {
      idle: {
        on: {
          SELECT: {
            actions: [
              'assignSelection',
              'createOptions',
              'rerenderValues',
              'createOptions',
              'createStatus',
              'createSelectValue',
            ],
          },
          INITIALIZE: {
            actions: choose([
              {
                cond: (_, e: any) => isObjEmpty(e.selected),
                actions: [
                  'groupObjects',
                  'initializeValue',
                  'createOptions',
                  'createStatus',
                  'createSelectValue',
                ],
              },
              {
                actions: [
                  'groupObjects',
                  'assignSelectedObjects',
                  'createOptions',
                  'createStatus',
                ],
              },
            ]),
          },
          SUBMIT_BRANCH: {
            actions: assign((ctx) => {
              return { ...ctx, isSubmitted: true };
            }),
          },
        },
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  { actions },
);
