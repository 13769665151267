import { parseValuesBetweenPairOfSymbols } from '../helpers';
import { getData } from '../services/services.utils';

export const parseValuesBetweenParentheses = parseValuesBetweenPairOfSymbols('[', ']');

/*
  param has shape OBJECT:FIELD
  state has shape {OBJECT:[FIELD]}
*/
export function groupFieldsByObjectFromParam(state: any, param: any) {
  const [object, field] = param.split(':');
  const alreadyExistingObjects = Object.keys(state);
  const objectExists = alreadyExistingObjects.find((obj) => obj === object);

  //if have already field for this object then push it in the array
  if (objectExists) {
    return { ...state, [object]: [...state[object], field] };
  }
  //else create the array for the object with the first field
  return { ...state, [object]: [field] };
}

export function createLocateInfoArrayFromObjectGroups(objects: any) {
  const locateInfoArray = (state: any, objectKey: any) => {
    const fields = objects[objectKey].join(',');
    const currentObjectLocateString = `${objectKey}:${fields}`;
    return [...state, currentObjectLocateString];
  };
  return locateInfoArray;
}

/*
given path shape is like /[OBJECT1:FIELD1]/[OBJECT1:FIELD2]/[OBJECT2:FIELD1]
we need to create locate info for get data request
so we need to transform it to
"OBJECT1:FIELD1,FIELD2;OBJECT3:FIELD1"
*/
export function getGetDataForFilesLocateInfoFromPath(path: any) {
  const objectGroups = parseValuesBetweenParentheses(path).reduce(
    groupFieldsByObjectFromParam,
    {},
  );
  const objectGroupsKeys = Object.keys(objectGroups);

  const locateInfoString = objectGroupsKeys
    .reduce(createLocateInfoArrayFromObjectGroups(objectGroups), [])
    .join(';');

  return locateInfoString;
}

export async function getDataForFilePaths({ path, object, form, key }: any) {
  const locateInfo = getGetDataForFilesLocateInfoFromPath(path);
  const result = await getData({ object, form, key, locateInfo });
  if (!result.success) throw new Error(result.error);
  return result.data;
}

export function createParamsHashtableFromGetDataResponse(data: any) {
  //get the different objects from response
  const objectsKeys = Object.keys(data);
  const hashTable = objectsKeys.reduce((prev, cur) => {
    const currentObject = data[cur];
    const fieldsObject = currentObject[0];
    if (!fieldsObject) return prev;
    // array of pairs of [FIELD,VALUE]
    const fieldsEntries = Object.entries(fieldsObject);
    const currentObjectHashEntries = fieldsEntries.reduce((prevEntry, curEntry) => {
      const [field, value] = curEntry;
      return {
        ...prevEntry,
        //"OBJECT:FIELD":VALUE
        [`${cur}:${field}`]: value,
      };
    }, {});
    return { ...prev, ...currentObjectHashEntries };
  }, {});
  return hashTable;
}

export function fillPathFromParamsHashtable({ path, hashTable }: any) {
  let newPath = path;
  const keys = Object.keys(hashTable);
  keys.forEach((key) => {
    newPath = newPath.replace(`[${key}]`, hashTable[key]);
  });
  return newPath;
}

export async function getFilesBasePath({ path, object, form, key }: any) {
  const data = await getDataForFilePaths({ form, key, object, path });
  const hashTable = createParamsHashtableFromGetDataResponse(data);
  const newPath = fillPathFromParamsHashtable({ path, hashTable });
  return newPath;
}
