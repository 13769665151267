import { useState } from 'react';

import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';

import { SecondaryButton } from 'components/shared/Button/Button';
import { Loading } from 'components/shared/Loading';
import Modal from 'components/shared/Modal/Modal';
import { StyledText } from 'components/shared/shared.style.';
import { useListContext } from 'context/ListProvider';
import { setApproval } from 'helpers/services/services.utils';
import { useGetSelectorStringData } from 'hooks/useGetListData';

import { checkIfapproval, getListTable } from './apprvoval.utils';
import { ShowCurrentApproval } from './TableApproval';

type SubmitApprovalProps = {
  columns: any;
  approvalData: any;
  approvalItem: any;
  onApprovalReset: () => void;
  onApprovalChange: (item: any) => void;
};

export function SubmitApproval({
  columns,
  approvalData,
  approvalItem,
  onApprovalReset,
  onApprovalChange,
}: SubmitApprovalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { config, form, refetchList } = useListContext();

  const { mutate, status, error, reset } = useMutation(setApproval, {
    onSuccess: (_, request) => {
      const { data, option } = request as any;
      setIsOpen(false);
      refetchList();
      onApprovalReset();
      toast.success(`Successfully ${option.label} of ${data.length} item(s)`);
    },
  });

  const found = config.find((item: any) => checkIfapproval(item.FIELDNAME, '.'));
  const stringSelector = useGetSelectorStringData(found?.FIELDEDITOR);

  function onDelete(item: any) {
    if (approvalData.length === 1) setIsOpen(false);
    onApprovalChange(item);
  }
  function onSubmit(option: any) {
    if (!found) {
      toast.error('Cant find CCCCCLEDITAPPRV FIELDNAME.');
      return;
    }
    const [object] = found.FIELDNAME.split('.');
    const objectHeader = getListTable(approvalData);

    const data = approvalData.map((item: any) => {
      return { id: item.ID, editapprv: option.value, object };
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mutate({ option, data, form, object: objectHeader });
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        isDisabled={status === 'loading'}
        handleClose={() => {
          reset();
          setIsOpen(false);
        }}
      >
        <div style={{ height: '100%', width: '100%' }}>
          {(() => {
            if (status === 'loading') return <Loading />;
            return (
              <ShowCurrentApproval
                data={approvalData}
                label={approvalItem.Header}
                options={stringSelector.data}
                isError={status === 'error'}
                errorData={error}
                {...{ columns, onDelete, onSubmit }}
              />
            );
          })()}
        </div>
      </Modal>
      <SecondaryButton
        data-cy='multi-edit'
        size='sm'
        disabled={approvalData.length === 0}
        onClick={() => setIsOpen(true)}
      >
        <StyledText $isTruncated>MULTI-EDIT</StyledText>
      </SecondaryButton>
    </>
  );
}
