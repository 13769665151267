import { useState, useEffect } from 'react';

import { useMachine } from '@xstate/react';
import { useNavigate } from 'react-router-dom';

function usePerstistedMachine(
  key: any,
  machine: any,
  options = { initialContext: undefined },
) {
  const navigate = useNavigate();

  const previousState = JSON.parse(localStorage.getItem(key) as string);
  const { initialContext } = options;

  // ensure that machine optios dont change between renders
  const [machineOptions] = useState(() => {
    if (previousState) {
      return { state: previousState };
    }
    return {
      context: initialContext,
      actions: {
        changePassword: () => navigate('change-password'),
      },
    };
  });

  const [state, send] = useMachine(machine, machineOptions);

  useEffect(() => {
    const jsonState = JSON.stringify(state);
    localStorage.setItem(key, jsonState);
  }, [state, key]);
  return [state, send];
}

export default usePerstistedMachine;
