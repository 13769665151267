import { assign, createMachine, spawn } from 'xstate';

import { branchMachine } from './actors/BranchMachine';
import { actions, services } from './LoginMachine.config';

export const LoginMachine = createMachine({
  id: `loginMachine`,
  initial: 'chooseInitialState',
  context: {
    API: undefined,
    username: '',
    password: '',
    error: '',
    clientID: '',
    loginClientID: '',
    authParams: undefined,
    appId: undefined,
    objs: [],
    selected: {},
  },
  entry: assign((ctx) => {
    return { ...ctx, branchActor: spawn(branchMachine) };
  }),
  states: {
    chooseInitialState: {
      always: [
        {
          cond: (ctx) => ctx.objs.length > 1,
          target: 'selectingBranch',
        },
        { target: 'idle' },
      ],
    },
    idle: {
      on: {
        LOGIN: {
          target: 'logginIn',
          actions: actions.assignCreds,
        },
      },
    },
    selectingBranch: {
      entry: ({ objs, selected, branchActor }: any) => {
        branchActor.send({ type: 'INITIALIZE', objects: objs, selected });
      },
      on: {
        SUBMIT_BRANCH: {
          target: 'authenticating',
          actions: actions.assignSelectedObject,
        },
      },
    },
    logginIn: {
      invoke: {
        id: 'loginService',
        src: services.logInService,
        onDone: {
          target: 'chooseAfterLoginState',
          actions: [
            assign((ctx, e) => {
              return { ...ctx, objs: e.data.objs };
            }),
            assign((ctx, e) => {
              return { ...ctx, loginClientID: e.data.clientID };
            }),
          ],
        },
        onError: {
          target: 'loginFailure',
          actions: actions.assignError,
        },
      },
    },
    chooseAfterLoginState: {
      always: [
        {
          cond: (ctx) => ctx.objs.length > 1,
          target: 'selectingBranch',
        },
        {
          target: 'authenticating',
          actions: actions.assignObject,
        },
      ],
    },
    authenticating: {
      invoke: {
        id: 'authService',
        src: services.authService,
        onDone: {
          target: 'success',
          actions: actions.assignClientID,
        },
        onError: [
          {
            cond: (ctx) => ctx.objs.length > 1,
            target: 'branchFailure',
            actions: actions.assignError,
          },
          {
            target: 'loginFailure',
            actions: actions.assignError,
          },
        ],
      },
    },
    loginFailure: {
      on: {
        LOGIN: {
          target: 'logginIn',
          actions: assign((ctx) => {
            return { ...ctx, error: '', clientID: undefined };
          }),
        },
      },
    },
    branchFailure: {
      on: {
        SUBMIT_BRANCH: {
          target: 'authenticating',
          actions: [
            actions.assignSelectedObject,
            assign((ctx) => {
              return { ...ctx, error: '' };
            }),
          ],
        },
      },
    },
    success: {
      after: {
        1000: {
          actions: 'goToHome',
        },
      },
    },
  },
});
