import { ColumnFilters } from './ColumnFilters';
import { ExcelExport } from './ExcelExport';
import { TableNavWrapper, ActionsWrapper } from './TableNav.style';
import { SubmitApproval } from '../Approval/SubmitApprvoval';

type Props = {
  enable: boolean;
  length: number;
  columns: any[];
  excelData: any[];
  excelColumns: any[];
  approvalState: {
    item: any;
    data: any;
    onReset: () => void;
    onChange: (cell: any) => void;
  };
};

export default function TableNav({
  enable,
  length,
  columns,
  excelData,
  excelColumns,
  approvalState,
}: Props) {
  if (!enable) return null;
  return (
    <TableNavWrapper>
      <span id='records-count'>Total records {length}</span>
      <ActionsWrapper>
        {(() => {
          if (!approvalState.item) return null;
          return (
            <SubmitApproval
              columns={columns}
              approvalItem={approvalState.item}
              approvalData={approvalState.data}
              onApprovalReset={approvalState.onReset}
              onApprovalChange={(cell) => approvalState.onChange(cell)}
            />
          );
        })()}
        <ExcelExport
          buttonStyles={{ marginRight: 10 }}
          columns={excelColumns}
          data={excelData}
        />
        <ColumnFilters columns={columns} length={length} />
      </ActionsWrapper>
    </TableNavWrapper>
  );
}
