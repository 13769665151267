import { SoftOneBoolean } from 'helpers/helpers';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const publicWindow = window['runConfig'];

export function isCopyIconVisible(
  objectName: any,
  copyIcon: any,
  excludeFromCopyIcon: any,
) {
  if (!SoftOneBoolean(copyIcon)) return false;
  if (!excludeFromCopyIcon || !excludeFromCopyIcon.length)
    return SoftOneBoolean(copyIcon);
  return excludeFromCopyIcon.every((OBJ: any) => OBJ !== objectName);
}
