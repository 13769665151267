import * as React from 'react';

import PropagateLoader from 'react-spinners/ScaleLoader';

import { ModalOverlay } from 'components/shared/Modal/Modal.style';
import { useUserConfiguration } from 'config/themes';

import { StyledFlex } from '../shared.style.';

export function Loading() {
  const { specialColor } = useUserConfiguration();
  return (
    <StyledFlex $height='100%' $alignItems='center' justifyContent='center'>
      <PropagateLoader
        height={60}
        margin={3}
        width={8}
        radius={2}
        color={specialColor}
        loading={true}
      />
    </StyledFlex>
  );
}

export function OverlayLoading({ isLoading = true, delay = 0 }) {
  const { specialColor } = useUserConfiguration();
  const [hidden, setHidden] = React.useState(true);

  React.useEffect(() => {
    if (!isLoading) return;
    const timeout = setTimeout(() => setHidden(false), delay);
    return () => clearTimeout(timeout);
  }, [delay, isLoading]);

  if (hidden || !isLoading) return null;
  return (
    <ModalOverlay>
      <PropagateLoader
        height={60}
        margin={3}
        width={8}
        radius={2}
        color={specialColor}
        loading={true}
      />
    </ModalOverlay>
  );
}
