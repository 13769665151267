import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { Item } from 'types/Item';

import { BooleanContainer, BooleanOption } from './Bools.style';

const selectionOptions = [
  { label: 'Yes', value: '1' },
  { label: 'No', value: '0' },
];

type Props = {
  item: Item;
  send: (opt: any) => void;
};

function Bool({ item, send }: Props) {
  function handleClick(opt: { label: string; value: string }) {
    if (opt.value === item.FIELDVALUE) {
      return send({
        type: 'UPDATE_BOOLEAN',
        id: item.FIELDID,
        FIELDVALUE: null,
        DISPLAYVALUE: null,
        FIELDFORCECALC: true,
        UPDATEDFIELDS: item.UPDATEDFIELDS,
        CLEAREDFIELDS: item.CLEAREDFIELDS,
      });
    }
    send({
      type: 'UPDATE_BOOLEAN',
      id: item.FIELDID,
      DISPLAYVALUE: opt.value,
      FIELDVALUE: opt.value,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
      LOCKFIELDS: item.LOCKFIELDS,
    });
  }

  return (
    <ConfigBaseItem item={item}>
      <BooleanContainer locked={item.FIELDREADONLY || item.PREVIEWMODE}>
        {selectionOptions.map((opt, index) => (
          <BooleanOption
            key={index}
            onClick={() => handleClick(opt)}
            active={opt.value === String(item.FIELDVALUE)}
          >
            {opt.label}
          </BooleanOption>
        ))}
      </BooleanContainer>
    </ConfigBaseItem>
  );
}
export default Bool;
