import { publicWindow } from 'config/public';

import { ArtieFile } from './ArtieFile';
import {
  ActionsWrapper,
  ExcelIcon,
  ImageIcon,
  Name,
  PdfIcon,
  PowerPointIcon,
  RemoveIcon,
  TextIcon,
  TitleWrapper,
  UnknowFile,
  WordIcon,
  Wrapper,
} from './File.style';
import { IISFile } from './IISFile';

const { enableArtieFileSystem } = publicWindow;

type Props = {
  name: string;
  remove: () => void;
  basePath: string;
  readOnly?: boolean;
};

const File = ({ name, remove, basePath, readOnly }: Props) => {
  const type = name.split('.').pop()?.toLowerCase();

  function isImage(extension: string | undefined) {
    if (!extension) return;
    switch (extension.toLowerCase()) {
      case 'jpg':
        return true;
      case 'svg':
        return true;
      case 'png':
        return true;
      case 'webp':
        return true;
      default:
        return false;
    }
  }
  function isPowerPoint(extension: string | undefined) {
    if (!extension) return;
    switch (extension.toLowerCase()) {
      case 'pptx':
        return true;
      case 'pptm':
        return true;
      case 'ppt':
        return true;
      default:
        return false;
    }
  }
  function renderIcon(type: string | undefined) {
    if (type === 'txt') {
      return <TextIcon />;
    }
    if (type === 'pdf') {
      return <PdfIcon />;
    }
    if (isPowerPoint(type)) {
      return <PowerPointIcon />;
    }
    if (type === 'xlsx') {
      return <ExcelIcon />;
    }
    if (type?.startsWith('doc')) {
      return <WordIcon />;
    }
    if (isImage(type)) {
      return <ImageIcon />;
    }
    return <UnknowFile />;
  }

  return (
    <Wrapper>
      <TitleWrapper>
        {renderIcon(type)}
        <Name noOfLines={3}>{name}</Name>
      </TitleWrapper>
      <ActionsWrapper readOnly={readOnly}>
        {!readOnly && <RemoveIcon onClick={remove} />}
        {(() => {
          if (enableArtieFileSystem) {
            return <ArtieFile {...{ name, basePath }} />;
          }
          return <IISFile {...{ name, basePath }} />;
        })()}
      </ActionsWrapper>
    </Wrapper>
  );
};

export default File;
