import { useMemo } from 'react';

import Particles from '@tsparticles/react';
import { useMachine } from '@xstate/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import useResizeObserver from 'use-resize-observer';

import { Button } from 'components/shared/Button/Button';
import Input from 'components/shared/Input/Input';
import { NotificationLoading } from 'components/shared/ui/AnimatedText';
import { useUserConfiguration } from 'config/themes';

import { passwordMachine } from './passwordMachine';
import {
  Form,
  FormMessage,
  LoginContainer,
  LoginError,
  ParticlesContainer,
} from '../LoginPage/Login.style';

export default function PasswordPage() {
  const navigate = useNavigate();
  const { title } = useUserConfiguration();
  const { ref, width } = useResizeObserver();

  const { register, handleSubmit, formState } = useForm({
    shouldUnregister: false,
  });
  const [state, send] = useMachine(passwordMachine, {
    actions: {
      goToHome: () => {
        navigate('/', { replace: true });
      },
    },
  });
  const { hasMatchError, responseError } = state.context as any;

  const particlesLoaded = (container: any) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onHover: {
            enable: true,
            mode: 'repulse',
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 100,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: '#ffffff',
        },
        links: {
          color: '#ffffff',
          distance: 100,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        move: {
          direction: 'none',
          enable: true,
          outModes: {
            default: 'bounce',
          },
          random: false,
          speed: 1,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 700,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: 'circle',
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
      fullScreen: { enable: false },
    }),
    [],
  );

  return (
    <LoginContainer ref={ref}>
      {width
        ? width
        : 0 > 650 && (
            <ParticlesContainer>
              <div
                className={'foo'}
                style={{ position: 'absolute', top: 0, bottom: 0, height: '100%' }}
              >
                <Particles
                  id='tsparticles'
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  particlesLoaded={particlesLoaded}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  options={options}
                />
              </div>
            </ParticlesContainer>
          )}
      <Form
        onSubmit={handleSubmit(async (data) => {
          send({ type: 'SUBMIT', ...data });
        })}
      >
        <FormMessage color='tertiary'>{title}</FormMessage>
        {(() => {
          if (state.hasTag('loading') || state.matches('success')) {
            return (
              <NotificationLoading
                showText={state.matches('success')}
                textTitle={'SUCCESS'}
              />
            );
          }
          return (
            <>
              <Input
                type='password'
                label='Current Password *'
                placeholder='Your current password...'
                $errorMessage='current password is required'
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                error={formState.errors.oldPassword}
                {...register('oldPassword', { required: true })}
              />
              <Input
                type='password'
                label='New Password *'
                placeholder='Your new password...'
                errorMessage='new password is required'
                error={formState.errors.newPassword || hasMatchError}
                {...register('newPassword', { required: true })}
              />
              <Input
                type='password'
                label='Confirm Password *'
                placeholder='Your new password...'
                errorMessage='confirm password is required'
                error={formState.errors.confirmedPassword || hasMatchError}
                {...register('confirmedPassword', { required: true })}
              />
              <Button style={{ width: '100%', marginTop: '2rem' }} type='submit'>
                SUBMIT
              </Button>
              {state.hasTag('failure') && <LoginError>{responseError}</LoginError>}
            </>
          );
        })()}
      </Form>
    </LoginContainer>
  );
}
