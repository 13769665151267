import styled from 'styled-components';

import { SearchIcon, StyledInput, FormContainer } from 'components/Search/Search.style';
import { ClipLoader } from 'components/shared/Loading';
import { device } from 'config/device';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .form-wrapper {
    margin-left: 0.1rem;
    display: flex;
    grid-gap: 1.5rem;
    align-items: center;
  }
  .mobile-label {
    display: none;
  }
  .desktop-label {
    display: block;
  }
  @media ${device.mobile} {
    grid-gap: 0.5rem;
    align-items: flex-start;
    flex-direction: column;
    .mobile-label {
      display: block;
    }
    .desktop-label {
      display: none;
    }
  }
`;

type Props = {
  label?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isUpdating: boolean;
};

function TableSearchHeader({ label = '', handleChange, value, isUpdating }: Props) {
  return (
    <Wrapper>
      {label ? <h2 className='mobile-label'>{label}</h2> : null}
      <div className='form-wrapper'>
        <FormContainer>
          <SearchIcon />
          <StyledInput
            data-cy='search-input'
            value={value}
            placeholder='Search...'
            onChange={handleChange}
            autoFocus
          />
        </FormContainer>
        <ClipLoader size={25} isLoading={isUpdating} />
      </div>
      {label ? <h2 className='desktop-label'>{label}</h2> : null}
    </Wrapper>
  );
}

export default TableSearchHeader;
