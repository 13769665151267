import styled from 'styled-components';

import { device } from 'config/device';

type DateWrapperProps = {
  left?: string;
};

export const DateWrapper = styled.div<DateWrapperProps>`
  width: 100%;
  position: relative;
  .nice-dates-popover {
    width: 400px;
    padding: 0 1rem;
    position: absolute;
    top: 90%;
    left: ${(props) => (props.left ? props.left : '26%')};
    border-radius: 5px;
    transform: scale(1);
    transition-duration: 0s;
    border-top: 3px solid ${({ theme }) => theme.tertiary};
    box-shadow: ${({ theme }) => theme.shadow[4]};
    @media ${device.mobile} {
      left: 0;
      width: 100%;
    }
  }
  .nice-dates-popover:not(.-open) {
    opacity: 1;
    transform: scale(0);
  }
  .nice-dates-day {
    font-size: 1.5rem;
    &:after {
      border: 2px solid ${({ theme }) => theme.special};
    }
  }
  .nice-dates-day.-today {
    font-size: 2rem;
    color: ${({ theme }) => theme.special};
  }
  .nice-dates-day.-selected {
    font-size: 1.5rem; // fallback to inital fontSize
    &:before {
      background: ${({ theme }) => theme.special};
    }
  }
`;

export const TimeContainer = styled.div`
  height: 30rem;
  width: 25rem;
  padding: 2rem 4rem;
  position: absolute;
  z-index: 1;
  top: 6.5rem;
  border-top: 3px solid ${({ theme }) => theme.tertiary};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadow[4]};
  background-color: ${(props) => props.theme.white};
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media ${device.mobile} {
    width: 100%;
    height: 300px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: unset;
  }
  p {
    margin: 0 1rem 0 1rem;
    font-family: 'Roboto';
    font-size: ${(props) => props.theme.large};
  }
  .button-wrapper {
    width: 100%;
    gap: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      cursor: pointer;
      color: ${(props) => props.theme.special};
      :hover {
        opacity: 0.8;
      }
    }
  }
  Button {
    height: 1rem;
    width: 1rem;
  }
  .time-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 1.5rem;
    overflow: hidden;
  }
  .carousel {
    padding: calc(50% - 1rem) 2rem calc(50% - 1rem) 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    p {
      cursor: pointer;
      padding: 0.7rem 0.3rem;
      text-align: center;
      border-radius: 5px;
      color: ${(props) => props.theme.text};
      font-size: ${(props) => props.theme.medium};
      background: transparent;
      :hover {
        color: white;
        background: ${(props) => `${props.theme.special}70`};
      }
    }
    .selected {
      color: white;
      background: ${(props) => props.theme.special};
    }
  }
  .spacer {
    height: 50px;
    margin: 0 0 -50px 0;
    background: transparent;
    @media ${device.mobile} {
      display: none;
    }
  }
`;
