import React from 'react';

import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import './index.css';
import App from './App';
import ErrorPage from './components/shared/Layouts/Error/ErrorPage';
import { Loading } from './components/shared/Loading';
import { WithAnimation } from './helpers/routeTransitions/MountTransition';
import BranchPage from './pages/BranchPage/BranchPage';
import { CalendarPage } from './pages/CalendarPage';
import HomePage from './pages/HomePage/HomePage';
import { ListTable } from './pages/ListPage';

// eslint-disable-next-line import/order
import { Suspense } from 'react';

import PasswordPage from './pages/PasswordPage/PasswordPage';
import StepsPage from './pages/StepsPage';

const AnimatedHomePage = WithAnimation(HomePage);
const AnimatedStepsPage = WithAnimation(StepsPage);
const AnimatedErrorPage = WithAnimation(ErrorPage);
const AnimatedBranchPage = WithAnimation(BranchPage);
const AnimatedPasswordPage = WithAnimation(PasswordPage);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />}>
      <Route path='/' element={<AnimatedHomePage />} />
      <Route path='/branch-selection' element={<AnimatedBranchPage />} />
      <Route path='/change-password' element={<AnimatedPasswordPage />} />
      <Route path='/:objectPath/new/:optionPath' element={<AnimatedStepsPage />} />
      {/* use optional param to access this route */}
      <Route path='/:objectPath/search/:optionPath/:id?' element={<ListTable />} />
      <Route
        path='/:objectPath/calendar/:optionPath/:id?'
        element={
          <Suspense fallback={<Loading />}>
            {(() => {
              const AnimatedCalendar = WithAnimation(CalendarPage);
              return <AnimatedCalendar />;
            })()}
          </Suspense>
        }
      />
      <Route
        path='*'
        element={
          <AnimatedErrorPage
            title='Go to Home'
            message='Error 404 - Page Not Found'
            handler={() => (window.location.href = '/')}
          />
        }
      />
    </Route>,
  ),
);

const root = document.getElementById('root')!;

createRoot(root).render(<RouterProvider router={router} />);
