import styled from 'styled-components';

export const TableNavWrapper = styled.div`
  position: relative;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  #records-count {
    opacity: 0.6;
    font-size: ${({ theme }) => theme.small};
  }
`;

export const ActionsWrapper = styled.div`
  grid-gap: 1.5rem;
  display: flex;
  align-items: center;
`;

type IconWrapperProps = {
  disabled: boolean;
};

export const IconWrapper = styled.div<IconWrapperProps>`
  width: 3rem;
  height: 3rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  svg {
    fill: ${({ theme }) => theme.secondary};
    width: 100%;
    height: 100%;
    :hover {
      opacity: 0.6;
    }
  }
`;
export const CheckMenuWrapper = styled.ul`
  position: absolute;
  list-style: none;
  z-index: 100;
  top: 5rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  width: max-content;
  border-radius: 5px;
  padding: 1.5rem 3rem;
  color: ${({ theme }) => theme.secondary};
  font-size: ${({ theme }) => theme.small};
  background: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.shadow[4]};

  span {
    opacity: 1;
    margin-bottom: 1rem;
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    :last-child {
      margin-bottom: 0rem;
    }
  }
`;
