import { publicWindow } from 'config/public';

import { deleteArtieFile, uploadArtieFile } from './services.artie';
import { deleteIISFile, uploadIISFile } from './services.iis';

const { enableArtieFileSystem } = publicWindow;

export const uploadFile = (...args: any) => {
  if (enableArtieFileSystem) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return uploadArtieFile(...args);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return uploadIISFile(...args);
};

export const deleteFile = (...args: any) => {
  if (enableArtieFileSystem) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return deleteArtieFile(...args);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return deleteIISFile(...args);
};
