import { useCallback, useMemo, useState } from 'react';

import {
  getValidRows,
  getIsAllChecked,
  checkIfapproval,
} from 'components/Table/Approval/apprvoval.utils';

export function useApprovals(columns: any) {
  const [approvals, setApprovals] = useState([]);

  const approvalReset = useCallback(() => setApprovals([]), [setApprovals]);
  const approvalChange = useCallback(
    (value: any) => {
      setApprovals((prev: any) => {
        const isChecked = prev.some((item: any) => item.ID === value.ID);
        if (isChecked) {
          return prev.filter((item: any) => item.ID !== value.ID);
        }
        return [...prev, value];
      });
    },
    [setApprovals],
  );

  const multiChange = useCallback(
    (tableRows: any) => {
      const validRows = getValidRows(tableRows);
      setApprovals((prev: any) => {
        const isChecked = getIsAllChecked(validRows, prev);
        if (isChecked) {
          return prev.filter((row: any) => {
            return !validRows.find((item: any) => item.ID === row.ID);
          });
        }
        const uniqueArr = validRows.filter((row: any) => {
          const found = prev.find((item: any) => item.ID === row.ID);
          return !found;
        });
        return [...prev, ...uniqueArr];
      });
    },
    [setApprovals],
  );

  const item = useMemo(() => {
    return columns.find((cell: any) => checkIfapproval(cell.id));
  }, [columns]);

  return {
    item,
    data: approvals,
    onReset: approvalReset,
    onChange: approvalChange,
    onMultiChange: multiChange,
  };
}
