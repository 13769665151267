import axios from 'axios';

import { isObjEmpty, customAxios } from 'helpers/helpers';

export const fetchPatient = async (ctx: any) => {
  const response = await customAxios({
    service: 'getData',
    OBJECT: 'CUSTOMER',
    FORM: '',
    KEY: ctx.patient.code,
    LOCATEINFO: 'CUSTOMER:PHONE01,PHONE02;CUSEXTRA:UTBL02',
  });
  const result = response.data;
  if (!result.success) {
    throw new Error(result.error);
  }
  const [data] = result.data.CUSTOMER;
  const [extra] = result.data.CUSEXTRA;
  if (isObjEmpty(data)) {
    throw new Error('No phone found');
  }
  return {
    coPayment: extra?.UTBL02,
    phoneNumber1: data?.PHONE01,
    phoneNumber2: data?.PHONE02,
    code: ctx.patient.code,
  };
};

export const fetchItem = async (ctx: any) => {
  const response = await customAxios({
    service: 'getData',
    OBJECT: 'ITEM',
    FORM: '',
    KEY: ctx.item.code,
    LOCATEINFO: 'ITEM:CCCEOPYYCONTRACTCODE;',
  });
  const result = response.data;
  if (!result.success) {
    throw new Error(result.error);
  }
  const [data] = result.data.ITEM;
  return { ContractCode: data?.CCCEOPYYCONTRACTCODE };
};

export const checkEopyyCode = async ({ EOPYY_API, eopyyCode, patient, item }: any) => {
  const result = await axios.post(EOPYY_API, {
    Prescription: eopyyCode,
    CoPayment: patient.coPayment,
    ContractCode: item.ContractCode,
    PhoneNumber1: patient.phoneNumber1,
    PhoneNumber2: patient.phoneNumber2,
  });
  const response = result.data;
  if (!response.Success || !response.IsValid) {
    throw new Error(response.ErrorMessage);
  }
  return response;
};
