import Axios from 'axios';

import { FILES_API } from 'config/API';

export const uploadIISFile = async (file: any, basePath: any) => {
  try {
    if (file.serverPath) return file; // return if is already uploaded

    const [, data64] = file.data.split(',');
    const path = basePath.slice(1); // remove the / from basepath

    const { data } = await Axios.post(`${FILES_API}/UploadFileBase64`, {
      request: {
        Data: data64,
        OverwriteIfExists: true,
        Filename: `${path}${file.name}`,
      },
    });
    if (!data.Success) {
      throw new Error(data.ErrorMessage);
    }
    return { ...file, serverPath: data.FilePath };
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.message && error.message.includes('413')) {
      throw new Error('File is too large. Size must be less than 4mb');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw new Error(error);
  }
};

export const deleteIISFile = async (file: any) => {
  const { data } = await Axios.post(`${FILES_API}/DeleteFile`, {
    request: { Filename: file.serverPath },
  });
  if (!data.Success) {
    throw new Error(data.ErrorMessage);
  }
  return file.name;
};
