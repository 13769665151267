import { createContext, useContext } from 'react';

const ListContext = createContext<any>({});

const ListProvider = ({ children, ...props }: any) => {
  return <ListContext.Provider value={props}>{children}</ListContext.Provider>;
};
const useListContext = () => {
  const context = useContext(ListContext);
  if (context === undefined) {
    throw new Error('useListContext must be used within a StepsProvider');
  }
  return context;
};

export { ListProvider, useListContext };
