import { useLayoutEffect } from 'react';

import Axios from 'axios';

function useInterceptor(user: any, send: any) {
  useLayoutEffect(() => {
    const myInterceptor = Axios.interceptors.request.use(
      function (config) {
        const { service } = config.data;
        if (user && service && service !== 'authenticate') {
          //if user exits pass to every request clientID and appId
          const data = config.data;
          config.data = { ...data, clientID: user.clientID, appId: user.appId };
        }
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      },
    );
    if (myInterceptor) {
      send({ type: 'APPROVAL' });
    }
    return () => {
      Axios.interceptors.request.eject(myInterceptor);
    };
  }, [user, send]);
}

export default useInterceptor;
