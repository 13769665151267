import { useState } from 'react';

import { useQuery } from 'react-query';

import { loadGadgets } from 'helpers/services/services.gadgets';

const INITIAL_STATE = { custom: [], list: [] };

export function useLoadgadgets(s1gadgets: { CODE: string }[]) {
  const [gadgets, setGadgets] = useState(INITIAL_STATE);
  const codes = s1gadgets.map((item: any) => item.CODE).toString();

  const query = useQuery(['gadgets', { codes }], loadGadgets, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const dataEntries = Object.entries(data.gadgets ?? {});
      const newData = dataEntries.reduce((acc: any, [key, value]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const dataKeys = Object.keys(value.data);
        if (dataKeys.some((dataKey) => dataKey === 'LIST')) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return { ...acc, list: [...acc['list'], { key, ...value }] };
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { ...acc, custom: [...acc['custom'], { key, ...value }] };
      }, INITIAL_STATE);
      setGadgets(newData);
    },
  });

  return { ...query, data: gadgets };
}
