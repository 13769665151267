import { useMemo, useState } from 'react';

import { SearchIcon, Error } from 'components/Search/Search.style';
import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { StyledCloseIcon } from 'components/shared/Icons/ActionIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import { Loading } from 'components/shared/Loading';
import Modal from 'components/shared/Modal/Modal';
import Table from 'components/Table/Table';
import { TablePageWrapper } from 'components/Table/Table.style';
import useSqlData from 'hooks/useSqlData';
import { getListDisplayValue } from 'machines/FormMachine/FormMachine.utils';

type SqlProps = {
  item: any;
  send: any;
};

export default function Sql({ item, send }: SqlProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const parseSqlRequestParams = useMemo(() => {
    const filters = item.FIELDLISTFILTERS.split('AND');
    const trimedFilters = filters.map((filter: any) => filter.trim());
    return trimedFilters.reduce(
      (acc: any, current: any) => [
        ...acc,
        { key: current.split(':')[0], value: current.split(':')[1] || '' },
      ],
      [],
    );
  }, [item.FIELDLISTFILTERS]);
  const { status, data, error } = useSqlData(
    item.FIELDEDITOR,
    parseSqlRequestParams,
  ) as any;

  const tableInfo = useMemo(() => {
    if (!data) return { columns: [], data: [] };
    const columns = data.reduce(
      (acc: any, cur: any) => [...acc, ...Object.keys(cur)],
      [],
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const uniqueColumns = [...new Set(columns)];
    const tableColumns = uniqueColumns.map((columnKey) => ({
      Header: columnKey,
      accessor: columnKey,
    }));
    return { columns: tableColumns, data };
  }, [data]);

  const clearSelection = (e: any) => {
    e.stopPropagation();
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      FIELDVALUE: null,
      DISPLAYVALUE: null,
      FIELDFORCECALC: true,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
  };
  function handleClick(row: any) {
    const DISPLAYVALUE = getListDisplayValue(item.FIELDDISPLAY, row);
    send({
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      FIELDVALUE: row.ID,
      DISPLAYVALUE,
      UPDATEDFIELDS: item.UPDATEDFIELDS,
      CLEAREDFIELDS: item.CLEAREDFIELDS,
    });
    setIsModalOpen(false);
  }

  return (
    <>
      <Modal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} fullScreen>
        {(() => {
          if (status === 'loading') return <Loading />;
          if (error) return <Error>{error.message}</Error>;
          return (
            <TablePageWrapper $offset={12}>
              <Table
                usage={{ pagination: true }}
                provideCell={handleClick}
                data={tableInfo.data}
                columns={tableInfo.columns}
              />
            </TablePageWrapper>
          );
        })()}
      </Modal>
      <ConfigBaseItem
        item={item}
        leftIcon={<SearchIcon />}
        rightIcon={(() => {
          if (!item.DISPLAYVALUE) return null;
          return <StyledCloseIcon onClick={clearSelection} />;
        })()}
      >
        {({ baseStatus }) => (
          <ConfigInput
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            modifiers={baseStatus.status}
            placeholder=''
            value={item.DISPLAYVALUE || ''}
            onClick={() => setIsModalOpen(true)}
            onChange={() => {}}
          />
        )}
      </ConfigBaseItem>
    </>
  );
}
