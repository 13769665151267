import { useState, memo } from 'react';

import axios from 'axios';
import { toast } from 'react-hot-toast';

import Groups from 'components/Groups/Groups';
import Preview from 'components/Preview/Preview';
import { Button, SecondaryButton } from 'components/shared/Button/Button';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { ClipLoader } from 'components/shared/Loading';
import Modal from 'components/shared/Modal/Modal';
import { PORTAL_API } from 'config/API';
import { useStepsDispatch, useStepsContext } from 'context/StepsProvider';

import { H2, PageContainer, PageNavigation } from './Page.style';

type Props = {
  page: any;
  currentStep?: () => void;
  previousStep?: () => void;
  totalSteps?: number;
  nextStep?: () => void;
  stepIndex: number;
  preview?: boolean;
};

function Page({
  page,
  currentStep,
  previousStep,
  totalSteps,
  nextStep,
  stepIndex,
  preview,
}: Props) {
  const state = useStepsContext();
  const send = useStepsDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function CalculateButton() {
    const { calcError } = state.context;
    return (
      <SecondaryButton
        data-cy='Calculate-Button'
        onClick={(event: any) => {
          if (
            !(
              event.target.tagName.toLowerCase() === 'svg' ||
              event.target.tagName.toLowerCase() === 'path'
            )
          )
            handleCalculate();
        }}
      >
        Calculate
        <div style={{ marginLeft: '1rem', position: 'relative' }}>
          <div
            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
          >
            {(state.matches('calculating') || state.context.isCalculating) && (
              <ClipLoader size={20} />
            )}
            {calcError && <ErrorInfo id={currentStep} message={calcError} />}
          </div>
        </div>
      </SecondaryButton>
    );
  }

  function renderNavigation() {
    return (
      <PageNavigation>
        <div className='flex'>
          {stepIndex > 1 && (
            <SecondaryButton onClick={previousStep}>Previous</SecondaryButton>
          )}
          <SecondaryButton onClick={handlePreview}>Preview</SecondaryButton>
          {stepIndex === 1 && <CalculateButton />}

          {Boolean(state.context.object.HASSAVEBUTTON) && (
            <Button data-cy='Button-Save' onClick={handleSave}>
              Save
            </Button>
          )}
        </div>
        <div className='flex'>
          {stepIndex !== 1 && <CalculateButton />}
          {stepIndex < (totalSteps ? totalSteps : 0) - 1 && (
            <Button data-cy='Button-Next' onClick={nextStep}>
              Next
            </Button>
          )}
          {stepIndex === (totalSteps ? totalSteps : 0) - 1 && (
            <Button
              data-cy='Button-Finish'
              disabled={state.matches('calculating')}
              onClick={handleFinish}
            >
              Finish
            </Button>
          )}
        </div>
      </PageNavigation>
    );
  }

  const formState = useStepsContext();
  const { signature } = formState.context;
  //console.log(state);
  const savedSignatureData = sessionStorage.getItem('signatureData');
  const storedSignFileName = sessionStorage.getItem('signFileName');
  const dataBase64 = signature?.data || savedSignatureData;
  const commaIndex = dataBase64?.indexOf(',');
  const data = dataBase64?.substring((commaIndex ? commaIndex : 0) + 1);
  //console.log('Saved Signature', data);

  async function handleSignature() {
    const key = state.context.key;
    const object = {
      OBJSOFTONENAME: state.context.object.OBJSOFTONENAME,
      XTRDOCTYPE: state.context.object.XTRDOCTYPE,
    };

    await new Promise((resolve) => setTimeout(resolve, 500));

    try {
      const response = await axios.post(PORTAL_API, {
        service: 'setSignature',
        KEY: key,
        OBJECT: object.OBJSOFTONENAME,
        XTRDOCTYPE: object.XTRDOCTYPE,
        data: data,
        signFileName: storedSignFileName,
      });
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function handleSave() {
    send({ type: 'SAVE' });
    if (state.context.object.REGSIGNATURE === 2) {
      await handleSignature();
    }
  }

  function handleFinish() {
    if (
      state.context.object.REGSIGNATURE === 2 &&
      sessionStorage.getItem('signFileName')
    ) {
      handleSignature();
    }
    handleSubmit();
    nextStep && nextStep();
    sessionStorage.removeItem('signFileName');
    sessionStorage.removeItem('loadedSignatureFileName');
    sessionStorage.removeItem('signatureData');
  }
  async function handleSubmit() {
    await send({ type: 'SUBMIT' });
  }
  function handleCalculate() {
    send({ type: 'CALCULATE' });
  }
  function handlePreview() {
    setIsModalOpen(true);
  }
  function handleClose() {
    setIsModalOpen(false);
  }

  return (
    <>
      <Modal isOpen={isModalOpen} fullScreen handleClose={handleClose}>
        <Preview context={state.context} object={state.context.object} />
      </Modal>
      <PageContainer>
        <H2>{page.PAGEDESC}</H2>
        <Groups groups={page.GROUPS} />
        {!preview && renderNavigation()}
      </PageContainer>
    </>
  );
}
export default memo(Page);
