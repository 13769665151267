import { createNewItem } from './getSoftoneData';
import { getSearchData } from './getSpecialFieldsData';
import { createSpecialFieldsInfo } from './locateInfo';

function getDataKeys(data: any) {
  return data.reduce((acc: any, curr: any) => {
    if (!curr.FIELDNAME) return acc;
    const [table, field] = curr.FIELDNAME.split('.');
    if (!(table in acc)) {
      return { ...acc, [table]: [{ [field]: curr.FIELDVALUE }] };
    }
    return { ...acc, [table]: [...acc[table], { [field]: curr.FIELDVALUE }] };
  }, {});
}

export async function fillConfigWithData(config: any, data: any) {
  try {
    if (config.length === 0 || data.length === 0) return config;

    const dataKeys = getDataKeys(data);
    const { searchInfo } = createSpecialFieldsInfo(config);

    const searchData = await getSearchData(dataKeys, searchInfo);
    const eventData = { editData: dataKeys, searchData };

    return config.map((item: any) => {
      if (!item.FIELDNAME) return item;
      const [objectName, tableName] = item.FIELDNAME.split('.');

      if (!(objectName in dataKeys)) return item;
      const index = dataKeys[objectName].findIndex(
        (dataItem: any) => dataItem[tableName],
      );
      if (index < 0) return item;

      return createNewItem({ item, index, eventData });
    });
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    console.error('Error: ', error.message);
  }
}
