import { memo } from 'react';

import Group from './Group';

type Props = {
  groups: any[];
};

function Groups({ groups }: Props) {
  return (
    <>
      {groups.map((group) => (
        <Group key={group.GROUPNUM} group={group} />
      ))}
    </>
  );
}
export default memo(Groups);
