import styled from 'styled-components';

import { device } from 'config/device';

type BubbleWrapperProps = {
  $lineWidth: number;
};

export const BubbleWrapper = styled.div<BubbleWrapperProps>`
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 3.5rem;
  margin-right: ${(props) => (props.$lineWidth < 0 ? '5px' : '0px')};
  &:after {
    position: absolute;
    content: '';
    width: ${(props) => `${props.$lineWidth}px`};
    top: 50%;
    transform: translate(45px, -5px);
    border-bottom: 2px solid var(--bg-color);
  }

  @media ${device.tablet} {
    margin-bottom: 0.5rem;
  }
`;

type BubbleIconProps = {
  active: boolean;
  current: boolean;
  title: string;
};

export const BubbleIcon = styled.div<BubbleIconProps>`
  position: relative;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    font-weight: bolder;
    fill: ${(props) => props.theme.white};
    color: ${(props) => props.theme.white};
    background-color: var(--bg-color);
    box-shadow: ${(props) => props.theme.shadow[2]};
    transform: ${(props) => (props.current ? 'scale(1.2)' : 'scale(1)')};
    transition: 0.2s transform ease-in-out;
  }

  &:after {
    content: '${(props) => props.title}';
    width: max-content;
    max-width: 10rem;
    position: absolute;
    text-align: center;
    overflow: hidden;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    top: 50px;
    right: 50%;
    border: none;
    transform: translateX(50%);
    color: var(--text-color);
    @media ${device.tablet} {
      display: none;
    }
  }
`;

type BubbleItemProps = {
  $active: boolean;
};

export const BubbleItem = styled.li<BubbleItemProps>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  list-style-type: none;
  --bg-color: ${(props) => (props.$active ? props.theme.special : props.theme.disable)};
  --text-color: ${(props) => (props.$active ? props.theme.tertiary : props.theme.gray)};
`;
