import axios from 'axios';
import isDate from 'date-fns/isDate';

import { API } from 'config/API';

export const identifyType = (object: any, key: any) => {
  /* return true if one item in the array satisfy the statement */
  return Object.keys(object).some((groupKey) => groupKey === key);
};

export const getIconLibrary = (name = '') => {
  const library = name.substring(0, 2);
  return library.toLowerCase();
};

export const customAxios = async (requestObject: any) => {
  return axios.post(API, { ...requestObject });
};

export const groupBy = (key: any) => (array: any) =>
  array.reduce(
    (objectsByKeyValue: any, obj: any) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
    }),
    {},
  );

function requiredItemStatus(item: any) {
  return item.FIELDVALUE && item.DISPLAYVALUE
    ? { status: 'success' }
    : { status: 'error' };
}
function defaulItemStatus(item: any) {
  return item.FIELDVALUE && item.DISPLAYVALUE
    ? { status: 'success' }
    : { status: 'default' };
}
export function getBaseStatusInformation(item: any) {
  if (item.FIELDREQUIRED) return requiredItemStatus(item);
  return defaulItemStatus(item);
}

export function isObjEmpty(obj: any) {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
  }
  return true;
}

export function getEopyyPatientCode(config: any) {
  const collaborationCenterMatch = config.find((item: any) => {
    return item?.FIELDNAME === 'SALDOC.CCCPATIENT';
  });
  if (collaborationCenterMatch?.FIELDVALUE) return collaborationCenterMatch.FIELDVALUE;
  const customerMatch = config.find((item: any) => {
    return item?.FIELDNAME === 'SALDOC.TRDR';
  });

  return customerMatch?.FIELDVALUE;
}
export function getEoppyItemCode(config: any) {
  const found = config.find((item: any) => item.FIELDNAME === 'ITELINES.MTRL');
  return found?.FIELDVALUE || '';
}
export function getEoppyItemsCode(store: any) {
  return store.map((line: any) => {
    const found = line.item.find((item: any) => item.FIELDNAME === 'ITELINES.MTRL');
    return { id: line.id, code: found?.FIELDVALUE };
  });
}

export function SoftOneBoolean(value: any) {
  if (value === '0') return false;
  if (value === 'false') return false;
  return Boolean(value);
}

export function getFileGroups(pages = []) {
  return pages.reduce((groups: any, currentPage) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const currentPageFileGroups = currentPage.GROUPS.filter(
      (group: any) => group.GROUPLEVEL === 'File',
    );
    return [...groups, ...currentPageFileGroups];
  }, []);
}
export function getFileTableNamesFromGroupsArray(groups = []) {
  const groupByFileObject = groupBy('GROUPFILEOBJ');
  return Object.keys(groupByFileObject(groups));
}
export function getFileTableNamesFromObject(object: any) {
  const groups = getFileGroups(object.PAGES);
  return getFileTableNamesFromGroupsArray(groups);
}
export function getAllFilesTypes(object: any, data: any) {
  if (!data || !object) return [];
  const tableNames = getFileTableNamesFromObject(object);
  return tableNames.reduce((previousConfig: any, currentTableName) => {
    const currentTableFiles = data[currentTableName];
    if (!currentTableFiles) return previousConfig;
    const transformedCurrentTableFiles = currentTableFiles.map(
      (currentTableFile: any) => ({
        name: currentTableFile.NAME,
        date: currentTableFile.CRTDATE,
        serverPath: currentTableFile.SOFNAME,
        basePath: currentTableFile.CODE,
        softoneSubmitKey: currentTableName,
      }),
    );
    return [...previousConfig, ...transformedCurrentTableFiles];
  }, []);
}

export function parseValuesBetweenPairOfSymbols(symbol1: any, symbol2: any) {
  return (string: any) => {
    const splits = string.split(symbol1);
    const partsAfterFirstParenthese = splits.splice(1);
    return partsAfterFirstParenthese.map((part: any) => {
      const [partBetweenParentheses] = part.split(symbol2);
      return partBetweenParentheses;
    });
  };
}

export function handleFieldType(item: any) {
  if (item.FIELDTYPE === 'Text') {
    if (item.FIELDPROPERTY === 'Integer') {
      return item.FIELDVALUE.replace(/\./g, '');
    }
    if (item.FIELDPROPERTY === 'Number') {
      return item.FIELDVALUE.replace(/\./g, '').replace(/,/g, '.');
    }
  }
  return item.FIELDVALUE;
}

export function normalizeGreek(text: any) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function formHasChanges(newContext: any, oldContext: any) {
  const { config, details, tempFiles, signature } = newContext;
  const signatureHasChanges = signature?.name !== oldContext.signature?.name;
  const detailHasChanges = details.some((dItem: any, dIndex: any) => {
    const oldStore = oldContext.details[dIndex].store;
    return dItem.store.some((sItem: any) => {
      const found = oldStore.find((old: any) => old.id === sItem.id);
      if (!found) return true;
      return sItem.item.some((item: any, itemIndex: any) => {
        const oldItem = found.item[itemIndex];
        return item.FIELDVALUE !== oldItem.FIELDVALUE;
      });
    });
  });
  const configHasChanges = config.some((item: any, index: any) => {
    return item.FIELDVALUE !== oldContext.config[index].FIELDVALUE;
  });
  return (
    tempFiles.length > 0 || configHasChanges || detailHasChanges || signatureHasChanges
  );
}

export function getValidDateValue(value: any) {
  if (!value) return null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isDate(value)) return value;
  return new Date(value);
}

export function convertToBytes(sizeStr = '') {
  const units = {
    kb: 1024,
    mb: 1024 ** 2,
    gb: 1024 ** 3,
    tb: 1024 ** 4,
  };
  const key = Object.keys(units).find((key) => sizeStr.includes(key));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [size] = sizeStr.split(key);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Number(size) * units[key];
}
