import styled from 'styled-components';

const BranchWrapper = styled.div`
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  span {
    color: ${(props) => props.theme.gray};
  }
`;

type BranchUserProps = {
  user: any;
  branch: any;
};

export function BranchUser({ user, branch }: BranchUserProps) {
  return (
    <BranchWrapper>
      <p>
        {user.username} ({branch.selected.REFIDNAME})
      </p>
      <span>{branch.selected.MODULENAME}</span>
    </BranchWrapper>
  );
}

type BranchCompanyProps = {
  branch: any;
};

export function BranchCompany({ branch }: BranchCompanyProps) {
  return (
    <BranchWrapper>
      <p>{branch.selected.COMPANYNAME}</p>
      <span>{branch.selected.BRANCHNAME}</span>
    </BranchWrapper>
  );
}
