import { useMemo, useState } from 'react';

import { formHasChanges } from 'helpers/helpers';

function useAskUser(context: any, dontAsk: any) {
  const [oldContext] = useState(context);

  return useMemo(() => {
    if (dontAsk) return false;
    return formHasChanges(context, oldContext);
  }, [context, dontAsk]);
}

export default useAskUser;
