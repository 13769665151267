import { PlacesType, Tooltip } from 'react-tooltip';

import { InfoIcon } from 'components/shared/Icons/InfoIcons';

type Props = {
  id: any;
  message: string;
  style?: any;
  place?: string;
};

function ErrorInfo({ id, message, style, place = 'top' }: Props) {
  return (
    <>
      <span data-tooltip-id={`error1`} data-for={`error${id}`} data-event='click'>
        <InfoIcon style={style} />
      </span>
      <Tooltip
        id={`error1`}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        type='error'
        place={place as PlacesType}
        effect='solid'
        clickable={true}
        events={['click']}
        variant={'error'}
      >
        <span>{message}</span>
      </Tooltip>
    </>
  );
}

export default ErrorInfo;
