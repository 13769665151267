import { useMemo, useState } from 'react';

import styled from 'styled-components';

import { Error } from 'components/Search/Search.style';
import { Button } from 'components/shared/Button/Button';
import { StyledFlex } from 'components/shared/shared.style.';
import { Select } from 'components/shared/ui/Select';
import { device } from 'config/device';

import { getApprovalTableData } from './apprvoval.utils';
import Table from '../Table';
import { MemoryTableWrapper } from '../Table.style';

type ContainerProps = {
  noOptions: boolean;
};

const Container = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  grid-gap: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => (props.noOptions ? 0 : '5rem')};
  @media ${device.mobile} {
    padding-top: 0;
  }
`;

type ShowCurrentApprovalProps = {
  data: Array<any>;
  options: any;
  columns: Array<any>;
  label: string;
  isError: boolean;
  errorData: any;
  onDelete: (id: string) => void;
  onSubmit: (item: any) => void;
};

export function ShowCurrentApproval({
  data,
  options,
  columns,
  label,
  isError,
  errorData,
  onDelete,
  onSubmit,
}: ShowCurrentApprovalProps) {
  const noOptions = !options || !options.data;
  const [selectedItem, setSelectedItem] = useState(undefined);

  const tableColumns = useMemo(() => {
    return columns.reduce(
      (acc, item) => {
        if (item.Header === label) return acc;
        if (item.Header === 'Actions') return acc;
        return [...acc, item];
      },
      [{ accessor: 'Actions', Header: 'Actions' }],
    );
  }, [columns, label]);

  const tableData = useMemo(() => {
    return getApprovalTableData({
      data,
      isError,
      onDelete,
      errorData,
    });
  }, [data, isError]);

  return (
    <Container noOptions={noOptions}>
      {(() => {
        if (noOptions) {
          return <Error>No Options found</Error>;
        }
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <StyledFlex
            as='form'
            $gridGap='1rem'
            marginLeft='auto'
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(selectedItem);
            }}
          >
            <Select
              data-cy='approval-select'
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              options={options.data.map((opt) => ({
                value: opt[0],
                label: opt[1],
              }))}
              placeholder='Select action...'
              onChange={(item: any) => setSelectedItem(item)}
            />
            <Button
              data-cy='approval-submit'
              type='submit'
              style={{ padding: '0.8rem 2.5rem' }}
              disabled={!selectedItem}
            >
              Submit
            </Button>
          </StyledFlex>
        );
      })()}
      <MemoryTableWrapper $isClickable={false}>
        <Table data={tableData} columns={tableColumns} usage={{}} />
      </MemoryTableWrapper>
    </Container>
  );
}
