import styled from 'styled-components';

import { device } from 'config/device';

const Layout = styled.div`
  height: calc(100vh - 10rem);
  padding: 1rem 1rem;
  margin-top: 10rem;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    height: calc(100svh - 6rem);
    margin-top: 6rem;
  }
`;

const Container = styled.div`
  max-width: 97%;
  width: 100rem;
  margin: 1rem auto;
`;

export { Layout, Container };
