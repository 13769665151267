import { useQuery } from 'react-query';

import { getGadgetData } from 'helpers/services/services.gadgets';

export function useGetgadgetdata(cstId: any) {
  return useQuery(['custom-gadgets', { cstId }], getGadgetData, {
    retry: false,
    refetchOnWindowFocus: false,
  });
}
