import styled from 'styled-components';

import { device } from 'config/device';
import { useUserConfiguration } from 'config/themes';
import { Version } from 'config/Version';

type FooterContainerProps = {
  $screen: string;
};

const FooterContainer = styled.footer<FooterContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.small};
  ${(props) => {
    if (props.$screen === 'login') {
      return {
        opacity: 0.5,
        bottom: '20px',
        right: '20px',
        justifyContent: 'center',
      };
    }
    return {
      alignItems: props.$screen === 'drawer' ? 'center' : 'flex-start',
      flexDirection: 'column',
    };
  }}
  @media ${device.tablet} {
    ${(props) => {
      if (props.$screen === 'login') {
        return {
          paddingLeft: '0.5rem',
          justifyContent: 'flex-start',
        };
      }
    }}
  }
`;
const BrandBox = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 10px;
  span {
    font-size: ${({ theme }) => theme.large};
  }
  .wrapper {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
`;

type VersionBoxProps = {
  $screen: string;
};

const VersionBox = styled.div<VersionBoxProps>`
  font-size: ${({ theme }) => theme.small};
  ${(props) => {
    if (props.$screen === 'login') {
      return {
        position: 'absolute',
        right: '1rem',
      };
    }
    return {
      color: props.theme.gray,
    };
  }}
`;

type FooterProps = {
  screen?: string;
};

function Footer({ screen = 'default' }: FooterProps) {
  const { title } = useUserConfiguration();
  return (
    <FooterContainer $screen={screen}>
      <BrandBox>
        {title}
        <div className='wrapper'>
          2024 <span>&copy;</span>
        </div>
      </BrandBox>
      <VersionBox $screen={screen}>Version: {Version}</VersionBox>
    </FooterContainer>
  );
}

export default Footer;
