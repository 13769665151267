import { assign } from 'xstate';

import { DATA_URL_PREFIX } from 'components/Signature/SignatureGroup';
import { SIGNATURE_PREFIX, SOFTONE_DATABASE } from 'config/constants';
import { groupBy } from 'helpers/helpers';

export function updateConfigWithExtraFields(oldConfig: any, newConfig: any) {
  const configWithUpdatedFields = oldConfig.filter((item: any) => item.UPDATEDFIELDS);

  const updatedConfig = newConfig.map((item: any) => {
    const updaterItem = configWithUpdatedFields.find((oldItem: any) => {
      const updateFields = oldItem.UPDATEDFIELDS.split(',');
      return updateFields.includes(String(item.FIELDID));
    });
    if (!updaterItem) return item;
    return {
      ...item,
      FIELDVALUE: updaterItem.FIELDVALUE,
      DISPLAYVALUE: updaterItem.DISPLAYVALUE,
    };
  });

  const configWithLockFields = updatedConfig.filter((item: any) => item.LOCKFIELDS);

  return updatedConfig.map((item: any) => {
    const lockerItem = configWithLockFields.find((oldItem: any) => {
      const lockFields = oldItem.LOCKFIELDS.split(',');
      return lockFields.includes(String(item.FIELDID));
    });
    if (!lockerItem) return item;
    return { ...item, FIELDREADONLY: Number(lockerItem.FIELDVALUE) };
  });
}

export function assignFactory(key: any) {
  return assign((ctx, e: any) => ({ ...ctx, [key]: e.data }));
}

export function addDraftToConfig(config: any, draft: any) {
  return config.map((configItem: any) => {
    if (configItem.FIELDLEVEL === 'Detail') {
      const foundItem = draft.find((draftItem: any) => {
        return draftItem.FIELDID === configItem.FIELDID;
      });
      return foundItem || configItem;
    }
    return configItem;
  });
}

export function getFilesData(allFilesTypes: any) {
  return allFilesTypes.filter((file: any) => !file.name.includes(SIGNATURE_PREFIX));
}
export function getSignatureData(allFilesTypes: any, event: any) {
  const { object, data } = event;
  if (object.REGSIGNATURE === SOFTONE_DATABASE) {
    if (!data.signature) return null;
    return {
      data: DATA_URL_PREFIX + data.signature,
      resignature: object.REGSIGNATURE,
    };
  }
  return allFilesTypes.find((file: any) => file.name.includes(SIGNATURE_PREFIX)) ?? null;
}

/* ------------ createDetails ----------------- */

export function createDetailGroups(config: any) {
  const groupByFieldlevel = groupBy('FIELDLEVEL');
  const groupByGroup = groupBy('PAGEGROUP');

  const details = groupByFieldlevel(config).Detail;
  if (!details) return [];
  return groupByGroup(details);
}
function getPageInfo(detailsGroups: any, object: any) {
  return Object.keys(detailsGroups).reduce((acc, key) => {
    const pageInfo = object.PAGES.find((pg: any) => {
      return pg.GROUPS.some((grp: any) => grp.GROUPDESC === key);
    });
    return { ...acc, [key]: pageInfo };
  }, {});
}
function getItemTable(config: any) {
  const foundItem = config.find((item: any) => item.FIELDNAME);
  const [tableName] = foundItem.FIELDNAME.split('.');
  return tableName;
}
export function createDetails(config: any, stores = {}, object: any) {
  const detailsGroups = createDetailGroups(config);
  const pageInfo = getPageInfo(detailsGroups, object);

  return Object.keys(detailsGroups).map((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = pageInfo[key];
    const group = page.GROUPS.find((grp: any) => grp.GROUPDESC === key);

    const config = detailsGroups[key];
    const table = getItemTable(config);

    return {
      name: key,
      table,
      config,
      page: page.PAGEDESC,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      store: stores[key] || [],
      ready: false,
      machineRef: undefined,
      CALCULATESKIP: Boolean(group.GROUPFORCECALC),
    };
  });
}
