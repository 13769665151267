import NavMenuLink from './NavMenuLink';
import { AccountIcon } from '../NavBar.style';

type AccountMenuProps = {
  branch: any;
  closeMenu: any;
};

function AccountMenu({ branch, closeMenu }: AccountMenuProps) {
  const MENUOPTIONS = [
    {
      HIDDEN: branch.objs.length <= 1,
      TITLE: 'Change Company',
      PATH: 'branch-selection',
    },
    {
      TITLE: 'Change Password',
      PATH: 'change-password',
    },
  ];
  const object = {
    OBJECTNAME: 'Account',
    MENUOPTIONS,
    ICON: <AccountIcon className='menu-icon' />,
  };
  return <NavMenuLink closeMenu={closeMenu} object={object} />;
}

export default AccountMenu;
