import { useState, useRef } from 'react';

import { ReactComponent as ColumnsIcon } from 'assets/icons/view-column.svg';
import { Switch } from 'components/Bool/Bools.style';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import { CheckMenuWrapper, IconWrapper } from './TableNav.style';

type Props = {
  columns: any[];
  length: number;
};

export function ColumnFilters({ columns, length }: Props) {
  const ref = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useOnClickOutside(ref, () => setMenuOpen(false));

  return (
    <IconWrapper ref={ref} disabled={length === 0}>
      <ColumnsIcon onClick={() => setMenuOpen((current) => !current)} />
      {(() => {
        if (!menuOpen) return null;
        return (
          <CheckMenuWrapper>
            <span>Select Columns:</span>
            {columns.map((column) => (
              <li key={column.id}>
                <Switch
                  height='2rem'
                  width='4rem'
                  type='checkbox'
                  {...column.getToggleHiddenProps()}
                />
                <label style={{ marginLeft: '1.5rem' }}>{column.Header}</label>
              </li>
            ))}
          </CheckMenuWrapper>
        );
      })()}
    </IconWrapper>
  );
}
