import { useEffect, useState } from 'react';

function useIsOverflow(ref: any, callback: any) {
  const [isOverflow, setIsOverflow] = useState<any>(undefined);

  useEffect(() => {
    const { current } = ref;
    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      if (hasOverflow === current) return;
      setIsOverflow(hasOverflow);
      if (callback) callback(hasOverflow);
    };
    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
}

export default useIsOverflow;
