import { useCallback } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

export const useNavigation = (objectPath: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (option: any) => {
      let type = '';
      let itemUrl;

      if (option.TYPE === 'LIST') {
        type = 'search';
      }
      if (option.TYPE === 'CREATE') {
        type = 'new';
      }
      if (option.TYPE === 'CALENDAR') {
        type = 'calendar';
      }

      if (option.PATH && objectPath) {
        itemUrl = `/${objectPath}/${type}/${option.PATH}`;
      } else if (option.PATH) {
        itemUrl = `/${option.PATH}`;
      } else {
        itemUrl = `/${objectPath}/${type}`;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigate(itemUrl, { title: option.TITLE });
    },
    [history, objectPath, location],
  );
};
