import { useState, useCallback, useEffect } from 'react';

import { useQuery } from 'react-query';

import { EditIcon } from 'components/shared/Icons/ActionIcons';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { ClipLoader } from 'components/shared/Loading';
import { useStepsContext, useStepsDispatch } from 'context/StepsProvider';
import { getEditDataWithKey } from 'helpers/services/services.utils';
import { getConfigByID, getOjbectByID } from 'machines/FormMachine/FormMachine.utils';
import { createLocateObject } from 'machines/shared/utils/locateInfo';

import ChildSteps from './ChildSteps';

type Props = {
  objectId: number;
  item: any;
};

function EditButton({ objectId, item }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const send = useStepsDispatch();
  const appState = useStepsContext();
  const { globalConfig, globalObjects, childObjectRef } = appState.context;

  const object = getOjbectByID(objectId, globalObjects);
  const configById = getConfigByID(objectId, globalConfig);
  const locateObject = createLocateObject(configById, object);

  useEffect(() => {
    if (!childObjectRef) setIsModalOpen(false);
  }, [childObjectRef]);

  const { status, data, error, refetch } = useQuery(
    [
      'edit',
      {
        locateObject,
        currentObject: object,
        fieldValue: item.FIELDVALUE,
      },
    ],
    getEditDataWithKey,
    { refetchOnMount: false, refetchOnWindowFocus: false },
  );

  const handleClose = useCallback(() => {
    setIsModalOpen(false);
    send({ type: 'CALCULATE' });
    send({ type: 'KILL_CHILD' });
  }, [send]);

  function handleClick() {
    send({
      type: 'SPAWN_EDIT_OBJECT',
      data,
      object,
      id: objectId,
      key: item.FIELDVALUE,
      callback: refetch,
      config: configById,
      fieldId: item.FIELDID,
      globalConfig,
      globalObjects,
    });
    setIsModalOpen(true);
  }

  return (
    <div style={{ marginLeft: '0.5rem' }}>
      {childObjectRef && (
        <ChildSteps
          isOpen={isModalOpen}
          onClose={handleClose}
          actorRef={childObjectRef}
        />
      )}
      {(() => {
        if (error) {
          return <ErrorInfo id={item.FIELDID} message={String(error)} />;
        }
        if (status === 'loading') {
          return <ClipLoader size={20} />;
        }
        return <EditIcon onClick={handleClick} />;
      })()}
    </div>
  );
}

export default EditButton;
