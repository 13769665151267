import { BubbleWrapper, BubbleItem, BubbleIcon } from './BubbleStep.style';

type BubbleStepsProps = {
  current: number;
  label: string;
  index: number;
  goToStep: (index: number) => void;
  IconName?: any;
  $lineWidth: number;
};

const BubbleSteps = ({
  current,
  label,
  index,
  goToStep,
  IconName,
  $lineWidth,
}: BubbleStepsProps) => {
  return (
    <BubbleItem onClick={() => goToStep(index)} $active={index <= current}>
      <BubbleWrapper data-cy={label} $lineWidth={$lineWidth}>
        <BubbleIcon active={index <= current} current={current === index} title={label}>
          <span>
            {(() => {
              if (IconName) return <IconName />;
              return index;
            })()}
          </span>
        </BubbleIcon>
      </BubbleWrapper>
    </BubbleItem>
  );
};

export default BubbleSteps;
