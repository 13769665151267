import { useQuery } from 'react-query';

import { getSqlData } from 'helpers/services/services.utils';

function useSqlData(SqlName: any, sqlFilters: any) {
  return useQuery(['SqlData', { SqlName, sqlFilters }], getSqlData, {
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export default useSqlData;
