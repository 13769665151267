import formatISO9075 from 'date-fns/formatISO9075';

import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';

import { CheckInOut } from './CheckInOut';
import { DateInput } from './DateInput';
import { DateTimeInput } from './DateTimeInput';
import { TimeInput } from './TimeInput';

export const DATE_TYPES = ['Time', 'DateTime', 'Date', 'CheckIn', 'CheckOut'];

type Props = {
  item: any;
  send: any;
  config: any;
};

export function DateContainer({ item, send, config }: Props) {
  const handleChange = (date: any) => {
    if (date === null) {
      send({
        type: 'UPDATE_VALUE',
        id: item.FIELDID,
        FIELDVALUE: null,
        DISPLAYVALUE: 'null',
        UPDATEDFIELDS: item.UPDATEDFIELDS,
        CLEAREDFIELDS: item.CLEAREDFIELDS,
        FIELDISTOUCHED: true,
      });
    } else {
      send({
        type: 'UPDATE_VALUE',
        id: item.FIELDID,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        FIELDVALUE: date ? formatISO9075(date) : undefined,
        DISPLAYVALUE: date || undefined,
        UPDATEDFIELDS: item.UPDATEDFIELDS,
        CLEAREDFIELDS: item.CLEAREDFIELDS,
        FIELDISTOUCHED: true,
      });
    }
  };

  if (item.FIELDTYPE === 'Time') {
    return (
      <ConfigBaseItem item={item}>
        {({ baseStatus }) => (
          <TimeInput item={item} baseStatus={baseStatus} handleChange={handleChange} />
        )}
      </ConfigBaseItem>
    );
  }
  if (item.FIELDTYPE === 'DateTime') {
    return (
      <DateInput item={item} handleChange={handleChange}>
        {({ baseStatus }: any) => (
          <div style={{ minWidth: '25rem' }}>
            <DateTimeInput
              position='right'
              item={item}
              handleChange={handleChange}
              baseStatus={baseStatus}
            />
          </div>
        )}
      </DateInput>
    );
  }
  if (item.FIELDTYPE === 'CheckIn' || item.FIELDTYPE === 'CheckOut') {
    return <CheckInOut item={item} handleChange={handleChange} config={config} />;
  }
  return <DateInput item={item} handleChange={handleChange} />;
}
