import { FC, PropsWithChildren, Suspense, useEffect, useState } from 'react';

import { initParticlesEngine } from '@tsparticles/react';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { loadFull } from 'tsparticles';

import NavBar from 'components/Navbar/NavBar';
import CustomToast from 'components/shared/CustomToast';
import ErrorBoundary from 'components/shared/Layouts/Error/ErrorBoundary';
import { Layout } from 'components/shared/Layouts/Layout';
import { Loading } from 'components/shared/Loading';
import GlobalStyle from 'config/GlobalStyle';
import { useUserConfiguration } from 'config/themes';
import { AuthProvider } from 'context/AuthProvider';
import { ModalProvider } from 'context/ModalProvider';

import ConfirmationModalContextProvider from './components/shared/Modal/Provider';

const queryClient = new QueryClient();

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const { theme, title } = useUserConfiguration();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

function App() {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) {
      return;
    }

    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, [init]);

  return (
    <>
      <AuthProvider>
        <Wrapper>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <NavBar />
              <ConfirmationModalContextProvider>
                <ModalProvider>
                  <CustomToast />
                  <Layout>
                    <Suspense fallback={<Loading />}>
                      <Outlet />
                    </Suspense>
                  </Layout>
                </ModalProvider>
              </ConfirmationModalContextProvider>
            </QueryClientProvider>
          </ErrorBoundary>
          <GlobalStyle />
        </Wrapper>
      </AuthProvider>
    </>
  );
}

export default App;
