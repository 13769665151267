import styled from 'styled-components';

const Button = styled.button`
  padding: 1.5rem 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 2px;
  font-weight: 600;
  font-family: 'Roboto';
  font-size: ${(props) => props.theme.small};
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  background: ${({ theme }) => theme.special};
  box-shadow: ${(props) => props.theme.shadow[2]};
  ${({ disabled }) => {
    if (disabled) {
      return { opacity: 0.4, cursor: 'not-allowed' };
    }
    return { opacity: 1, cursor: 'pointer' };
  }}
  :hover {
    ${({ disabled }) => {
      if (disabled) return {};
      return { opacity: 0.8 };
    }}
  }
  :focus {
    outline: none;
  }
`;

type SecondaryButtonProps = {
  size?: 'sm' | 'lg';
};

const SecondaryButton = styled(Button)<SecondaryButtonProps>`
  padding: ${(props) => (props.size === 'sm' ? '0.8rem 2rem' : '1.5rem 3.2rem')};
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${(props) => props.theme.gray2};
  border: 1px solid ${({ theme }) => theme.gray2};
  background: transparent;
  box-shadow: ${(props) => props.theme.shadow[2]};
`;

export { Button, SecondaryButton };
