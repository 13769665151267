import axios from 'axios';

import { API } from 'config/API';

export async function loadGadgets({ queryKey }: any) {
  const [, { codes }] = queryKey;

  const response = await axios.post(API, {
    service: 'loadGadgets',
    CODES: codes,
    deviceId: 'not available',
    deviceName: 'Unknown Device',
    latitude: '38.0397557',
    longitude: '23.8004079',
    OBJECTPARAMS: { BGMOBILECHECK: '0' },
  });
  if (!response.data.success) {
    throw new Error(response.data?.error);
  }
  return response.data;
}

export async function getGadgetData({ queryKey }: any) {
  const [, { cstId }] = queryKey;

  const response = await axios.post(API, {
    service: 'getGadgetData',
    CSTID: cstId,
    deviceId: 'not available',
    deviceName: 'Unknown Device',
    latitude: '38.0397557',
    longitude: '23.8004079',
    OBJECTPARAMS: { BGMOBILECHECK: '0' },
  });
  if (!response.data.success) {
    throw new Error(response.data?.error);
  }
  if (!response.data.totalcount) {
    throw new Error('No data found');
  }
  return response.data;
}
