import { useMemo } from 'react';

import { useGetSelectorData } from 'hooks/useGetListData';

import { getListValue } from './List.utils';
import ListWrapper from './ListWrapper';

type Props = {
  item: any;
  send: any;
};

export default function List({ item, send }: Props) {
  const { data, error, status } = useGetSelectorData(item.FIELDEDITOR);

  const displayValue = useMemo(() => {
    const [id] = String(item.FIELDVALUE).split('|');
    return getListValue(data?.data, id);
  }, [data, item.FIELDVALUE]);

  const props = { item, send, data, error, displayValue };

  return <ListWrapper isLoading={status === 'loading'} {...props} />;
}
