import { useRef, useEffect } from 'react';

import { ConfigBaseItem } from 'components/shared/ConfigBaseItem';
import { TextIcon } from 'components/shared/Icons/InfoIcons';
import { ConfigInput } from 'components/shared/Input/ConfigInput.style';
import { balanceFloat, removeDemicals, formatCurrency } from 'helpers/convertNumber';

type TextProps = {
  item: any;
  send: any;
  isDetail?: boolean;
};

export default function Text({ item, send, isDetail = false }: TextProps) {
  const inputRef = useRef<any>(null);

  const handleSelect = (e: any) => {
    e.target.select();
  };

  const textProps = () => {
    if (item.FIELDPROPERTY === 'Text') return {};
    return { pattern: /\d*/, inputMode: 'numeric' };
  };

  useEffect(() => {
    if (inputRef.current) {
      if (item.FIELDPROPERTY !== 'Text') {
        inputRef.current.value = formatCurrency(item.FIELDVALUE, item.NUMOFDECIMAL);
        if (item.FIELDPROPERTY === 'Number') {
          inputRef.current.value = balanceFloat(inputRef.current.value);
        }
        return;
      }
      inputRef.current.value = item.FIELDVALUE || '';
    }
  }, [item.FIELDVALUE, item.FIELDPROPERTY]);

  // function handleBlur(e) {
  //   if (item.FIELDPROPERTY === 'Number') {
  //     inputRef.current.value = balanceFloat(e.target.value);
  //   }
  //   send({
  //     type: 'UPDATE_VALUE',
  //     id: item.FIELDID,
  //     DISPLAYVALUE: inputRef.current.value,
  //     FIELDVALUE: inputRef.current.value,
  //     UPDATEDFIELDS: item.UPDATEDFIELDS,
  //     CLEAREDFIELDS: item.CLEAREDFIELDS,
  //   });
  // }
  // function handleChange(e) {
  //   if (item.FIELDPROPERTY !== 'Text') {
  //     inputRef.current.value = formatCurrency(e.target.value, item.NUMOFDECIMAL);
  //     if (item.FIELDPROPERTY === 'Integer') {
  //       inputRef.current.value = removeDemicals(inputRef.current.value);
  //     }
  //     send({
  //       type: 'UPDATE_VALUE',
  //       id: item.FIELDID,
  //       FIELDISTOUCHED: true,
  //       DISPLAYVALUE: item.DISPLAYVALUE,
  //       FIELDVALUE: item.FIELDVALUE,
  //     });
  //     return;
  //   }
  //   inputRef.current.value = e.target.value;
  //   send({
  //     type: 'UPDATE_VALUE',
  //     id: item.FIELDID,
  //     FIELDISTOUCHED: true,
  //     DISPLAYVALUE: inputRef.current.value,
  //     FIELDVALUE: inputRef.current.value,
  //   });
  // }

  function handleBlur(e: any) {
    let value = e.target.value;

    if (item.FIELDPROPERTY !== 'Text') {
      if (item.FIELDPROPERTY === 'Number') {
        value = balanceFloat(value);
      } else {
        value = formatCurrency(value, item.NUMOFDECIMAL);
        if (item.FIELDPROPERTY === 'Integer') {
          value = removeDemicals(value);
        }
      }
    }

    // Set the value of the input field
    inputRef.current.value = value;

    // Construct the payload object
    const payload = {
      type: 'UPDATE_VALUE',
      id: item.FIELDID,
      DISPLAYVALUE: value,
      FIELDVALUE: value,
    };

    // Add UPDATEDFIELDS and CLEAREDFIELDS if necessary
    if (item.FIELDPROPERTY === 'Number') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      payload.UPDATEDFIELDS = item.UPDATEDFIELDS;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      payload.CLEAREDFIELDS = item.CLEAREDFIELDS;
    }

    // Add FIELDISTOUCHED if necessary
    if (item.FIELDPROPERTY !== 'Text') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      payload.FIELDISTOUCHED = true;
    }

    // Send the update message
    send(payload);
  }

  return (
    <ConfigBaseItem item={item} leftIcon={<TextIcon />}>
      {({ baseStatus }) => (
        <ConfigInput
          data-cy={item.FIELDID}
          ref={inputRef}
          onBlur={handleBlur}
          //onChange={handleChange}
          onFocus={item.FIELDPROPERTY !== 'Text' ? handleSelect : undefined}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          modifiers={baseStatus.status}
          $isNumber={item.FIELDPROPERTY !== 'Text'}
          {...textProps()}
        />
      )}
    </ConfigBaseItem>
  );
}
