export function formatNumber(n: any) {
  // format number 1000000 to 1.000.000, remove all non-digits
  return n.replace(/[^\d-]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
export function formatFloat(n: any) {
  const newNumber = n.replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return balanceFloat(newNumber);
}

export function balanceFloat(input_val: any) {
  if (!input_val || input_val === '') {
    return input_val;
  }
  if (input_val.indexOf(',') >= 0) {
    const right_side = input_val.split(',')[1];
    if (right_side.length === 1) {
      return input_val + 0;
    }
    return input_val;
  }
  return input_val + ',00';
}
export function removeDemicals(input_val: any) {
  if (input_val.indexOf(',') >= 0) {
    // get position of first decimal
    const decimal_pos = input_val.indexOf(',');
    // split number by decimal point
    return input_val.substring(0, decimal_pos);
  }
  return input_val;
}

export function formatCurrency(input_val: any, numOfDemicals = 2) {
  // don't validate empty input or undefined
  if (input_val === '') return input_val;
  if (!input_val) {
    if (!numOfDemicals) return 0;
    const right_side = new Array(numOfDemicals).fill(0);
    return `0,${right_side.join('')}`;
  }
  // check for decimal
  if (input_val.indexOf(',') >= 0) {
    // get position of first decimal
    const decimal_pos = input_val.indexOf(',');
    // split number by decimal point
    let left_side = input_val.substring(0, decimal_pos);
    let right_side = input_val.substring(decimal_pos + 1);
    // add dots to left side
    left_side = formatNumber(left_side);
    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, numOfDemicals);
    // separate number with comma
    return left_side + ',' + right_side;
  }
  // no decimal entered
  return formatNumber(input_val);
}
