import { assign, createMachine } from 'xstate';

import { fetchPatient, fetchItem, checkEopyyCode } from './EoppyMachine.services';
import {
  cacheError,
  isEmpty,
  updateEopyyHistory,
  checkHistoryForCode,
  resetContext,
  updateParent,
  updateParentVariants,
} from './EopyyMachine.actions';

export const EopyyMachine = createMachine(
  {
    id: `eopyyMachine`,
    initial: 'idle',
    context: {
      fieldId: undefined,
      EOPYY_API: undefined,
      eopyyCode: '',
      item: { ContractCode: null, code: null },
      patient: { phoneNumber1: null, phoneNumber2: null, code: null, coPayment: null },
      errorMessage: undefined,
      eopyyHistory: [],
    },
    states: {
      idle: {
        on: {
          CHECK_CODE: [
            {
              cond: isEmpty,
              target: 'idle',
              actions: 'handleUpdate',
            },
            {
              target: 'loading',
              actions: ['handleUpdate', 'assignEoppyInfo'],
            },
          ],
        },
      },
      loading: {
        initial: 'checkingHistory',
        states: {
          checkingHistory: {
            onEntry: checkHistoryForCode,
            on: {
              VALID_CODE: {
                target: '#eopyyMachine.success',
              },
              UNKOWN_CODE: {
                target: 'fetchingPatient',
              },
              NO_PATIENT: {
                target: '#eopyyMachine.error',
                actions: assign((ctx: any) => ({
                  ...ctx,
                  errorMessage: 'Please select Patient',
                })),
              },
            },
          },
          fetchingPatient: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            invoke: {
              src: fetchPatient,
              onDone: {
                actions: assign((ctx, e) => {
                  return { ...ctx, patient: e.data };
                }),
                target: 'fetchingItem',
              },
              onError: {
                actions: [cacheError],
                target: '#eopyyMachine.error',
              },
            },
          },
          fetchingItem: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            invoke: {
              src: fetchItem,
              onDone: {
                actions: assign((ctx, e) => {
                  return { ...ctx, item: e.data };
                }),
                target: 'checkingEopyyCode',
              },
              onError: {
                actions: [cacheError],
                target: '#eopyyMachine.error',
              },
            },
          },
          checkingEopyyCode: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            invoke: {
              src: checkEopyyCode,
              onDone: {
                actions: updateEopyyHistory,
                target: '#eopyyMachine.success',
              },
              onError: {
                actions: [cacheError],
                target: '#eopyyMachine.error',
              },
            },
          },
        },
      },
      error: {
        on: { RETRY: 'idle' },
      },
      success: {
        entry: 'handleSuccess',
        on: { RETRY: 'idle' },
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    on: {
      RESET: {
        target: 'idle',
        actions: resetContext,
      },
      DETAIL_EDIT_UPDATE: {
        target: 'loading',
        actions: assign((ctx, e: any) => ({
          ...ctx,
          eopyyCode: e.eopyyCode,
        })),
      },
      UPDATE_CODE: {
        actions: assign((ctx, e: any) => ({ ...ctx, eopyyCode: e.code })),
      },
    },
  },
  {
    actions: {
      handleUpdate: updateParent,
      handleSuccess: updateParentVariants,
      assignEoppyInfo: assign((ctx: any, e: any) => {
        return {
          ...ctx,
          item: { code: e.eoppyItemCode },
          patient: {
            phoneNumber1: null,
            phoneNumber2: null,
            code: e.eopyyPatientCode,
          },
        };
      }),
    },
  },
);
