import { memo, useCallback, useMemo, useState } from 'react';

import { useMachine } from '@xstate/react';
import { useParams } from 'react-router-dom';
import StepWizard from 'react-step-wizard';

import FinalScreen from 'components/FinalScreen/FinalScreen';
import Page from 'components/Page/Page';
import { Container } from 'components/shared/Layouts/Layout';
import AskUser from 'components/shared/Modal/askUser';
import StepsNav from 'components/StepsInfo/StepsNav/StepsNav';
import { StepsProvider } from 'context/StepsProvider';
import { isObjEmpty } from 'helpers/helpers';
import useAskUser from 'hooks/useAskUser';
import { createFormMachine } from 'machines/FormMachine/FormMachine';
import { getConfigByID, getObjectByParams } from 'machines/FormMachine/FormMachine.utils';
import 'config/Animations.css';
import { createDetails, updateConfigWithExtraFields } from 'machines/shared/utils/utils';

import { useAuth } from '../context/AuthProvider';

function StepsPage() {
  const authState = useAuth();
  const { objects, config } = authState.context;
  const [final, setFinal] = useState(false);

  const { objectPath, optionPath } = useParams();
  const object = getObjectByParams(objectPath, optionPath, objects);

  const newConfig = useMemo(() => {
    const configById = getConfigByID(object.OBJECTID, config);
    return updateConfigWithExtraFields(config, configById);
  }, [object.OBJECTID, config]);
  const details = createDetails(newConfig, undefined, object);

  const Dynamic = useCallback(() => {
    const [appState, send] = useMachine(
      createFormMachine({
        object,
        details,
        objectPath,
        config: newConfig,
        globalConfig: config,
        globalObjects: objects,
      }),
      { devTools: true },
    );

    const isFinalState = appState.matches('final');
    const askOnTransition = useAskUser(appState.context, isFinalState);

    return (
      <StepsProvider appState={appState} appSend={send}>
        <Container data-testid='child-steps'>
          <AskUser isActive={askOnTransition} />
          <StepWizard
            nav={<StepsNav pages={object.PAGES || []} send={send} />}
            initialStep={1}
            isLazyMount={true}
            transitions={{
              enterRight: 'enter',
              enterLeft: 'enter',
              exitRight: 'exit',
              exitLeft: 'exit',
            }}
          >
            {pages}
          </StepWizard>
        </Container>
      </StepsProvider>
    );
  }, [objectPath, final]);

  const pages = useMemo(() => {
    if (isObjEmpty(object)) return [];
    const objectPages = object.PAGES.map((page: any, index: any) => (
      <Page key={page.PAGENUM} stepIndex={index + 1} {...{ page, object }} />
    ));
    return [...objectPages, <FinalScreen key='Final' setFinal={setFinal} />];
  }, [object]);

  return (
    <>
      <Dynamic />
    </>
  );
}

export default memo(StepsPage);
