import { useMemo, useState } from 'react';

import Table from 'components/Table/Table';
import { MemoryTableWrapper } from 'components/Table/Table.style';
import { useGetgadgetdata } from 'hooks/useGetgadgetdata';

import { ChartBody } from './ChartBody';
import { AreaChartIcon, BarChartIcon } from './Charts.style';
import { BarChart, LineChart } from './Types';

const chartArr = [
  { tag: 'area', label: 'Area Chart', icon: <AreaChartIcon /> },
  { tag: 'bar', label: 'Bar Chart', icon: <BarChartIcon /> },
];

type Props = {
  cstId: string;
  axes: { X: string; Y: string };
  description: string;
  structure: Array<{ name: string; type: string; caption: string }>;
};

function CustomCharts({ cstId, axes, description, structure }: Props) {
  const [showList, setShowList] = useState(true);
  const [currentChart, setCurrentChart] = useState(chartArr[0]);
  const { data = { rows: [] }, ...queryData } = useGetgadgetdata(cstId);

  const convertedData = useMemo(() => {
    return data.rows.map((item: any) => {
      if (isNaN(item[axes.Y])) return item;
      const numY = Number(item[axes.Y]);
      return { ...item, [axes.Y]: numY };
    });
  }, [data]);

  const width = useMemo(() => {
    if (!convertedData.length) return null;
    const valueArr = convertedData.map((item: any) => item.VALUE);
    const maxValue = Math.max(...valueArr);
    return (maxValue.toFixed(0).toString().length + 3) * 10;
  }, [convertedData]);

  const tableColumns = useMemo(() => {
    return structure.map((item) => ({
      type: item.type,
      accessor: item.name,
      Header: item.caption,
    }));
  }, [structure]);
  const tableData = useMemo(() => {
    return data.rows.map((item: any, index: number) => ({ ...item, ID: index }));
  }, [data]);

  function getNextChart(prevMode: any) {
    const index = chartArr.indexOf(prevMode);
    if (index === chartArr.length - 1) {
      return chartArr[0];
    }
    return chartArr[index + 1];
  }
  const nextChart = getNextChart(currentChart);

  return (
    <ChartBody
      onChartChange={() => {
        setShowList(false);
        setCurrentChart((prev) => getNextChart(prev));
      }}
      onChangeToList={() => setShowList((bool) => !bool)}
      description={description}
      queryData={queryData}
      showList={showList}
      nextChart={nextChart}
    >
      {(() => {
        if (showList) {
          return (
            <MemoryTableWrapper $isClickable={false} $offset={11}>
              <Table
                data={tableData}
                columns={tableColumns}
                tableProps={{ disableGroupBy: true }}
                usage={{ pagination: true, columnsFilter: true }}
              />
            </MemoryTableWrapper>
          );
        }
        switch (currentChart.tag) {
          case 'area': {
            return <LineChart data={convertedData} width={width} axes={axes} />;
          }
          case 'bar': {
            return <BarChart data={convertedData} width={width} axes={axes} />;
          }
          default: {
            return null;
          }
        }
      })()}
    </ChartBody>
  );
}

export default CustomCharts;
