import { AiTwotoneDelete } from 'react-icons/ai';
import {
  ImFilePdf,
  ImFileText2,
  ImFileExcel,
  ImImage,
  ImFileWord,
  ImFileEmpty,
} from 'react-icons/im';
import { SiMicrosoftpowerpoint } from 'react-icons/si';
import styled, { css } from 'styled-components';

import { Button } from 'components/shared/Button/Button';
import { StyledText } from 'components/shared/shared.style.';
import { device } from 'config/device';

export const Wrapper = styled.div`
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 220px;
  padding: 3rem;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.shadow[3]};
  @media ${device.mobile} {
    width: 100%;
    justify-content: center;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Name = styled(StyledText)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.small};
  text-align: center;
  word-wrap: break-word;
  width: 100%;
`;

type ActionsWrapperProps = {
  readOnly?: boolean;
};

export const ActionsWrapper = styled.div<ActionsWrapperProps>`
  display: flex;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: ${(props) => (props.readOnly ? 'center' : 'space-between')};
`;

export const ViewFIleButton = styled(Button)`
  height: 40px;
  padding: 2rem 1rem;
  grid-gap: 1.5rem;
  display: flex;
  align-items: center;
  background: #434c54;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  svg {
    color: currentColor;
    font-size: 25px;
    flex-shrink: 0;
  }
`;

export const RemoveIcon = styled(AiTwotoneDelete)`
  cursor: pointer;
  height: 22px;
  width: 22px;
`;

export const PdfIcon = styled(ImFilePdf)`
  color: red;
  margin-bottom: 10px;
  font-size: 50px;
`;

export const UnknowFile = styled(ImFileEmpty)`
  margin-bottom: 10px;
  font-size: 50px;
  color: ${({ theme }) => theme.secondary};
`;

export const TextIcon = styled(ImFileText2)`
  margin-bottom: 10px;
  font-size: 50px;
  color: ${({ theme }) => theme.secondary};
`;

export const WordIcon = styled(ImFileWord)`
  margin-bottom: 10px;
  font-size: 50px;
  color: #285394;
`;

export const ExcelIcon = styled(ImFileExcel)`
  margin-bottom: 10px;
  font-size: 50px;
  color: #1ca24a;
`;

export const ImageIcon = styled(ImImage)`
  margin-bottom: 10px;
  font-size: 50px;
`;

export const PowerPointIcon = styled(SiMicrosoftpowerpoint)`
  margin-bottom: 10px;
  font-size: 50px;
  color: #f78c68;
`;
