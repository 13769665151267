import { NavLink } from 'react-router-dom';

import { useAuth } from 'context/AuthProvider';

import AccountMenu from './AccountMenu';
import NavDropList from './NavDropList';
import { NavLinkWrapper } from './NavLinks.style';
import { LinkIcon } from '../NavBar.style';

type Props = {
  isOverFlown: boolean;
};

function NavLinks({ isOverFlown }: Props) {
  const authState = useAuth();
  const isAuthorized = authState.matches('authorized');
  const isInitialized = authState.matches('authorized.initialized');
  const { objects = [], user, branch } = authState.context;

  function insertIcon(options: any, Icon: any) {
    return options.map((opt: any) => ({ ...opt, ICON: <Icon /> }));
  }

  return (
    <NavLinkWrapper $isOverFlown={isOverFlown}>
      {isInitialized && (
        <NavLink
          to='/'
          className={({ isActive }) => [isActive ? 'activeLink' : ''].join(' ')}
        >
          Home
          {location.pathname === '/' && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                width: '100%',
                background: '#079dd6',
                height: '80%',
                zIndex: '-1',
                borderRadius: '0px 0px 15px 15px',
              }}
            ></div>
          )}
        </NavLink>
      )}
      {isInitialized &&
        objects.map((object: any, index: number) => {
          const { MENUOPTIONS, OBJECTPATH, OBJECTNAME } = object;
          const menuOptions = insertIcon(MENUOPTIONS, LinkIcon);
          return (
            <NavDropList
              key={index}
              title={OBJECTNAME}
              object={object}
              objectPath={OBJECTPATH}
              menuOptions={menuOptions}
            />
          );
        })}
      <AccountMenu isOpen={isAuthorized} {...{ user, branch }} />
    </NavLinkWrapper>
  );
}

export default NavLinks;
