import { useQuery } from 'react-query';
import styled from 'styled-components';

import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { ClipLoader } from 'components/shared/Loading';
import { customAxios } from 'helpers/helpers';

const Label = styled.div`
  max-width: 22rem;
  width: max-content;
  display: flex;
  grid-gap: 0.5rem;
  justify-content: center;
  align-items: center;
`;
async function getBrowserInfoCounter(object: any, list: any) {
  const response = await customAxios({
    service: 'getBrowserInfo',
    OBJECT: object || '',
    LIST: list || '',
    FILTERS: '',
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData.error);
  return responseData.totalcount;
}

type NavLinkLabelProps = {
  option: any;
  object: any;
};

function NavLinkLabel({ option, object }: NavLinkLabelProps) {
  return (
    <Label>
      {option.TITLE}
      {(() => {
        if (!option.DISPLAYTOTALCOUNTS) return null;
        return <RecordsCounter object={object} />;
      })()}
    </Label>
  );
}

type RecordsCounterProps = {
  object: any;
};

function RecordsCounter({ object }: RecordsCounterProps) {
  const { OBJSOFTONENAME, OBJSOFTONELIST } = object;

  const { status, error, data, isFetching } = useQuery(
    ['count', OBJSOFTONENAME],
    () => getBrowserInfoCounter(OBJSOFTONENAME, OBJSOFTONELIST),
    { retry: false, refetchOnWindowFocus: false },
  ) as any;

  if (status === 'loading' || isFetching) {
    return <ClipLoader size={18} />;
  }
  if (status === 'error') {
    return <ErrorInfo id={OBJSOFTONENAME} message={error.message} />;
  }
  return <div style={{ marginLeft: '0.1rem' }}>({data})</div>;
}

export default NavLinkLabel;
