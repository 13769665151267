import axios from 'axios';

import { PORTAL_API } from 'config/API';
import { SOFTONE_DATABASE } from 'config/constants';
import { publicWindow } from 'config/public';
import { getSearchData } from 'machines/shared/utils/getSpecialFieldsData';

import { customAxios } from '../helpers';

const { showMessages } = publicWindow;

async function calculate(object: any, fieldsData: any, key: any, locateObject: any) {
  const { locateInfo, searchInfo } = locateObject;

  const response = await customAxios({
    service: 'calculate',
    KEY: key,
    OBJECT: object.OBJSOFTONENAME,
    FORM: object.OBJSOFTONEFORM || '',
    data: fieldsData,
    LOCATEINFO: locateInfo,
  });
  const responseData = response.data;
  //console.log('Response Data: ', responseData.message);
  if (!responseData.success) throw new Error(responseData.error);

  const searchData = await getSearchData(responseData.data, searchInfo);

  if (showMessages === 0) {
    return {
      editData: responseData.data,
      searchData,
    };
  } else if (showMessages === 1) {
    return {
      message: responseData.message,
      editData: responseData.data,
      searchData,
    };
  } else if (showMessages === 2) {
    const messages = responseData.message?.split('\r\n');
    const uniqueMessages: any = [];
    // Push each message to uniqueMessages array only if it's not already present
    messages?.forEach((message: any) => {
      if (!uniqueMessages.includes(message)) {
        uniqueMessages.push(message);
      }
    });
    // Convert the uniqueMessages array to a string
    const uniqueMessagesString = JSON.stringify(uniqueMessages);
    // Store the uniqueMessages string in sessionStorage
    sessionStorage.setItem('uniqueMessages', uniqueMessagesString);

    return {
      editData: responseData.data,
      searchData,
    };
  }
}

async function changePassword({ oldPassword, newPassword }: any) {
  const result = await customAxios({
    service: 'changePassword',
    OLD: oldPassword,
    NEW: newPassword,
  });
  if (!result.data.success) {
    throw new Error('Softone Issue with changing password');
  }
  return result;
}

async function setData({ object, data, key }: any) {
  const response = await customAxios({
    KEY: key,
    service: 'setData',
    OBJECT: object.OBJSOFTONENAME,
    FORM: object.OBJSOFTONEFORM || '',
    data,
  });
  const responseData = response.data;

  if (!responseData.success) throw new Error(responseData.error);
  return responseData;
}

async function getInfoAndBrowserData({ queryKey }: any) {
  const [, { object, list, filters, query = '', softoneTopLimit }] = queryKey;
  const infoResponse = await customAxios({
    service: 'getBrowserInfo',
    OBJECT: object || '',
    LIST: list || '',
    FILTERS: filters
      ? `SELECTTOP=${softoneTopLimit}&SEARCH=` + query + '&FILTERS=&' + filters
      : `SELECTTOP=${softoneTopLimit}&SEARCH=` + query,
  });
  const infoResult = infoResponse.data;
  if (!infoResult.success) {
    throw new Error(infoResult.error);
  }
  if (infoResult.totalcount === 0) {
    return { data: [], infoData: [] };
  }
  const browserResponse = await customAxios({
    service: 'getBrowserData',
    reqID: infoResult.reqID,
    START: 0,
    LIMIT: softoneTopLimit,
  });
  const browserResult = browserResponse.data;
  if (!browserResult.success) {
    throw new Error(browserResult.error);
  }
  const columnsWithSoftoneFieldsType = infoResult.columns.reduce(
    (prev: any, cur: any, index: any) => {
      return [...prev, { ...cur, type: infoResult.fields[index + 1].type }];
    },
    [],
  );
  return { data: browserResult.rows, infoData: columnsWithSoftoneFieldsType };
}

async function getSelectorStringData({ queryKey }: any) {
  const [, { editor }] = queryKey;

  const response = await customAxios({
    service: 'getSelectorData',
    EDITOR: editor,
  });
  let data = response.data;
  if (typeof response.data === 'string' && response.data.includes(`, "model":, `)) {
    //megali kagkouria logw stringlist softone
    data = data.replace('model":,', 'model":"",');
    data = JSON.parse(data);
    data.model = [{ name: 'CODE' }, { name: 'DESCRIPTION' }];
    data.totalCount = data.data.length || 0;
    if (!data.success) throw new Error(data.error);
    if (data.totalcount === 0) {
      return { columns: [], data: [] };
    }
    if (data.data.length === 0) {
      throw new Error('No records found!');
    }
  }

  return { columns: data.model, data: data.data, editor };
}

async function getSelectorData({ queryKey }: any) {
  const [, { editor }] = queryKey;

  const response = await customAxios({
    service: 'getSelectorData',
    EDITOR: editor,
  });
  const data = response.data;
  if (!data.success) throw new Error(data.error);
  if (data.totalcount === 0) {
    return { columns: [], data: [] };
  }
  if (data.data.length === 0) {
    throw new Error('No records found!');
  }
  return { columns: data.model, data: data.data };
}

async function getData({ object, form, key, locateInfo, fieldName }: any) {
  const response = await customAxios({
    service: 'getData',
    OBJECT: object,
    FORM: form || '',
    LOCATEINFO: locateInfo,
    KEY: key,
  });
  const data = response.data;
  return { ...data, fieldName };
}

async function getEditDataWithKey({ queryKey }: any) {
  const [, { currentObject, fieldValue, locateObject }] = queryKey;
  return await getEditData({ currentObject, key: fieldValue, locateObject });
}

async function getEditData({ key, copy = false, currentObject, locateObject }: any) {
  const { locateInfo, searchInfo } = locateObject;
  const response = await customAxios({
    service: 'getData',
    KEY: key,
    FORM: currentObject.OBJSOFTONEFORM || '',
    OBJECT: currentObject.OBJSOFTONENAME || '',
    LOCATEINFO: locateInfo,
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData.error);
  const editData = responseData.data;
  /* second response */
  const signature = await getSignature({ currentObject, key });
  const searchData = await getSearchData(responseData.data, searchInfo);
  /* -------------- */
  return {
    //if we use the copy flag , we dont edit the form , we create a new object
    key: copy ? null : key,
    editData,
    signature,
    searchData,
  };
}

async function getSqlData({ queryKey }: any) {
  const [, { SqlName, sqlFilters }] = queryKey;
  const uncompletedRelatedFields = sqlFilters.filter(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (sqlFilter: any) => !sqlFilter.value.length > 0,
  );
  if (uncompletedRelatedFields.length > 0) {
    throw new Error('Related values not completed');
  }
  const response = await customAxios({
    service: 'SqlData',
    SqlName: SqlName,
    ...sqlFilters.reduce((acc: any, curr: any) => {
      return { ...acc, [curr.key]: curr.value };
    }, {}),
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData.errorcode);
  return responseData.rows;
}

async function getPdf({ object, key, template = {}, printTemplate }: any) {
  const response = await customAxios({
    service: 'printDoc',
    OBJECT: object,
    KEY: key,
    TEMPLATE: template.TEMPLTEID || printTemplate,
    FORMAT: 'PDF',
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData?.errorcode);
  return { fileName: responseData.filename, caption: template.TEMPLTECAPTION };
}

export async function getSignature({ currentObject, key }: any) {
  if (currentObject.REGSIGNATURE !== SOFTONE_DATABASE) {
    return null;
  }
  const response = await axios.post(PORTAL_API, {
    service: 'getSignature',
    KEY: key,
    OBJECT: currentObject.OBJSOFTONENAME,
    XTRDOCTYPE: currentObject.XTRDOCTYPE,
  });
  const responseData = response.data;
  if (currentObject.REGSIGNATURE === SOFTONE_DATABASE) {
    const loadedSignatureFileName = response.data.signatureFileName;
    sessionStorage.setItem('loadedSignatureFileName', loadedSignatureFileName);
  }
  if (!responseData.success) return null;
  return responseData.data;
}
export async function setSignature({ key, data, object }: any) {
  const response = await axios.post(PORTAL_API, {
    service: 'setSignature',
    KEY: key,
    OBJECT: object.OBJSOFTONENAME,
    XTRDOCTYPE: object.XTRDOCTYPE,
    data,
  });
  const responseData = response.data;
  if (!responseData.success) throw new Error(responseData?.error);
  return responseData;
}

export async function setApproval({ data, object, form }: any) {
  const response = await axios.post(PORTAL_API, {
    data,
    form,
    object,
    service: 'setApproval',
  });
  const responseData = response.data;
  if (!responseData.success) throw responseData;
  return responseData;
}

export {
  getPdf,
  calculate,
  setData,
  getInfoAndBrowserData,
  getSelectorData,
  getSelectorStringData,
  getEditData,
  getSqlData,
  getData,
  getEditDataWithKey,
  changePassword,
};
