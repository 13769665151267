import formatISO9075 from 'date-fns/formatISO9075';

import { getFieldsData } from 'machines/shared/utils/getFieldsData';
import { createDetails } from 'machines/shared/utils/utils';

const getInfoIndex = (infoData: any, name: any) => {
  return infoData.findIndex((item: any) => item.dataIndex.split('.')[1] === name);
};
export const getAllEvents = (data: any) => {
  if (!data || !data.infoData.length) return [];
  const endIndex = getInfoIndex(data.infoData, 'FINALDATE');
  const startIndex = getInfoIndex(data.infoData, 'FROMDATE');
  const titleIndex = getInfoIndex(data.infoData, 'COMMENTS');
  return data.data.map((item: any) => ({
    id: item[0].split(';')[1],
    end: new Date(item[endIndex + 1]),
    start: new Date(item[startIndex + 1]),
    title: item[titleIndex + 1],
  }));
};

export const getEventData = (config: any, event: any) => {
  return config.map((item: any) => {
    if (item.FIELDNAME.includes('FROMDATE')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = formatISO9075(event.start);
      return { ...item, FIELDVALUE: value, DISPLAYVALUE: value };
    }
    if (item.FIELDNAME.includes('FINALDATE')) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = formatISO9075(event.end);
      return { ...item, FIELDVALUE: value, DISPLAYVALUE: value };
    }
    return item;
  });
};

export const getEventSetData = (config: any, object: any) => {
  const details = createDetails(config, undefined, object);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return getFieldsData(config, details);
};
