import styled from 'styled-components';

import { device } from 'config/device';

type IconWrapperProps = {
  disabled: boolean;
};

export const IconWrapper = styled.li<IconWrapperProps>`
  height: 2.5rem;
  width: 2.5rem;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: ${(props) => (props.disabled ? 'disabled' : 'pointer')};
  svg {
    height: 100%;
    width: 100%;
    fill: ${({ theme }) => theme.secondary};
  }
  :hover {
    opacity: 0.6;
  }
`;
export const PaginationWrapper = styled.ul`
  list-style: none;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${({ theme }) => theme.small};
  color: ${({ theme }) => theme.secondary};
  li {
    margin-right: 3.5rem;
    :last-child {
      margin-right: 0;
    }
  }

  @media ${device.tablet} {
    justify-content: space-between;
    li {
      margin-right: 1rem;
    }
  }
`;
export const DropdownWrapper = styled.li`
  display: flex;
  align-items: center;
  div {
    min-width: max-content;
  }
`;
