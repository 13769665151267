import Checkbox from 'components/shared/ui/CheckBox';
import { Image } from 'components/shared/ui/Image';
import { API } from 'config/API';

import {
  getValidRows,
  getIsAllChecked,
  checkIfapproval,
} from '../Approval/apprvoval.utils';

type Props = {
  cell: any;
  rowID: string;
  approvalState: any;
  isSmall?: boolean;
};

function RenderCell({ cell, rowID, approvalState, isSmall }: Props) {
  const { id, type } = cell.column;
  if (type === 'image') {
    return <Image src={`${API}?filename=${cell.value}`} />;
  }
  if (checkIfapproval(id)) {
    if (cell.row.isGrouped) {
      const validRows = getValidRows(cell.row.leafRows);
      const isChecked = getIsAllChecked(validRows, approvalState.data);
      return <Checkbox checked={isChecked} />;
    }
    const found = approvalState.data.find((item: any) => item.ID === rowID);
    return <Checkbox checked={Boolean(found)} />;
  }
  return (
    <div style={{ display: 'flex', width: isSmall ? '60px' : '' }}>
      {cell.render('Cell')}
    </div>
  );
}

export default RenderCell;
