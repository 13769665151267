import { useRef } from 'react';

import { useNavigate } from 'react-router-dom';

import { useUserConfiguration } from 'config/themes';
import useIsOverflow from 'hooks/useIsOverflow';

import { NavbarContainer, NavContent, NavLogo } from './NavBar.style';
import NavLinks from './NavLinks/NavLinks';
import NavMenu from './NavMenu/NavMenu';

export default function NavBar() {
  const ref = useRef();
  const isOverFlown = useIsOverflow(ref, () => {});

  const navigate = useNavigate();
  const { logoPath, logoHeight } = useUserConfiguration();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NavbarContainer ref={ref}>
      <NavContent>
        <NavLogo
          alt='app-logo'
          src={logoPath}
          $logoHeight={logoHeight}
          onClick={() => navigate('/')}
        />
        <NavMenu isOverFlown={Boolean(isOverFlown)} />
        <NavLinks isOverFlown={Boolean(isOverFlown)} />
      </NavContent>
    </NavbarContainer>
  );
}
