import { motion } from 'framer-motion';
import styled from 'styled-components';

import { device } from 'config/device';

export const SidebarOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.7;
  background: black;
`;
export const SidebarContainer = motion(styled.div`
  min-width: 30rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 30;
  background: ${({ theme }) => theme.text};
  @media ${device.mobile} {
    width: 100vw;
  }
`);

export const SidebarContent = styled.div`
  height: 100%;
  color: white;
  position: relative;
  padding: 1rem 0;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  .header {
    padding-right: 3rem;
    font-size: ${(props) => props.theme.medium};
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
  }
  .info {
    padding-right: 3rem;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    font-size: ${(props) => props.theme.medium};
  }
  .children {
    height: 100%;
    overflow-y: auto;
    padding-right: 3rem;
  }
  .footer {
    padding-right: 3rem;
  }
`;
