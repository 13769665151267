import { VscGoToFile } from 'react-icons/vsc';

import { VIEW_FILES_API } from 'config/API';

import { ViewFIleButton } from './File.style';

type Props = {
  name: string;
  basePath: string;
};

export const IISFile = ({ name, basePath }: Props) => {
  return (
    <a
      target='_blank'
      href={`${VIEW_FILES_API}${basePath}${encodeURIComponent(name)}`}
      style={{ color: 'white', textDecoration: 'none' }}
      rel='noreferrer'
    >
      <ViewFIleButton>
        File
        <VscGoToFile />
      </ViewFIleButton>
    </a>
  );
};
