import { createMachine } from 'xstate';

import {
  spawnEditObject,
  killChild,
  assignCallback,
  spawnCopyObject,
  showPdfResult,
  renderErrorMessage,
  spawnNewObject,
} from './ListMachine.actions';
import { getColumnPdf } from './ListMachine.services';

export const ListMachine = createMachine({
  initial: 'viewing',
  context: {
    refetch: undefined,
    errorMessage: '',
    childObjectRef: undefined,
  },
  states: {
    viewing: {
      on: {
        spawnForm: {
          target: 'editing',
          actions: [spawnEditObject, assignCallback],
        },
        spawnCopyForm: {
          target: 'editing',
          actions: [spawnCopyObject, assignCallback],
        },
        spawnNewForm: {
          target: 'editing',
          actions: [spawnNewObject, assignCallback],
        },
        getColumnPdf: { target: 'gettingPdf' },
      },
    },
    editing: {
      on: {
        CHILD_UPDATE: {
          target: 'viewing',
          actions: (ctx: any) => ctx.refetch(),
        },
      },
    },
    gettingPdf: {
      invoke: {
        src: getColumnPdf,
        onDone: {
          target: 'viewing',
          actions: showPdfResult,
        },
        onError: {
          target: 'failure',
          actions: renderErrorMessage,
        },
      },
    },
    failure: {},
  },
  //@ts-ignore
  on: {
    CLOSE: {
      target: 'viewing',
      actions: killChild,
    },
  },
});
