function isItemValid(item: any) {
  return 'FIELDLISTFILTERS' in item || 'FIELDEDITOR' in item;
}
function getRevisableFields(item: any) {
  if ('FIELDEDITOR' in item && 'FIELDLISTFILTERS' in item)
    return { FIELDEDITOR: item.FIELDEDITOR, FIELDLISTFILTERS: item.FIELDLISTFILTERS };
  if ('FIELDLISTFILTERS' in item) return { FIELDLISTFILTERS: item.FIELDLISTFILTERS };
  return { FIELDEDITOR: item.FIELDEDITOR };
}

function getValueOfBracketID(config: any, id: any) {
  const foundItem = config.find((item: any) => item.FIELDID === Number(id));
  if (!foundItem || !foundItem.FIELDVALUE) return;
  return foundItem.FIELDVALUE;
}
function replaceAllBracketsID(property: any, modificationInfo: any) {
  modificationInfo.forEach(({ id, value }: any) => {
    property = property.replace(`[${id}]`, value);
  });
  return property;
}
function createModificationInfo(config: any, bracketsID: any) {
  return bracketsID.reduce((acc: any, id: any) => {
    const requestedValue = getValueOfBracketID(config, id);
    if (!requestedValue) return acc;
    return [...acc, { id, value: requestedValue }];
  }, []);
}
export function getAllBracketsID(propValue: any) {
  const regEXp = /\[(\d+)\]/;
  const globalRegExp = /\[(\d+)\]/g;
  const results = propValue.match(globalRegExp);
  if (results === null) return false;
  return results.map((result: any) => result.match(regEXp)[1]);
}
function getAllUpdatedFields(config: any, revisableProperties: any) {
  return Object.entries(revisableProperties).reduce((acc, [propKey, propValue]) => {
    const bracketsID = getAllBracketsID(propValue);
    if (!bracketsID) return acc;
    const modificationInfo = createModificationInfo(config, bracketsID);
    const newProperty = replaceAllBracketsID(propValue, modificationInfo);
    return { ...acc, [propKey]: newProperty };
  }, {});
}

export function updateRelatedFields(config = [], item = {}) {
  if (!isItemValid(item)) return item;
  const revisableProperties = getRevisableFields(item);
  return getAllUpdatedFields(config, revisableProperties);
}
