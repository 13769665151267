import { DeleteIcon } from 'components/shared/Icons/ActionIcons';
import ErrorInfo from 'components/shared/Layouts/Error/ErrorInfo';
import { StyledFlex } from 'components/shared/shared.style.';

export function getOriginalRow(row: any) {
  if (row.isGrouped) {
    const [curRow] = row.leafRows;
    return curRow.original;
  }
  return row.original;
}
export function getAllRows(rows: any) {
  return rows.reduce((prev: any, row: any) => {
    if (row.isGrouped) return [...prev, ...row.leafRows];
    return [...prev, row];
  }, []);
}
export function getValidRows(rows: any) {
  return rows.map((row: any) => getOriginalRow(row));
}
export function getIsAllChecked(validRows: any, approvalData: any) {
  return validRows.every((row: any) => {
    return approvalData.find((item: any) => item.ID === row.ID);
  });
}

export function checkIfapproval(name = '', delimiter = '_') {
  return name.split(delimiter)[1] === 'CCCCCLEDITAPPRV';
}

export function getListTable(data: any) {
  if (!data.length) return null;
  const keys = Object.keys(data[0]);
  const foundItem = keys.find((item) => item.includes('_'));
  if (!foundItem) return null;
  return foundItem.split('_')[0];
}

export function getApprovalTableData({ data, errorData, isError, onDelete }: any) {
  if (isError) {
    const { errorid = [], errormsg = [] } = errorData;
    const filteredData = data.filter((item: any) => {
      return errorid.includes(Number(item.ID));
    });
    return filteredData.map((item: any, index: number) => {
      const Actions = (
        <StyledFlex $gridGap='1rem'>
          <ErrorInfo
            id={item.ID}
            place='right'
            message={errormsg[index]}
            style={{
              width: '1.9rem',
              height: '1.9rem',
              marginLeft: '1rem',
            }}
          />
          <DeleteIcon onClick={() => onDelete(item)} />
        </StyledFlex>
      );
      return { ...item, Actions };
    });
  }
  return data.map((item: any) => {
    const Actions = <DeleteIcon onClick={() => onDelete(item)} />;
    return { ...item, Actions };
  });
}
