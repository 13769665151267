import { FaCheck } from 'react-icons/fa';
import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';

import { device } from 'config/device';
import { useUserConfiguration } from 'config/themes';

const Placeholder = styled.div`
  width: 20rem;
  @media ${device.laptop} {
    width: 15rem;
    maxwidth: 20rem;
  }
`;

type SelectProps = {
  isSubmitted: boolean;
  isDisabled: boolean;
  placeholder: string;
};

export function Select({ isSubmitted, isDisabled, placeholder, ...rest }: SelectProps) {
  const { specialColor } = useUserConfiguration();

  const { Option } = components;
  const IconOption = (props: any) => (
    <Option {...props}>
      <div className='option'>
        {props.isSelected && <FaCheck />}
        {props.data.label}
      </div>
    </Option>
  );

  function isInvalid(state: any) {
    const { hasValue, isFocused } = state;
    return isSubmitted && !isDisabled && !hasValue && !isFocused;
  }

  return (
    <ReactSelect
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 99 }),
        control: (provided, state) => ({
          ...provided,
          boxShadow: isInvalid(state) ? '0 0 0 1px #BE6464' : provided['boxShadow'],
        }),
        singleValue: (provided) => ({
          ...provided,
          width: '20rem',
          cursor: 'pointer',
          transform: 'none',
          position: 'relative',
          top: 0,
          [`@media ${device.laptop}`]: {
            width: '15rem',
            maxWidth: '20rem',
          },
        }),
        valueContainer: (css) => ({
          ...css,
          input: { height: 0 },
        }),
        option: (provided, state) => ({
          ...provided,
          cursor: 'pointer',
          opacity: state.isSelected ? 1 : 0.5,
          color: 'inherit',
          fontWeight: state.isSelected ? 'bold' : 'inherit',
          background: 'inherit',
          '&:hover': {
            color: specialColor,
            background: `${specialColor}33`,
          },
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: specialColor,
        },
      })}
      components={{
        Option: IconOption,
        Placeholder: () => <Placeholder>{placeholder}</Placeholder>,
      }}
      isDisabled={isDisabled}
      isSearchable={false}
      placeholder={isDisabled ? '' : placeholder}
      {...rest}
    />
  );
}
