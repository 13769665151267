import { ReactComponent as CheveronLeft } from 'assets/icons/cheveron-left.svg';
import { ReactComponent as CheveronRight } from 'assets/icons/cheveron-right.svg';
import DropDown from 'components/shared/DropDown/DropDown';
import { StyledFlex } from 'components/shared/shared.style.';

import { IconWrapper, PaginationWrapper, DropdownWrapper } from './Pagination.style';

type Props = {
  enable: boolean;
  total: number;
  nextPage: () => void;
  pageSize: number;
  pageIndex: number;
  previousPage: () => void;
  setPageSize: (value: number | string) => void;
  canNextPage: boolean;
  canPreviousPage: boolean;
};

const Pagination = ({
  enable,
  total,
  nextPage,
  pageSize,
  pageIndex,
  previousPage,
  setPageSize,
  canNextPage,
  canPreviousPage,
}: Props) => {
  if (!enable || !total) return null;
  return (
    <PaginationWrapper>
      {(() => {
        const start = pageIndex * pageSize;
        const end = start + pageSize;
        return `${start}-${end}`;
      })()}
      <li style={{ marginLeft: 5 }}>of {total}</li>
      <DropdownWrapper>
        <div style={{ marginRight: 5 }}>Per page:</div>
        <DropDown
          selectedItem={pageSize}
          handleClick={setPageSize}
          data={[10, 20, 30, 40, 50]}
        />
      </DropdownWrapper>
      {(() => {
        if (total < pageSize) return null;
        return (
          <StyledFlex>
            <IconWrapper disabled={!canPreviousPage} onClick={previousPage}>
              <CheveronLeft />
            </IconWrapper>
            <IconWrapper disabled={!canNextPage} onClick={nextPage}>
              <CheveronRight />
            </IconWrapper>
          </StyledFlex>
        );
      })()}
    </PaginationWrapper>
  );
};

export default Pagination;
