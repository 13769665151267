import { createNewItem, createNewLines } from './getSoftoneData';
import { createDetailGroups } from './utils';

export function updateConfigWithCalcData(config: any, eventData: any) {
  return config.map((item: any) => {
    if (!item.FIELDNAME) return item;
    return createNewItem({ item, index: 0, eventData });
  });
}
export function updateConfigWithEditData(config: any, eventData: any, type: any) {
  return config.map((item: any) => {
    if (!item.FIELDNAME) return item;
    if (type === 'copy' && item.FIELDBUFEXCLUDE) return item;
    return createNewItem({ item, index: 0, eventData });
  });
}

export function updateStoreWithEditData(config: any, eventData: any) {
  const detailGroups = createDetailGroups(config);
  const detailGroupsEntries = Object.entries(detailGroups);

  return detailGroupsEntries.reduce((newObj, [name, storeConfig]) => {
    const newLines = createNewLines(storeConfig, eventData);
    return { ...newObj, [name]: newLines };
  }, {});
}
export function updateStoreWithCalcData(ctx: any, eventData: any) {
  return ctx.details.reduce((prev: any, { name, config }: any) => {
    const newLines = createNewLines(config, eventData);
    const updatedLines = newLines.map((line: any) => {
      return { ...line, item: line.item };
    });
    return { ...prev, [name]: updatedLines };
  }, {});
}

export function updateDraftWithCalcData(eventData: any) {
  if (!eventData.draft) return;

  const foundItem = eventData.draft?.find((item: any) => item.FIELDNAME);
  const [tableName] = foundItem.FIELDNAME.split('.');

  const prevArray = eventData.prevData[tableName];
  if (!prevArray || !prevArray.length) return eventData.draft;

  const { LINENUM } = prevArray[prevArray.length - 1];
  const index =
    eventData ??
    eventData.editData[tableName].findIndex((item: any) => {
      return Number(item.LINENUM) === Number(LINENUM);
    });

  return eventData.draft?.map((item: any) => {
    if (!item.FIELDNAME) return item;
    return createNewItem({ item, index, eventData });
  });
}
