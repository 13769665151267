import {
  BarChart as RecBarChart,
  Bar,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';

import { useUserConfiguration } from 'config/themes';

type Props = {
  data: any;
  width: number | null;
  axes: { X: string; Y: string };
};

export function BarChart({ data, width, axes }: Props) {
  const { theme } = useUserConfiguration();
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <RecBarChart data={data}>
        <CartesianGrid opacity={0.1} vertical={false} />
        <XAxis
          dataKey={axes.X}
          tickLine={false}
          tickMargin={5}
          domain={['auto', 'auto']}
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          padding={{ bottom: 20 }}
        />
        <YAxis
          dataKey={axes.Y}
          tickLine={false}
          tickCount={7}
          padding={{ bottom: 5, top: 5 }}
          domain={['dataMin', 'dataMax']}
          // tickFormatter={(number) => `€${number.toFixed(2)}`}
          width={width ? width : 0}
        />
        <Tooltip />
        <Bar dataKey={axes.Y} fill={theme.special} />
      </RecBarChart>
    </ResponsiveContainer>
  );
}
