import React, { useContext, useRef, useState } from 'react';

import Modal from 'react-modal';
import styled from 'styled-components';

import { CloseIcon, ModalMidScreen, ModalOverlay } from './Modal.style';
import { device } from '../../../config/device';
import { Button, SecondaryButton } from '../Button/Button';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem;

  h4 {
    padding: 2.5rem 1rem;
  }

  .button-wrapper {
    width: 100%;
    margin-top: auto;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    grid-gap: 2rem;
  }

  @media ${device.mobile} {
    padding: 0.5rem;
    .button-wrapper {
      button {
        padding: 1.5rem 2rem;
        font-size: ${(props) => props.theme.x_small};
      }

      padding: 0;
      grid-gap: 1rem;
    }
  }
`;

const MESSAGE = 'The form contains changes. Do you want to continue?';

const useModalShow = () => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};
const ConfirmationModalContext = React.createContext({});
Modal.setAppElement('#root');

const ConfirmationModalContextProvider = (props: any) => {
  const { setShow, show, onHide } = useModalShow();
  const resolver = useRef<any>();

  const handleShow = async () => {
    setShow(true);
    const result = await new Promise(function (resolve) {
      resolver.current = resolve;
    });

    await console.log('handleShow -> result', result);

    return await result;
  };

  const modalContext = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      <Modal
        isOpen={show}
        onRequestClose={onHide}
        style={{
          overlay: {
            zIndex: 99,
            backgroundColor: 'inherit',
          },
          content: {
            border: 'none',
            backgroundColor: 'inherit',
          },
        }}
      >
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, easings: 'circInOut' }}
        >
          <ModalMidScreen>
            <Container>
              <CloseIcon onClick={onHide} />
              <h2>Confirm Action</h2>
              <h4>{MESSAGE}</h4>
              <div className='button-wrapper'>
                <SecondaryButton onClick={handleCancel}>cancel</SecondaryButton>
                <Button onClick={handleOk}>continue</Button>
              </div>
            </Container>
          </ModalMidScreen>
        </ModalOverlay>
      </Modal>
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export { useModalShow, useConfirmationModalContext };

export default ConfirmationModalContextProvider;
