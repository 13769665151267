import { useState, useLayoutEffect, useMemo } from 'react';

import { FaCheck } from 'react-icons/fa';
import useResizeObserver from 'use-resize-observer';

import { useStepsContext } from 'context/StepsProvider';

import { StepsInfoContainer } from './StepsNav.style';
import BubbleSteps from '../BubbleNavItem/BubbleSteps';

type Props = {
  currentStep?: number;
  pages: any;
  goToStep?: (step: number) => void;
  send: any;
};

export default function StepsNav({ currentStep, pages, goToStep, send }: Props) {
  const [lineWidth, setLineWidth] = useState(0);
  const { value } = useStepsContext();
  const { ref, width } = useResizeObserver();

  useLayoutEffect(() => {
    if ((width ? width : 0) > 0) {
      const currentLineWidth =
        ((width ? width : 0) - (pages.length + 1) * 45) / pages.length;
      setLineWidth(currentLineWidth);
    }
  }, [width, pages.length]);

  const stepsInfoArray = useMemo(() => {
    const array = [];

    function handleNavigation(step: number) {
      if (value === 'final') return;
      send({ type: 'STEP_CHANGE' });
      goToStep && goToStep(step);
    }

    pages.forEach((page: any, index: number) => {
      array.push(
        <BubbleSteps
          key={page.PAGENUM}
          index={index + 1}
          label={page.PAGEDESC}
          current={currentStep ? currentStep : 0}
          $lineWidth={lineWidth}
          goToStep={handleNavigation}
        />,
      );
    });
    array.push(
      <BubbleSteps
        key='final'
        index={pages.length + 1}
        label='Final'
        current={currentStep ? currentStep : 0}
        IconName={FaCheck}
        $lineWidth={0}
        goToStep={() => {}}
      />,
    );
    return array;
  }, [pages, goToStep, currentStep, value, lineWidth, send]);

  return (
    <StepsInfoContainer $lineWidth={lineWidth} ref={ref} data-testid='steps-info'>
      {stepsInfoArray}
    </StepsInfoContainer>
  );
}
