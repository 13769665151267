import { useState } from 'react';

import format from 'date-fns/format';
import formatISO9075 from 'date-fns/formatISO9075';
import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import styled from 'styled-components';

import { CustomInput } from 'components/shared/Input/Input.style';
import Modal from 'components/shared/Modal/Modal';
import { device } from 'config/device';
import { getValidDateValue } from 'helpers/helpers';

import { DateWrapper } from './DateInput.style';

const Container = styled.div`
  width: 400px;
  margin-top: 3.5rem;
  @media ${device.mobile} {
    width: auto;
  }
`;

type Props = {
  value: Date | null;
  handleFieldUpdate: (value: string, date: Date | null) => void;
};

export function DetailDateInput({ value, handleFieldUpdate }: Props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Modal isOpen={open} handleClose={() => setOpen(false)} askUser>
        <Container>
          <DateWrapper>
            <DatePickerCalendar
              locale={enGB}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              format='dd/MM/yyyy'
              onDateChange={(date) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const stringDate = formatISO9075(date);
                handleFieldUpdate(stringDate, date);
                setOpen(false);
              }}
              date={getValidDateValue(value)}
            />
          </DateWrapper>
        </Container>
      </Modal>
      <CustomInput
        onClick={() => setOpen(true)}
        placeholder='dd/mm/yyyy'
        onChange={() => {}}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        value={value ? format(value, 'dd/MM/yyyy') : ''}
        style={{
          color: 'inherit',
          padding: 0,
          boxShadow: 'none',
          backgroundColor: 'inherit',
        }}
      />
    </>
  );
}
