import { useEffect } from 'react';

import { useModalContext } from 'context/ModalProvider';

export const useOnClickOutsideModal = () => {
  const openModals = useModalContext();

  useEffect(
    () => {
      const listener = (event: any) => {
        const lastModal = openModals[openModals.length - 1];
        // Do nothing if clicking ref's element or descendent elements
        if (lastModal?.fullScreen) return;
        if (
          !lastModal?.ref ||
          !lastModal?.ref.current ||
          lastModal?.ref.current.contains(event.target)
        ) {
          return;
        }
        lastModal?.onClose(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [openModals],
  );
};
