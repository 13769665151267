import { StyledInput } from 'components/Search/Search.style';

import { FilterContainer, FilterIcon } from './GlobalFilter.style';

type Props = {
  enable: boolean;
  globalFilter?: string;
  setGlobalFilter?: (value: string) => void;
};

const GlobalFilter = ({ enable, globalFilter, setGlobalFilter }: Props) => {
  if (!enable) return null;
  return (
    <FilterContainer>
      <FilterIcon />
      <StyledInput
        value={globalFilter || ''}
        onChange={(e: any) => {
          // Set undefined to remove the filter entirely
          setGlobalFilter && setGlobalFilter(e.target.value || undefined);
        }}
        placeholder='Filter...'
      />
    </FilterContainer>
  );
};

export default GlobalFilter;
