import { getPdf } from 'helpers/services/services.utils';

export const getColumnPdf = async (ctx: any, e: any) => {
  const pdfResult = await getPdf({
    key: e.id,
    object: ctx.currentObject.OBJSOFTONENAME,
    printTemplate: ctx.currentObject.PRINTTEMPLATE,
  });
  return pdfResult;
};
