import { createMachine } from 'xstate';

import { assignFactory } from 'machines/shared/utils/utils';

import { services } from './intializeMachine.services';

export const initializeMachine = createMachine({
  id: 'initializeMachine',
  initial: 'gettingConfig',
  context: {},
  states: {
    gettingConfig: {
      invoke: {
        src: services.gettingConfig,
        onDone: {
          target: 'gettingObjects',
          actions: assignFactory('config'),
        },
        onError: {
          target: 'failure',
          actions: assignFactory('error'),
        },
      },
    },
    gettingObjects: {
      invoke: {
        src: services.gettingObjects,
        onDone: {
          target: 'gettingLoginData',
          actions: assignFactory('objects'),
        },
        onError: {
          target: 'failure',
          actions: assignFactory('error'),
        },
      },
    },
    gettingLoginData: {
      invoke: {
        src: services.gettingLoginData,
        onDone: {
          target: 'gettingSpecialFields',
          actions: assignFactory('loginData'),
        },
        onError: {
          target: 'failure',
          actions: assignFactory('error'),
        },
      },
    },
    gettingSpecialFields: {
      invoke: {
        src: services.gettingSpecialFields,
        onDone: {
          target: 'gettingBranchConfig',
          actions: assignFactory('updatedConfig'),
        },
        onError: {
          target: 'failure',
          actions: assignFactory('error'),
        },
      },
    },
    gettingBranchConfig: {
      invoke: {
        src: services.gettingBranchConfig,
        onDone: {
          target: 'gettingCLgadgets',
          actions: assignFactory('branchConfig'),
        },
        onError: {
          target: 'failure',
          actions: assignFactory('error'),
        },
      },
    },
    gettingCLgadgets: {
      invoke: {
        src: services.gettingCLgadgets,
        onDone: {
          target: 'success',
          actions: assignFactory('s1gadgets'),
        },
        onError: {
          target: 'failure',
          actions: assignFactory('error'),
        },
      },
    },
    success: {
      type: 'final',
      data: (ctx: any) => ({
        config: ctx.updatedConfig || [],
        objects: ctx.objects || [],
        branchConfig: ctx.branchConfig,
        s1gadgets: ctx.s1gadgets,
      }),
    },
    failure: {
      type: 'final',
      data: (ctx: any) => ({
        error: ctx.error || 'Server Error',
      }),
    },
  },
});
